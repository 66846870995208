import { useSelector } from 'react-redux';
import { selectorsOther } from '../redux/other/otherReducer';
import { useCallback, useMemo } from 'react';
import { IOption } from '../components/BasketPage/Components/DropdownInput';
import { useFormattingContext } from '../context/FormattingContext';
import { DEFAULT_NAME_SETTING } from '../constants/constantsId';

const ORDER_DELIVERY_TIME = 120;
const formatter = new Intl.DateTimeFormat('uk', {
  hour: 'numeric',
  minute: 'numeric',
});
const compareTimes = (d1: Date, d2: Date) => {
  return d1.getTime() <= d2.getTime();
};

const getOptionsTime = (d1: Date, d2: Date, step = 30, range = 60, isCourier = false) => {
  const hourStep = 60;

  let optionsTime: IOption<string>[] = [];
  if (isCourier) {
    for (let d = new Date(d1); ; ) {
      const second = new Date(d);
      second.setMinutes(second.getMinutes() + range); // Отрезок времени
      if (!compareTimes(second, d2)) {
        break;
      }
      const value = `${formatter.format(d)} - ${formatter.format(second)}`;
      optionsTime.push({
        value,
        label: value,
      });

      d.setMinutes(d.getMinutes() + hourStep); // Шаг
    }

    if (d1.getMinutes() >= 40) {
      optionsTime.pop();
    }
  } else {
    for (let d = new Date(d1); compareTimes(d, d2); ) {
      const value = `${formatter.format(d)} - ${formatter.format(d)}`;
      optionsTime.push({
        value,
        label: `${formatter.format(d)}`,
      });

      d.setMinutes(d.getMinutes() + hourStep);
    }
  }
  return optionsTime;
};

const modifyCurrentDate = (
  currentDate: Date,
  settings: {
    step: number;
    offset: number;
    from: string;
    to: string;
    range: number;
  },
) => {
  settings.step = 60;
  let minutes = currentDate.getMinutes() + ORDER_DELIVERY_TIME;
  minutes = Math.ceil(minutes / settings.step) * settings.step;
  currentDate.setMinutes(minutes);
};

const useAvailableDate = (keySetting: string, currentDate: Date, timeToPrepare: number | null) => {
  const { formatDate } = useFormattingContext();
  const settings = useSelector(selectorsOther.getSetting(keySetting));
  const offset = timeToPrepare || settings.offset;
  const constantTimes = useMemo(() => {
    const [fromH, fromM] = settings.from.split(':').map(parseFloat);
    const [toH, toM] = settings.to.split(':').map(parseFloat);
    const displayText = formatter.format(new Date(2000, 0, 1, toH, toM - offset - (settings.step || 0), 0, 0));
    if (keySetting === DEFAULT_NAME_SETTING) {
      return {
        min: new Date(2000, 0, 1, fromH, fromM, 0, 0),
        max: new Date(2000, 0, 1, toH, toM - 60, 0, 0),
        MAX: new Date(2000, 0, 1, toH, toM, 0, 0),
        displayText,
      };
    }
    return {
      min: new Date(2000, 0, 1, fromH, fromM, 0, 0),
      max: new Date(2000, 0, 1, toH, toM, 0, 0),
      MAX: new Date(2000, 0, 1, toH, toM, 0, 0),
      displayText,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = settings.from === settings.to;

  let startDate = new Date(currentDate);
  const initD = startDate.getDate();
  let optionsDate: IOption<Date>[] = [];
  startDate.setMinutes(startDate.getMinutes() + offset);

  if (startDate.getMinutes() !== 0) {
    let minutes = startDate.getMinutes();
    minutes = Math.ceil(minutes / 60) * 60;
    startDate.setHours(startDate.getHours(), minutes, 0, 0);
  }
  if (
    startDate.getHours() > constantTimes.MAX.getHours() ||
    (startDate.getHours() === constantTimes.MAX.getHours() && startDate.getMinutes() > constantTimes.MAX.getMinutes())
  ) {
    startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
  } else if (initD !== startDate.getDate()) {
    startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
  } else {
    const value = startDate;
    if (isDisabled) {
      optionsDate.push({
        label: 'сьогоднi',
        value,
      });
      startDate = new Date(currentDate);
      startDate.setDate(startDate.getDate() + 1);
    }
  }

  if (!isDisabled) {
    const today = new Date();
    for (let i = 1; i < 31; i += 1) {
      const isSameDate = today.toLocaleDateString() === startDate.toLocaleDateString();

      if (isSameDate) {
        modifyCurrentDate(today, settings);
        if (today.getHours() <= constantTimes.max.getHours()) {
          optionsDate.push({
            label: formatDate(startDate),
            value: new Date(startDate),
          });
        }
      } else {
        optionsDate.push({
          label: formatDate(startDate),
          value: new Date(startDate),
        });
      }

      startDate.setDate(startDate.getDate() + 1);
    }
  }

  const getTimeRanges = useCallback((date: string | Date) => {
    if (!date) return [];
    date = new Date(date);
    const currentDate = new Date();
    const isSameDate = currentDate.toLocaleDateString() === date.toLocaleDateString();
    modifyCurrentDate(currentDate, settings);

    if (isSameDate && currentDate.getHours() >= constantTimes.min.getHours()) {
      return getOptionsTime(
        new Date(2000, 0, 1, currentDate.getHours(), currentDate.getMinutes(), 0, 0),
        constantTimes.MAX,
        settings.step,
        settings.range!,
        keySetting === DEFAULT_NAME_SETTING,
      );
    }

    return getOptionsTime(
      constantTimes.min,
      constantTimes.MAX,
      settings.step,
      settings.range,
      keySetting === DEFAULT_NAME_SETTING,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    optionsDate,
    getTimeRanges,
    displayText: constantTimes.displayText,
  };
};

export default useAvailableDate;
