import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import { getLocaleStorageItem, removeFromLocaleStorage, setLocaleStorageItem } from '../../../utils/localStorageHelper';
import {
  invoiceRequisitesPerson,
  invoiceRequisitesCompany,
  invoiceRequisitesStreet,
  invoiceRequisitesCity,
  invoiceRequisitesBuildNumber,
  invoiceRequisitesZipCode,
  invoiceRequisitesVatId,
  invoiceRequisitesIsTheSameAsOrder,
} from '../../../constants/payData';
import { MiniItemInput } from '../../Forms/Components/ItemInput';
import { IRegister } from '../../../typings/IRegister';
import { selectorsDelivery } from '../../../redux/delivery/deliveryReducer';
import validation from '../../../utils/validation';
import {
  FieldValue,
  FieldValues,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import {
  canInvoiceRequisitesDataBeProcessing,
  isInvoiceRequisitesDataValidToSave,
} from '../../../utils/invoiceOrderRequisitesDataHelper';

interface IProps {
  errors: any;
  register: (name: string, validation: any) => IRegister;
  debCreateDraft: () => void;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValue<any>>;
  resetField: UseFormResetField<any>;
  trigger: UseFormTrigger<any>;
  isInvoiceAddressTheSameAsOrderAddress: boolean;
  setIsInvoiceAddressTheSameAsOrderAddress: (value: boolean) => void;
}

const InvoiceRequisitesDataComponent: React.FC<IProps> = ({
  errors,
  register,
  debCreateDraft,
  getValues,
  setValue,
  resetField,
  trigger,
  isInvoiceAddressTheSameAsOrderAddress,
  setIsInvoiceAddressTheSameAsOrderAddress,
}) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const zipCode = useSelector(selectorsDelivery.getZipCode);
  const [invoiceRequisitesData, setInvoiceRequisitesData] = useState<{ [key: string]: string } | null>(null);
  const nameRegister = canInvoiceRequisitesDataBeProcessing()
    ? register(invoiceRequisitesPerson, {
        validate: (value: string) => !!value?.length || !!getValues(invoiceRequisitesCompany)?.length,
      })
    : null;
  const companyRegister = canInvoiceRequisitesDataBeProcessing()
    ? register(invoiceRequisitesCompany, {
        validate: (value: string) => !!value?.length || !!getValues(invoiceRequisitesPerson)?.length,
      })
    : null;
  const cityRegister = canInvoiceRequisitesDataBeProcessing()
    ? register(invoiceRequisitesCity, validation.required)
    : null;
  const zipCodeRegister = canInvoiceRequisitesDataBeProcessing()
    ? register(invoiceRequisitesZipCode, validation.zipCode)
    : null;
  const streetRegister = canInvoiceRequisitesDataBeProcessing()
    ? register(invoiceRequisitesStreet, validation.required)
    : null;
  const buildNumberRegister = canInvoiceRequisitesDataBeProcessing()
    ? register(invoiceRequisitesBuildNumber, validation.required)
    : null;
  const vatIdRegister = canInvoiceRequisitesDataBeProcessing() ? register(invoiceRequisitesVatId, {}) : null;

  const requiredAtLeastOneFieldMess = [
    `${buildLocale(currentTranslate, 'payPageFieldError')}:`,
    buildLocale(currentTranslate, 'tINameLabel'),
    buildLocale(currentTranslate, 'or'),
    buildLocale(currentTranslate, 'companyTitle'),
  ].join(' ');

  useEffect(() => {
    initInvoiceRequisites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initInvoiceRequisites = () => {
    [
      invoiceRequisitesPerson,
      invoiceRequisitesCompany,
      invoiceRequisitesStreet,
      invoiceRequisitesCity,
      invoiceRequisitesBuildNumber,
      invoiceRequisitesZipCode,
      invoiceRequisitesVatId,
    ].forEach((key: string) => setInitialData(key));
  };

  const setInitialData = (key: string) => {
    let keyValue = getLocaleStorageItem(key);

    if (key === invoiceRequisitesZipCode && !keyValue?.length && !!zipCode?.length) {
      keyValue = zipCode;
      setLocaleStorageItem(key, zipCode);
    }
    if (keyValue) {
      setInvoiceRequisitesData((prevData: any) => ({ ...prevData, [key]: keyValue }));
      if (canInvoiceRequisitesDataBeProcessing()) {
        setValue(key, keyValue);
      }
    }
  };

  const changeData = (key: string, value: string) => {
    setValue(key, value);
    setLocaleStorageItem(key, value);
    setInvoiceRequisitesData((prevData: any) => ({ ...prevData, [key]: value }));
    trigger(key);
  };

  const handleBlur = () => {
    trigger(invoiceRequisitesPerson);
    trigger(invoiceRequisitesCompany);
    if (!isInvoiceRequisitesDataValidToSave()) {
      return;
    }
    debCreateDraft();
  };

  const resetAllItemsValue = () => {
    setInvoiceRequisitesData({});
    [
      invoiceRequisitesPerson,
      invoiceRequisitesCompany,
      invoiceRequisitesStreet,
      invoiceRequisitesCity,
      invoiceRequisitesBuildNumber,
      invoiceRequisitesZipCode,
      invoiceRequisitesVatId,
    ].forEach((key: string) => {
      removeFromLocaleStorage(key);
      resetField(key);
    });
  };

  const changeVisibility = (value: boolean) => {
    const prevFlagValue = getLocaleStorageItem(invoiceRequisitesIsTheSameAsOrder);
    setLocaleStorageItem(invoiceRequisitesIsTheSameAsOrder, String(value));
    if (value && prevFlagValue === 'false') {
      resetAllItemsValue();
      debCreateDraft();
    }

    setIsInvoiceAddressTheSameAsOrderAddress(value);
  };

  const getErrorData = (fieldName: string, errorMessage: string) => {
    if (errors[fieldName] && errors[fieldName].type === 'validate') {
      return { [fieldName]: { message: errorMessage } };
    }
    return [];
  };

  return (
    <div className="pay-invoice-requisites">
      <div className="pay-block_title">
        <div className="title">
          <span className="number">3</span> {buildLocale(currentTranslate, 'invoiceAddress')}
          <div className="form-check" onClick={() => {}}>
            <input
              id="the-same-address"
              type="checkbox"
              checked={isInvoiceAddressTheSameAsOrderAddress}
              onChange={(e) => changeVisibility(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="the-same-address">
              {buildLocale(currentTranslate, 'theSameAsOrderAddress')}
            </label>
          </div>
        </div>
      </div>
      {!isInvoiceAddressTheSameAsOrderAddress && (
        <>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-item">
                <MiniItemInput
                  errors={getErrorData(invoiceRequisitesPerson, requiredAtLeastOneFieldMess)}
                  label={buildLocale(currentTranslate, 'fullName')}
                  labelClassName="required"
                  defaultValue={invoiceRequisitesData?.invoiceRequisitesPerson || ''}
                  name={invoiceRequisitesPerson}
                  ref={nameRegister?.ref}
                  onChange={(e) => {
                    changeData(invoiceRequisitesPerson, e.target.value);
                    nameRegister?.onChange(e);
                  }}
                  onBlur={(e) => {
                    nameRegister?.onBlur(e);
                    handleBlur();
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <MiniItemInput
                  errors={getErrorData(invoiceRequisitesCompany, requiredAtLeastOneFieldMess)}
                  label={buildLocale(currentTranslate, 'companyTitle')}
                  labelClassName="required"
                  defaultValue={invoiceRequisitesData?.invoiceRequisitesCompany || ''}
                  name={invoiceRequisitesCompany}
                  ref={companyRegister?.ref}
                  onChange={(e) => {
                    companyRegister?.onChange(e);
                    changeData(invoiceRequisitesCompany, e.target.value);
                  }}
                  onBlur={(e) => {
                    companyRegister?.onBlur(e);
                    handleBlur();
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <MiniItemInput
                  errors={errors}
                  label={buildLocale(currentTranslate, 'addressStreet')}
                  labelClassName="required"
                  defaultValue={invoiceRequisitesData?.invoiceRequisitesStreet || ''}
                  name={invoiceRequisitesStreet}
                  ref={streetRegister?.ref}
                  onChange={(e) => {
                    streetRegister?.onChange(e);
                    changeData(invoiceRequisitesStreet, e.target.value);
                  }}
                  onBlur={(e) => {
                    streetRegister?.onBlur(e);
                    handleBlur();
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <MiniItemInput
                  errors={errors}
                  label={buildLocale(currentTranslate, 'addressBuild')}
                  labelClassName="required"
                  defaultValue={invoiceRequisitesData?.invoiceRequisitesBuildNumber || ''}
                  name={invoiceRequisitesBuildNumber}
                  ref={buildNumberRegister?.ref}
                  onChange={(e) => {
                    buildNumberRegister?.onChange(e);
                    changeData(invoiceRequisitesBuildNumber, e.target.value);
                  }}
                  onBlur={(e) => {
                    buildNumberRegister?.onBlur(e);
                    handleBlur();
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <MiniItemInput
                  errors={errors}
                  label={buildLocale(currentTranslate, 'addressCity')}
                  labelClassName="required"
                  defaultValue={invoiceRequisitesData?.invoiceRequisitesCity || ''}
                  name={invoiceRequisitesCity}
                  ref={cityRegister?.ref}
                  onChange={(e) => {
                    cityRegister?.onChange(e);
                    changeData(invoiceRequisitesCity, e.target.value);
                  }}
                  onBlur={(e) => {
                    cityRegister?.onBlur(e);
                    handleBlur();
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <MiniItemInput
                  errors={errors}
                  label={buildLocale(currentTranslate, 'zipPlaceholder')}
                  labelClassName="required"
                  defaultValue={invoiceRequisitesData?.invoiceRequisitesZipCode || ''}
                  name={invoiceRequisitesZipCode}
                  ref={zipCodeRegister?.ref}
                  onChange={(e) => {
                    zipCodeRegister?.onChange(e);
                    changeData(invoiceRequisitesZipCode, e.target.value);
                  }}
                  onBlur={(e) => {
                    zipCodeRegister?.onBlur(e);
                    handleBlur();
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <MiniItemInput
                  errors={[]}
                  label="Vat ID"
                  defaultValue={invoiceRequisitesData?.invoiceRequisitesVatId || ''}
                  name={invoiceRequisitesVatId}
                  ref={vatIdRegister?.ref}
                  onChange={(e) => {
                    vatIdRegister?.onChange(e);
                    changeData(invoiceRequisitesVatId, e.target.value);
                  }}
                  onBlur={(e) => {
                    vatIdRegister?.onBlur(e);
                    handleBlur();
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InvoiceRequisitesDataComponent;
