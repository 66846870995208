import React from 'react';
import { actionsFilters } from '../../../../../redux/filters/filtersReducer';
import { useDispatch, useSelector } from 'react-redux';
import CategoryImage from '../../../../ImagesComponent/CategoryImage';
import { IFetchCategory } from '../../../../../typings/ICategory';
import { selectorsCategory } from '../../../../../redux/category/categoryReducer';
import { useParams, useSearchParams } from 'react-router-dom';
import { IFilterCustomCategories } from '../../../../../typings/IFilterCustomCategories';

interface ICustomCategoryProps {
  data: IFilterCustomCategories;
}

const FilterCustomCategoriesDesktop = (props: ICustomCategoryProps) => {
  const { data } = props;
  const { id } = useParams();
  const categoryId = Number(id);
  const currentCategory = useSelector(selectorsCategory.getCategoriesById(categoryId));
  const [searchParams] = useSearchParams();
  const currentCustomCategory = searchParams.get('customcategory') || searchParams.get('customCategory') || null;
  const dispatch: any = useDispatch();

  const applyFilter = (selectedCustomCategory: IFetchCategory): void => {
    if (selectedCustomCategory.id === 0) {
      clearFilters();
      return;
    }
    dispatch(actionsFilters.setCustomCategory(String(selectedCustomCategory.id)));
    dispatch(
      actionsFilters.setSelectedCustomCategory({
        id: selectedCustomCategory.id,
        name: selectedCustomCategory.name,
      }),
    );
  };

  const clearFilters = (): void => {
    dispatch(actionsFilters.setCustomCategory(null));
    dispatch(actionsFilters.setSelectedCustomCategory(null));
  };

  const Item = (props: any) => {
    const { item } = props;
    return (
      <div className="category-tabs_col">
        <div
          className={`category-tabs_one category-tab-item ${Number(currentCustomCategory) === item.id ? 'todo-disabled' : ''}`}
          onClick={() => Number(currentCustomCategory) !== item.id && applyFilter(item)}>
          <span>{item.name}</span>
          <figure className="category-tabs_pic">
            <span>
              <CategoryImage category={item} />
            </span>
          </figure>
        </div>
      </div>
    );
  };

  return (
    <div className="category-tabs">
      <div className="row">
        <ol className="breadcrumb-categories">
          {!!currentCategory && !!currentCustomCategory && (
            <li className="breadcrumb-item" onClick={() => clearFilters()}>
              <span>{currentCategory.name}</span>
            </li>
          )}
          {!!data?.breadCrumbs?.length &&
            data.breadCrumbs.map((item: IFetchCategory) => (
              <li
                className={`breadcrumb-item ${Number(currentCustomCategory) === item.id ? 'todo-disabled' : ''}`}
                key={item.id}
                onClick={() => Number(currentCustomCategory) !== item.id && applyFilter(item)}>
                <span>{item.name}</span>
              </li>
            ))}
        </ol>
      </div>
      <div className="row">
        {!!data?.tree?.length && data.tree.map((item: IFetchCategory) => <Item item={item} key={item.id} />)}
      </div>
    </div>
  );
};

export default React.memo(FilterCustomCategoriesDesktop);
