import React from 'react';
import CategoryImage from '../../../../ImagesComponent/CategoryImage';
import { IFetchCategory } from '../../../../../typings/ICategory';
import { actionsFilters } from '../../../../../redux/filters/filtersReducer';
import { useDispatch, useSelector } from 'react-redux';
import { buildLocale } from '../../../../../utils/buildLocale';
import { selectorsLocale } from '../../../../../redux/locale/localeReducer';
import { usePaths } from '../../../../Routes/RouterList';
import { useNavigate } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import { IFilterCustomCategories } from '../../../../../typings/IFilterCustomCategories';

interface ICustomCategoryProps {
  data: IFilterCustomCategories;
  isWishlist?: boolean;
  isSearchPage?: boolean;
}

const FilterCustomCategoriesMobile = (props: ICustomCategoryProps) => {
  const { data, isWishlist, isSearchPage } = props;
  const { id } = useParams();
  const categoryId = Number(id);
  const [searchParams] = useSearchParams();
  const currentCustomCategory = searchParams.get('customcategory') || searchParams.get('customCategory') || null;
  const dispatch: any = useDispatch();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const navigate = useNavigate();
  const paths = usePaths();

  const applyFilter = (selectedCustomCategory: IFetchCategory): void => {
    if (!selectedCustomCategory) {
      return;
    }
    if (selectedCustomCategory.id === 0) {
      clearFilters();
      return;
    }
    dispatch(actionsFilters.setCustomCategory(String(selectedCustomCategory.id)));
    dispatch(
      actionsFilters.setSelectedCustomCategory({
        id: selectedCustomCategory.id,
        name: selectedCustomCategory.name,
      }),
    );
  };

  const applyBreadCrumbs = (): void => {
    if (data.breadCrumbs.length === 1) {
      clearFilters();
      return;
    }
    const customCategoryToNavigate = data.breadCrumbs.slice(data.breadCrumbs.length - 2, -1);
    if (!customCategoryToNavigate?.length || customCategoryToNavigate[0].id === 0) {
      clearFilters();
      return;
    }
    dispatch(actionsFilters.setCustomCategory(String(customCategoryToNavigate[0].id)));
    dispatch(
      actionsFilters.setSelectedCustomCategory({
        id: customCategoryToNavigate[0].id,
        name: customCategoryToNavigate[0].name,
      }),
    );
  };

  const clearFilters = (): void => {
    dispatch(actionsFilters.setCustomCategory(null));
    dispatch(actionsFilters.setSelectedCustomCategory(null));
    let redirectUrl = paths.categories_page('categories');
    if (isWishlist) {
      redirectUrl = paths.wishlist;
    } else if (isSearchPage) {
      redirectUrl = paths.searching;
    }
    if (!isWishlist && !isSearchPage && data.tree?.length && data.tree[0].parent) {
      redirectUrl = paths.categories(categoryId);
    }
    navigate(redirectUrl, {
      state: {
        from: [paths.categories_page()],
        filters: [],
      },
    });
  };

  const Item = (props: any) => {
    const { item } = props;
    return (
      <div className={`mobile-categories_one ${Number(currentCustomCategory) === item.id ? 'todo-disabled' : ''}`}>
        <div
          className="mobile-categories_one-in"
          onClick={() => Number(currentCustomCategory) !== item.id && applyFilter(item)}>
          <figure className="pic">
            <CategoryImage category={item} />
          </figure>
          <span className="name">{item.name}</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      {(isWishlist || isSearchPage) && !data.breadCrumbs?.length ? (
        <></>
      ) : (
        <div className="mobile-categories_top">
          <div className="mobile-categories_breadcrumbs">
            <div
              className="mobile-categories_back"
              onClick={() => (!!data.breadCrumbs?.length ? applyBreadCrumbs() : clearFilters())}>
              <span className="arrow"></span>
              <span className="text">{buildLocale(currentTranslate, 'categoriesBackBtn')}</span>
            </div>
          </div>
        </div>
      )}
      <>{!!data?.tree?.length && data.tree.map((item: IFetchCategory) => <Item item={item} key={item.id} />)}</>
    </div>
  );
};

export default React.memo(FilterCustomCategoriesMobile);
