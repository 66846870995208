import React from 'react';
import { IProduct, IProductOption } from '../../typings/IProduct';
import { Product, QuantitativeValue, WithContext, Review, Offer } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';
import { usePaths } from '../Routes/RouterList';
import config from '../../config';
import { clearHtmlTags } from '../../utils/clearHtmlTags';
import { IReview } from '../../typings/IReview';
import { getClientFullName } from '../../utils/clientNameHelper';
import { useSelector } from 'react-redux';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';

interface IProductJsonLdProps {
  product?: IProduct;
  reviews?: IReview[];
}

const ProductJsonLd = ({ product, reviews }: IProductJsonLdProps) => {
  const paths = usePaths();
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);

  const extendWithSaleEndDate = (item: Offer, saleEndDate: string): void => {
    const saleEndDateObj = new Date(saleEndDate);
    item.priceValidUntil = saleEndDateObj.toISOString();
  };

  const extendWithPriceRangeData = (
    data: Offer[],
    currentProductOption: IProductOption,
    productUrl: string,
    availability: string,
  ) => {
    const priceRangeItems = currentProductOption.productPriceRanges;
    for (const index in priceRangeItems) {
      const currentIndex = Number(index);
      const currentPriceRangeItem = priceRangeItems.at(currentIndex);
      if (!currentPriceRangeItem) {
        continue;
      }
      const minCurrentQty = Number(currentPriceRangeItem.quantity);
      const eligibleItem: QuantitativeValue = {
        '@type': 'QuantitativeValue',
        unitCode: 'C62',
        minValue: minCurrentQty,
      };

      const nextPriceRangeItem = priceRangeItems.at(currentIndex + 1);
      if (nextPriceRangeItem) {
        const maxCurrentQty = Number(nextPriceRangeItem.quantity) - 1;
        if (maxCurrentQty > minCurrentQty) {
          eligibleItem.maxValue = maxCurrentQty;
        }
      }

      const item = {
        '@type': 'Offer',
        url: productUrl,
        itemCondition: 'https://schema.org/NewCondition',
        priceType: 'https://schema.org/ListPrice',
        availability: availability,
        price: Number(currentPriceRangeItem.price),
        priceCurrency: 'EUR',
        eligibleQuantity: eligibleItem,
      } as Offer;

      data.push(item);
    }
  };

  const makeReviewsData = (reviews: IReview[]): Review[] => {
    const data: Review[] = [];
    for (const review of reviews) {
      if (!review.isComplete) {
        continue;
      }
      const publicationDate = new Date(review.publicationDate);
      const item = {
        '@type': 'Review',
        reviewBody: review.text,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.rating,
        },
        datePublished: publicationDate.toISOString(),
      } as Review;

      let authorName = 'Anonym';
      if (!!review?.client) {
        authorName = getClientFullName(review.client.fullName, review.client?.firstName, review.client.lastName);
      }
      item.author = {
        '@type': 'Person',
        name: authorName,
      };

      data.push(item);
    }

    return data;
  };

  const makeOffers = (product: IProduct): Offer[] => {
    const currentProductOption = product?.productOptions.at(0);
    const productUrl = config.canLink + paths.product(product.id);
    const isOutOfStockForDelivery = !product?.isBoxDeliveryAllowed && isInCourierArea === false;
    const availability =
      !!product?.productOptions?.[0]?.availableAmount && !isOutOfStockForDelivery
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock';
    const data = [] as Offer[];

    if (currentProductOption?.price) {
      const item = {
        '@type': 'Offer',
        url: productUrl,
        itemCondition: 'https://schema.org/NewCondition',
        availability: availability,
        priceType: currentProductOption?.salePrice ? 'https://schema.org/RegularPrice' : 'https://schema.org/ListPrice',
        price: Number(currentProductOption.price),
        priceCurrency: 'EUR',
      } as Offer;

      data.push(item);
    }

    if (currentProductOption?.salePrice) {
      const item = {
        '@type': 'Offer',
        url: productUrl,
        itemCondition: 'https://schema.org/NewCondition',
        availability: availability,
        priceType: 'https://schema.org/SalePrice',
        price: Number(currentProductOption.salePrice),
        priceCurrency: 'EUR',
      } as Offer;

      if (currentProductOption.saleEndDate) {
        extendWithSaleEndDate(item, currentProductOption.saleEndDate);
      }
      data.push(item);
    }

    if (currentProductOption?.hasPriceRanges && !!currentProductOption.productPriceRanges?.length) {
      extendWithPriceRangeData(data, currentProductOption, productUrl, availability);
    }

    return data;
  };

  const generateSchemaOrgJson = (product: IProduct): WithContext<Product> => {
    const schema: WithContext<Product> = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      sku: product.externalId,
      gtin: product.gtinArticle,
      name: product.title,
      category: product.customCategories?.[0]?.name,
      image: product?.cdnImages?.map((img) => img.variants.public),
      description: clearHtmlTags(product.shortDescription),
      offers: makeOffers(product),
    };

    if (product.brand) {
      schema.brand = {
        '@type': 'Brand',
        name: product.brand.nameDe,
      };
    }

    if (product.sizeArticle) {
      const [width, depth, height] = product.sizeArticle.split(' x ');
      if (Number(depth)) {
        schema.depth = {
          '@type': 'QuantitativeValue',
          value: Number(depth),
          unitCode: 'CMT',
        };
      }
      if (Number(height)) {
        schema.height = {
          '@type': 'QuantitativeValue',
          value: Number(height),
          unitCode: 'CMT',
        };
      }
      if (Number(width)) {
        schema.width = {
          '@type': 'QuantitativeValue',
          value: Number(width),
          unitCode: 'CMT',
        };
      }
    }
    if (Number(product.weight)) {
      schema.weight = {
        '@type': 'QuantitativeValue',
        value: Number(product.weight),
        unitText: product.weightUnit?.typeDe,
      };
    }
    if (product.reviewsPublishedCount) {
      schema.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: Number(product.reviewsAverageRating),
        ratingCount: product.reviewsPublishedCount,
      };
    }
    if (!!reviews?.length) {
      schema.review = makeReviewsData(reviews);
    }
    return schema;
  };

  if (!product) {
    return null;
  }
  return <JsonLd item={generateSchemaOrgJson(product)} />;
};

export default ProductJsonLd;
