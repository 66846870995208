import React from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { ICDNVariantKey } from '../../typings/ICDNImage';
import { IProduct } from '../../typings/IProduct';
import getUrlImg from '../../utils/getUrlImg';

interface IProps {
  product: IProduct;
  imageIndex: number;
}

const ProductMagnifyImage = (props: IProps) => {
  const { product, imageIndex } = props;
  const getPropsProductImage = (product: IProduct) => {
    if (product?.cdnImages?.length) {
      const actualImageIndex = product.cdnImages.length - 1 < imageIndex ? 0 : imageIndex;
      const cdn = product.cdnImages.sort((a: any, b: any) => {
        return a.id - b.id;
      })[actualImageIndex || 0];

      return { src: cdn.variants[ICDNVariantKey.public] };
    }

    return { src: getUrlImg(product.productImages) };
  };

  const imageProps = getPropsProductImage(product);

  return (
    <ReactImageMagnify
      {...{
        smallImage: {
          alt: `${product.title}`,
          isFluidWidth: true,
          src: imageProps.src,
        },
        largeImage: {
          src: imageProps.src,
          width: 600,
          height: 600,
        },
        enlargedImageContainerStyle: { backgroundColor: '#fff' },
        enlargedImageStyle: {
          objectFit: 'contain',
        },
      }}
    />
  );
};

export default ProductMagnifyImage;
