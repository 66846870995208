import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { usePaths } from '../Routes/RouterList';
import Pagination from '../common/Pagination';
import OrderItem from './Components/OrderItem';
import OrderLoader from '../Loaders/OrderLoader';
import useProfileOptions from '../../useHooks/useProfileOptions';
import { IOrderFull } from '../../typings/IOrderItem';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import service from '../../services/service';

const OrdersOption = React.memo(() => {
  const [searchParams] = useSearchParams();
  const paths = usePaths();
  const perPage = 4;
  const paginationParamValue = Number(searchParams.get('page')) || 1;
  const offset = paginationParamValue > 1 ? (paginationParamValue - 1) * perPage : 0;
  const ProfileOptions = useProfileOptions();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const loadMore = useRef(false);
  const [data, setData] = useState<IOrderFull[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  const getOrders = useCallback((currentTop?: number, currentSkip?: number) => {
    setIsLoading(true);
    service
      .getOrders({ top: currentTop || perPage, skip: currentSkip || offset })
      .then((resp) => {
        setData(resp.data.items);
        setCount(resp.data.count);
      })
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const changePagination = async (page: number, length: number, load: boolean) => {
    const top = length * perPage;
    const skip = (page - 1) * perPage;
    loadMore.current = load;
    await getOrders(top, skip);
  };

  return (
    <div className="tab-pane history">
      <Link to={paths.profile()} className="back-link">
        {buildLocale(currentTranslate, 'headerProfile')}
      </Link>
      <p className="profile-block_title">{ProfileOptions['orders'].name}</p>
      {!isLoading && (
        <>
          {!data?.length && (
            <div className="profile-block profile-block_info">
              <p>{buildLocale(currentTranslate, 'profileNoOrders')}</p>
            </div>
          )}
          {!!data?.length && data.map((item: IOrderFull) => <OrderItem key={item.id} order={item} />)}
        </>
      )}
      {loadMore.current &&
        isLoading &&
        Array(perPage)
          .fill(1)
          .map((_, index) => <OrderLoader key={index} />)}
      <div className="pagination-orders">
        <Pagination
          availableCount={100}
          count={count}
          perPage={perPage}
          onCurrentPages={changePagination}
          loading={isLoading}
        />
      </div>
    </div>
  );
});

export default OrdersOption;
