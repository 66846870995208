import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductLoader from '../components/Loaders/ProductLoader';
import Breadcrumbs, { ParentType } from '../components/common/Breadcrumbs';
import GoBackBtn from '../components/CategoriesMobile/GoBackBtn';
import ProductCard from '../components/Modals/ProductCard';
import { blockBodyScroll } from '../utils/blockBodyScroll';
import Product from '../components/Products/Product';
import FilterComponent from '../components/common/Filters/FilterComponent';
import { FILTER_TYPE, FILTERS_TYPE } from '../typings/IFilters';
import { selectorsFilters } from '../redux/filters/filtersReducer';
import { useLessThen991 } from '../utils/mediaQuery';
import queries from '../services/queries';
import useApi from 'react-use-api';
import { handleData } from '../utils/handleData';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import { IBrandItem } from '../typings/IBrands';
import { SeoTexts } from '../constants/seoTexts';
import CommonHelmet from '../components/common/CommonHelmet';
import config from '../config';
import { isContentNotEmpty } from '../utils/isContentNotEmpty';
import { usePaths } from '../components/Routes/RouterList';
import useFilterQueryParams from '../useHooks/useFilterQueryParams';
import useFilterDataFromSearchParams from '../useHooks/useFilterDataFromSearchParams';
import getSortDataForFilter from '../utils/getSortDataForFilter';
import BrandJsonLd from '../components/JsonLd/BrandJsonLd';
import { useNavigate, useNavigationType } from 'react-router';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';
import { useRouterStaticContext } from '../context/RouterStaticContext';
import NotFoundComponent from './common/NotFoundPage';
import { selectorsOther } from '../redux/other/otherReducer';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { FilterSearchingParams } from '../redux/filters/filtersTypes';

const BrandPage = () => {
  const params: any = useParams();
  const brandId = Number(params.id);
  const isBrandIdNaN = isNaN(brandId);
  const paths = usePaths();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const limit = 20;
  const [offset, setOffset] = useState(0);

  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const selectedSort = useSelector(selectorsFilters.getSortDirection);
  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const staticContext = useRouterStaticContext();

  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const [currentBrand, { error }] = useApi<IBrandItem>(queries.getBrand(brandId), { skip: isBrandIdNaN });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const navigationType = useNavigationType();

  const location = useLocation();
  const navigate = useNavigate();
  const translate = useSelector(selectorsLocale.getTranslate);

  const getActualFilters = () => {
    return {
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      orderProducts: getSortDataForFilter({ isEmpty: true } as FilterSearchingParams, selectedSort),
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
    };
  };

  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({
    type: FILTER_TYPE.BRAND,
    id: brandId,
    filters: getActualFilters(),
  });
  const { brands, badges, allergich, nutrion, searchQueryData } = useFilterDataFromSearchParams(
    brandsQuery,
    badgesQuery,
    attributesQuery,
  );

  const backBtn = useMemo(() => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return '';
    }
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={translate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (staticContext && (error?.status === 404 || isBrandIdNaN)) {
    staticContext.statusCode = 404;
  }

  const applyFilters = () => {
    setOffset(0);
  };

  useEffect(() => {
    if (!isLessThen991) {
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, brandId]);

  const getProducts = (offset?: number, limit?: number) => {
    const filters = getActualFilters();

    return queries.getProductsByBrand(brandId, filters.orderProducts, filters, isInCourierArea, limit, offset);
  };

  const options = useMemo(
    () => ({
      handleData,
      dependencies: {
        perPage: limit,
      },
      skip: isBrandIdNaN,
    }),
    [isBrandIdNaN, limit],
  );
  const [products, { loading, hasMore = true }, request] = useApi(getProducts(), options);
  const loadMore = useCallback(() => {
    const newOffset = offset + limit;
    request(getProducts(newOffset, limit), true).then(() => setOffset(newOffset));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    offset,
    selectedBadges,
    selectedAllergich,
    selectedNutrion,
    hasPriceRanges,
    hasBadges,
    hasSalePrice,
    searchFilter,
  ]);

  useEffect(() => {
    if (!isOpen) {
      blockBodyScroll(false);
    }
  }, [isOpen]);

  const metaData = useMemo(() => {
    const title = currentBrand?.seoTexts?.title || SeoTexts.title;
    const description = currentBrand?.seoTexts?.description || SeoTexts.description;

    return {
      title,
      description,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBrand]);

  const openProductCardPopup = (product) => {
    if (!isOpen) {
      setIsOpen(true);
      setSelectedProduct(product);
    }
  };

  const closeProductCardPopup = () => {
    if (isOpen) {
      setIsOpen(false);
      setSelectedProduct({});
    }
  };

  const hasLogo = () => !!currentBrand?.logo?.variants?.origin;

  if (error?.status === 404 || isBrandIdNaN) {
    return <NotFoundComponent />;
  }

  return (
    <section className="category-page brand-page">
      <CommonHelmet
        title={metaData.title}
        description={metaData.description}
        ogImg={currentBrand?.logo?.variants?.origin}>
        <link rel="canonical" href={config.canLink + paths.brand(brandId)} />
      </CommonHelmet>
      {backBtn}
      {currentBrand && <BrandJsonLd brand={currentBrand} />}
      <section className={'section-main no-banner-crumbs'}>
        {currentBrand && <Breadcrumbs type={ParentType.brands} lastItemName={currentBrand?.nameDe} />}
        <h1 className="category-title">{currentBrand?.nameDe || ''}</h1>
        <div className={'brand-page-header'}>
          {hasLogo() && (
            <img
              src={currentBrand.logo.variants.origin}
              alt=""
              className={
                isContentNotEmpty(currentBrand.descriptionDe) ? 'brand-page-logo' : 'brand-page-logo-no-description'
              }
            />
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: currentBrand?.descriptionDe,
            }}></div>
        </div>
      </section>
      <FilterComponent
        id={brandId}
        type={FILTER_TYPE.BRAND}
        applyFilters={applyFilters}
        isLoading={loading}
        disabledFilters={[FILTERS_TYPE.BRANDS]}
        filters={getActualFilters()}
        brands={brands}
        badges={badges}
        allergich={allergich}
        nutrion={nutrion}
        searchQueryData={searchQueryData}
      />

      <div className="search-result">
        <div className="container">
          <InfiniteScroll
            next={loadMore}
            hasMore={hasMore}
            loader={<ProductLoader />}
            dataLength={products ? products.length : 0}
            scrollThreshold={0.8}
            style={{ overflowX: 'hidden' }}>
            {products &&
              products.length > 0 &&
              products.map((product: any) => (
                <div key={product.id} className="col-6 col-md-4 col-lg-3 col-xl-2 catalog-item">
                  <Product product={product} onProductImageClick={openProductCardPopup} />
                </div>
              ))}
          </InfiniteScroll>
          {isOpen && <ProductCard isOpen={isOpen} id={selectedProduct.id} onProductCardClose={closeProductCardPopup} />}
        </div>
      </div>
    </section>
  );
};

export default BrandPage;
