import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TabsList from '../components/common/TabList';
import OwnCategoriesComponent from '../components/common/CustomSlider';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import configi18n from '../assets/i18n/config';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductLoader from '../components/Loaders/ProductLoader';
import Breadcrumbs from '../components/common/Breadcrumbs';
import GoBackBtn from '../components/CategoriesMobile/GoBackBtn';
import {
  getBannerBullets,
  getBannerHeight,
  getBannerImage,
  getBannerTitle,
  isHasBanner,
} from '../utils/bannerPrictures';
import FilterComponent from '../components/common/Filters/FilterComponent';
import { FILTER_TYPE, IFiltersDTO } from '../typings/IFilters';
import { useLessThen991 } from '../utils/mediaQuery';
import { selectorsFilters } from '../redux/filters/filtersReducer';
import queries from '../services/queries';
import useApi from 'react-use-api';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import CommonHelmet from '../components/common/CommonHelmet';
import config from '../config';
import { usePaths } from '../components/Routes/RouterList';
import { SeoTexts } from '../constants/seoTexts';
import NotFoundComponent from './common/NotFoundPage';
import { useRouterStaticContext } from '../context/RouterStaticContext';
import { selectorsOther } from '../redux/other/otherReducer';
import { useNavigate } from 'react-router';
import useFilterQueryParams from '../useHooks/useFilterQueryParams';
import useFilterDataFromSearchParams from '../useHooks/useFilterDataFromSearchParams';

const OwnCategoriesPage = () => {
  const { id } = useParams();
  const categoryId = Number(id);
  const isCategoryIdNan = isNaN(categoryId);
  const paths = usePaths();
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);

  const getActualFilters = () => {
    return {
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
    };
  };

  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({
    type: FILTER_TYPE.OWNCATEGORY,
    id: categoryId,
    skip: isCategoryIdNan,
    filters: {
      ...({} as IFiltersDTO),
      ...getActualFilters(),
    },
  });
  const { brands, badges, allergich, nutrion, searchQueryData } = useFilterDataFromSearchParams(
    brandsQuery,
    badgesQuery,
    attributesQuery,
  );

  const getCustomCategories = (id: number) => queries.getChildrenCategories(id, isInCourierArea);
  const staticContext = useRouterStaticContext();

  const [ownCategories, { loading: isLoadingOwnCategories }] = useApi(getCustomCategories(categoryId), {
    skip: isCategoryIdNan,
  });
  const [currentCategory, { error }] = useApi(
    queries.getCustomNoParentsCategory(categoryId, isInCourierArea, configi18n.defaultLocale, true),
    { skip: isCategoryIdNan },
  );
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const [isHasMore, setIsHasMore] = useState(true);
  const [limit, setLimit] = useState(4);
  const [shownCategories, setShownCategories] = useState<Array<any>>(
    ownCategories?.length ? ownCategories?.slice(0, limit) : [],
  );
  const [sortingData, setSortingData] = useState<undefined | string>(undefined);
  const [isLoading] = useState(false);
  const [productsTrigger, setProductsTrigger] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);

  const backBtn = useMemo(() => {
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={currentTranslate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (staticContext && (error?.status === 404 || isCategoryIdNan)) {
    staticContext.statusCode = 404;
  }

  const loadMore = () => {
    const newLimit = limit + 1;
    setLimit(newLimit);
    setShownCategories(ownCategories ? ownCategories.slice(0, newLimit) : []);
    if (shownCategories.length > 0 && shownCategories.length >= ownCategories.length) {
      setIsHasMore(false);
    }
  };

  const applyFilters = () => {
    setProductsTrigger(!productsTrigger);
  };

  useEffect(() => {
    if (!isLessThen991) {
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, sortingData, id]);

  useEffect(() => {
    setLimit(0);
    setLimit(4);
    setIsHasMore(ownCategories ? limit < ownCategories.length : false);
    setShownCategories(ownCategories?.length ? ownCategories?.slice(0, limit) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownCategories]);

  const metaData = useMemo(() => {
    const title = currentCategory?.seoTexts?.title || SeoTexts.title;
    const description = currentCategory?.seoTexts?.description || SeoTexts.description;

    return {
      title,
      description,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  const bullets = getBannerBullets(currentCategory);

  const setSort = (sortData: string | undefined) => {
    setSortingData(sortData);
    applyFilters();
  };

  if (error?.status === 404 || isCategoryIdNan) {
    return <NotFoundComponent />;
  }

  return (
    <section className="category-page">
      <CommonHelmet title={metaData.title} description={metaData.description}>
        <link rel="canonical" href={config.canLink + paths.own_categories(categoryId)} />
      </CommonHelmet>
      {backBtn}
      {/* <Breadcrumbs idCategory={match.params.id} /> */}
      <section className={`section-main ${isHasBanner(currentCategory) ? '' : 'no-banner-crumbs'}`}>
        <Breadcrumbs idCategory={categoryId} />
        {isHasBanner(currentCategory) && (
          <div className="search-main" style={{ height: getBannerHeight(currentCategory) }}>
            <figure className="search-main_pic">
              <img src={getBannerImage(currentCategory)} alt="" />
            </figure>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <h1 style={{ color: '#fff' }}>
                    {getBannerTitle(currentCategory, buildLocale(currentTranslate, 'ownCategoryBannerTitle'))}
                  </h1>
                  <div className="row">
                    {bullets &&
                      bullets.map((item, index) => (
                        <div key={index} className="col-md-4">
                          <article className="category-page_art" style={{ color: '#fff' }}>
                            {item}
                          </article>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isHasBanner(currentCategory) && <h1 className="category-title">{currentCategory?.name || ''}</h1>}
      </section>
      <div className="container">
        <TabsList items={ownCategories} />
        <FilterComponent
          id={categoryId}
          type={FILTER_TYPE.OWNCATEGORY}
          applyFilters={applyFilters}
          isLoading={isLoading}
          sortProducts={setSort}
          filters={getActualFilters()}
          brands={brands}
          badges={badges}
          allergich={allergich}
          nutrion={nutrion}
          searchQueryData={searchQueryData}
        />
      </div>
      {!isLoadingOwnCategories && shownCategories && shownCategories.length > 0 && (
        <InfiniteScroll
          dataLength={shownCategories.length}
          style={{ overflowX: 'hidden' }}
          next={() => loadMore()}
          hasMore={isHasMore}
          loader={<ProductLoader />}>
          {shownCategories.map((category: any) => (
            <OwnCategoriesComponent
              key={category.id}
              name={category.name}
              id={category.id}
              parentId={categoryId}
              setLimit={loadMore}
              sortingData={sortingData}
            />
          ))}
        </InfiniteScroll>
      )}
    </section>
  );
};

export default OwnCategoriesPage;
