import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { selectorsUser } from '../../../redux/user/userReducer';
import { IContact } from '../../../typings/IContact';
import useComponentVisible from '../../../useHooks/useComponentVisible';
import { buildLocale } from '../../../utils/buildLocale';
import { formatUserProfile } from '../../../utils/formatUserProfile';
import { getLocaleStorageItem } from '../../../utils/localStorageHelper';
import { company, email, fullName, phone } from '../../../constants/payData';

interface IProps {
  setUserData: (profile: IContact) => void;
  updateStorageUser: (user: any) => void;
}

const UserProfilesDropdown = (props: IProps) => {
  const { setUserData, updateStorageUser } = props;
  const profiles = useSelector(selectorsUser.getContacts);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const user = useSelector(selectorsUser.getDataUser)!;
  const [showInfo, setShowInfo] = useState<any>(null);

  const { ref: inputRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const selectProfile = (profile: IContact) => {
    setUserData(profile);
    updateStorageUser(profile);
    setIsComponentVisible(false);
    setShowInfo(null);
  };

  const handleToggle = () => {
    setIsComponentVisible((f) => !f);
  };

  const collectProfileUsingDraft = (profile: any) => {
    const fullNameDraft = getLocaleStorageItem(fullName);
    const companyDraft = getLocaleStorageItem(company);
    const phoneDraft = getLocaleStorageItem(phone);
    const emailDraft = getLocaleStorageItem(email);

    if (fullNameDraft?.length) {
      profile.fullName = fullNameDraft;
    }
    if (companyDraft?.length) {
      profile.company = companyDraft;
    }
    if (phoneDraft?.length) {
      profile.phone = phoneDraft;
    }
    if (emailDraft?.length) {
      profile.email = emailDraft;
    }
    selectProfile(profile);
  };

  useEffect(() => {
    if (!profiles?.length && user) {
      collectProfileUsingDraft({
        fullName: user.fullName,
        company: user.company,
        phone: user.phone,
        email: user.email,
      });
      return;
    }
    if (profiles.length > 1) {
      return;
    }
    collectProfileUsingDraft({ ...profiles.at(0) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles]);

  if (!profiles?.length) {
    return null;
  }

  return (
    <div className="pay-dropdown" ref={inputRef}>
      <span className="link" onFocus={handleToggle} tabIndex={1}>
        {buildLocale(currentTranslate, 'payPageUserDropdown')}
      </span>
      {isComponentVisible && (
        <div className={`${isComponentVisible ? 'pay-dropdown_block' : ''}`}>
          {profiles.map((profile) => (
            <div
              className="info-row"
              key={profile.id}
              onClick={() => selectProfile(profile)}
              onMouseEnter={() => setShowInfo(formatUserProfile(profile))}
              onMouseLeave={() => setShowInfo(null)}>
              {formatUserProfile(profile)}
            </div>
          ))}
        </div>
      )}
      {showInfo && <div className="info-row-tooltip profile-tooltip">{showInfo}</div>}
    </div>
  );
};

export default UserProfilesDropdown;
