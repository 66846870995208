import React from 'react';
import { useForm } from 'react-hook-form';
import { MiniItemInput } from './Components/ItemInput';
import validation from '../../utils/validation';
import { IAddressRedux } from '../../typings/CustomTypes/IAddressRedux';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';

interface IFormAddressProps {
  idForm: string;
  defaultAddress?: IAddressRedux | null;
  onSubmit: (d: any) => any;
  onChangeBuild?: (id: number) => any;
  error?: boolean;
}

const FormDictionaryAddress = ({ idForm, onSubmit, defaultAddress, error }: IFormAddressProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: { ...defaultAddress },
  });

  const submit = (data: Record<string, any>) => {
    if (defaultAddress) {
      data.id = defaultAddress.id;
    }
    data.streetObj = data.street;
    // data.flatNumber = data.flatNumber === '' ? null : data.flatNumber;
    // data.entrance = data.entrance === '' ? null : data.entrance;
    // data.floor = data.floor === '' ? null : data.floor;
    onSubmit(data);
  };

  return (
    <form id={idForm} onSubmit={handleSubmit(submit)} className="modal-form-profile">
      {error && <span style={{ color: '#e05944' }}>{buildLocale(currentTranslate, 'profileAddressDublicate')}</span>}
      <div className="row checkout-delivery_address-full">
        <div className="col-md-6">
          <MiniItemInput
            errors={errors}
            {...register('street', validation.required)}
            value={watch('street')}
            label={buildLocale(currentTranslate, 'addressStreet')}
            labelClassName="required"
          />
        </div>
        <div className="col-md-6">
          <MiniItemInput
            errors={errors}
            {...register('buildNumber', validation.required)}
            value={watch('buildNumber')}
            label={buildLocale(currentTranslate, 'addressBuild')}
            labelClassName="required"
          />
        </div>
        <div className="col-md-6">
          <MiniItemInput
            errors={errors}
            {...register('city', validation.required)}
            value={watch('city')}
            label={buildLocale(currentTranslate, 'addressCity')}
            labelClassName="required"
          />
        </div>
        <div className="col-md-6">
          <MiniItemInput
            errors={errors}
            {...register('zipCode', validation.zipCode)}
            value={watch('zipCode')}
            label={buildLocale(currentTranslate, 'zipPlaceholder')}
            labelClassName="required"
          />
        </div>
        {/*<div className="col-6 col-md-4">*/}
        {/*  <MiniItemInput*/}
        {/*    errors={errors}*/}
        {/*    value={watch('flatNumber')}*/}
        {/*    placeholder={buildLocale(currentTranslate, 'tiFlatPlaceholder')}*/}
        {/*    {...register('flatNumber')}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="col-6 col-md-4">*/}
        {/*  <MiniItemInput*/}
        {/*    errors={errors}*/}
        {/*    value={watch('entrance')}*/}
        {/*    placeholder={buildLocale(currentTranslate, 'tiEntrancePlaceholder')}*/}
        {/*    {...register('entrance')}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="col-6 col-md-4">*/}
        {/*  <MiniItemInput*/}
        {/*    errors={errors}*/}
        {/*    placeholder={buildLocale(currentTranslate, 'tiFloorPlaceholder')}*/}
        {/*    value={watch('floor')}*/}
        {/*    {...register('floor')}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </form>
  );
};

export default FormDictionaryAddress;
