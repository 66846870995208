import React, { useState } from 'react';
import ContainerModal from '../../Modals/ContainerModal';
import { useForm } from 'react-hook-form';
import ItemInput from '../../Forms/Components/ItemInput';
import validation from '../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { actionsUser, selectorsUser } from '../../../redux/user/userReducer';
import { useAxios } from '../../../useHooks/useAxios';
import service from '../../../services/service';
import useDidUpdateEffect from '../../../useHooks/useDidUpdateEffect';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';

const FieldChangeEmail = React.memo(() => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const idForm = 'id_form_change_email';
  const dispatch = useDispatch<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { data, isLoading, request } = useAxios(service.updateProfile);

  const toggle = () => {
    setIsOpen((s) => !s);
  };

  const user = useSelector(selectorsUser.getUser)!;
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const emailRegister = register('email', validation.email);

  const onSubmit = async (data) => {
    await request(data)
      .then((response) => {
        if (response.success) {
          setErrorMessage('');
          setIsOpen(false);
          return;
        }
        setErrorMessage(response.data as string);
      })
      .catch((error) => {});
  };

  useDidUpdateEffect(() => {
    if (data) {
      dispatch(actionsUser.updateUser(data));
    }
  }, [data]);

  return (
    <React.Fragment>
      <button type="button" onClick={toggle} className="link-dashed">
        {buildLocale(currentTranslate, 'btnTextChangeEmail')}
      </button>
      <ContainerModal
        title={buildLocale(currentTranslate, 'btnTextChange')}
        idForm={idForm}
        isAdd={false}
        isOpen={isOpen}
        isLoading={isLoading}
        toggle={toggle}>
        <form id={idForm} onSubmit={handleSubmit(onSubmit)} className="modal-form-profile modal-form-profile-email">
          <ItemInput
            errors={errors}
            label={buildLocale(currentTranslate, 'tIEmailLabel')}
            labelClassName="required"
            defaultValue={user.email!}
            {...emailRegister}
            onChange={(e) => {
              setErrorMessage('');
              emailRegister.onChange(e);
            }}
          />
          {errorMessage && <span style={{ color: '#f3335c' }}>{errorMessage}</span>}
        </form>
      </ContainerModal>
    </React.Fragment>
  );
});

export default FieldChangeEmail;
