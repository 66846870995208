import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../../../../redux/locale/localeReducer';
import useComponentVisible from '../../../../../useHooks/useComponentVisible';
import { buildLocale } from '../../../../../utils/buildLocale';
import { actionsFilters, selectorsFilters } from '../../../../../redux/filters/filtersReducer';
import { SortingValues } from '../../../../../typings/CustomTypes/SortingValues';
import { SortingLabels } from '../../../../../typings/CustomTypes/SortingLabels';
import getSortingLabelByValue from '../../../../../utils/getSortingLabelByValue';

interface IProps {
  sortProducts?: (sortData: string | undefined) => void;
}

const SortComponentDesktop = (props: IProps) => {
  const { sortProducts } = props;
  const dispatch: any = useDispatch();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const sortDirectionFilter = useSelector(selectorsFilters.getSortDirection);
  const [isOpen, setIsOpen] = useState(false);
  const [sortLabel, setSortLabel] = useState(buildLocale(currentTranslate, SortingLabels.popular));

  const { ref: inputRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  useEffect(() => {
    const selectedLabel = getSortingLabelByValue(sortDirectionFilter);
    setSortLabel(buildLocale(currentTranslate, selectedLabel));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirectionFilter]);

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
    }
  }, [isComponentVisible]);

  const handleToggle = () => {
    setIsComponentVisible((f) => !f);
  };

  const handleOpeningState = () => {
    handleToggle();
    setIsOpen(!isOpen);
  };

  const changeSorting = (sortData: string | undefined) => {
    if (sortData === undefined) {
      dispatch(actionsFilters.setSortDirection(undefined));
      if (sortProducts) {
        sortProducts(sortData);
      }
      handleOpeningState();
      return;
    }

    dispatch(actionsFilters.setSortDirection(sortData));

    if (sortProducts) {
      sortProducts(sortData);
    }
    handleOpeningState();
  };

  return (
    <div className="catalog-sort_drop" ref={inputRef} onClick={handleOpeningState}>
      <span className="catalog-sort_caption">
        <span className="label">{buildLocale(currentTranslate, 'filterModalTitle')}: </span>
        <span>{sortLabel}</span>
      </span>
      {isOpen && (
        <div className="category-radio sorting-items" onClick={(e) => e.stopPropagation()}>
          <div className="form-radio">
            <input
              type="radio"
              id="default"
              checked={sortDirectionFilter === undefined}
              onChange={(e) => {
                e.stopPropagation();
                changeSorting(undefined);
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <label htmlFor="default">{buildLocale(currentTranslate, SortingLabels.popular)}</label>
          </div>
          <div className="form-radio">
            <input
              type="radio"
              id="highPrice"
              checked={!!sortDirectionFilter?.length && sortDirectionFilter.includes(SortingValues.ascPrice)}
              onChange={(e) => {
                e.stopPropagation();
                changeSorting(SortingValues.ascPrice);
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <label htmlFor="highPrice">{buildLocale(currentTranslate, SortingLabels.ascPrice)}</label>
          </div>
          <div className="form-radio">
            <input
              type="radio"
              id="lowPrice"
              checked={!!sortDirectionFilter?.length && sortDirectionFilter.includes(SortingValues.descPrice)}
              onChange={(e) => {
                e.stopPropagation();
                changeSorting(SortingValues.descPrice);
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <label htmlFor="lowPrice">{buildLocale(currentTranslate, SortingLabels.descPrice)}</label>
          </div>
          <div className="form-radio">
            <input
              type="radio"
              id="lowDiscountPercentSorting"
              checked={!!sortDirectionFilter?.length && sortDirectionFilter.includes(SortingValues.descDiscount)}
              onChange={(e) => {
                e.stopPropagation();
                changeSorting(SortingValues.descDiscount);
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <label htmlFor="lowDiscountPercentSorting">
              {buildLocale(currentTranslate, SortingLabels.descDiscount)}
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortComponentDesktop;
