import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { actionsFilters, selectorsFilters } from '../../../../../redux/filters/filtersReducer';
import { selectorsLocale } from '../../../../../redux/locale/localeReducer';
import { IComboboxFilterItem } from '../../../../../typings/IFilters';
import { buildLocale } from '../../../../../utils/buildLocale';
import CustomDropdownFilter from './CustomDropdownFilter';
import sortFilters from '../../../../../utils/sortFilters';
import createHashFromFilter from '../../../../../utils/createHashFromFilter';
import { useNavigate, useNavigationType } from 'react-router';
import { IUseApiQuery } from '../../../../../typings/IUseApiQuery';
import { actionsOther, selectorsOther } from '../../../../../redux/other/otherReducer';
import isLocationForFirstLoadedPage from '../../../../../utils/isLocationForFirstLoadedPage';
import { FilterSearchingParams } from '../../../../../redux/filters/filtersTypes';

interface IProps {
  isLoading: boolean;
  brandsQuery: IUseApiQuery;
  brands: IComboboxFilterItem[];
  badges: IComboboxFilterItem[];
  allergich: IComboboxFilterItem[];
  nutrion: IComboboxFilterItem[];
  searchQueryData: FilterSearchingParams;
}

const FiltersComponentDesktop = (props: IProps) => {
  const { isLoading, brandsQuery, brands, badges, allergich, nutrion, searchQueryData } = props;
  const dispatch: any = useDispatch();
  const location = useLocation();
  const navigationType = useNavigationType();
  const navigate = useNavigate();

  const skipBrands = !!brandsQuery?.options?.skip;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const searchProductFilter = useSelector(selectorsOther.getProductsSearchQuery);
  const sortDirection = useSelector(selectorsFilters.getSortDirection);
  const customCategory = useSelector(selectorsFilters.getCustomCategory);

  const [isOpenBrands, setIsOpenBrands] = useState(false);
  const [isOpenBadges, setIsOpenBadges] = useState(false);
  const [isOpenAllergich, setIsOpenAllergich] = useState(false);
  const [isOpenNutrion, setIsOpenNutrion] = useState(false);
  const [shownBrands, setShownBrands] = useState<IComboboxFilterItem[]>([]);
  const [shownBadges, setShownBadges] = useState<IComboboxFilterItem[]>([]);
  const [shownAllergich, setShownAllergich] = useState<IComboboxFilterItem[]>([]);
  const [shownNutrion, setShownNutrion] = useState<IComboboxFilterItem[]>([]);
  const [searchValue, setSearchValue] = useState<string | null>(searchFilter || '');
  const sortedBrands = useMemo(() => sortFilters(brands || []), [brands]);
  const sortedBadges = useMemo(() => sortFilters(badges || []), [badges]);
  const sortedAllergich = useMemo(() => sortFilters(allergich || []), [allergich]);
  const sortedNutrion = useMemo(() => sortFilters(nutrion || []), [nutrion]);
  const isSearchPage = location.pathname.includes('all_products') || location.pathname.includes('searching');

  useEffect(() => {
    if (skipBrands) {
      dispatch(actionsFilters.setSelectedBrands([]));
    }
    if (!isLocationForFirstLoadedPage(location.key, navigationType)) {
      return;
    }
    if (searchQueryData.isEmpty) {
      dispatch(actionsFilters.clearAllSelected());
      return;
    }
    dispatch(actionsFilters.setSelectedBrands(searchQueryData.selectedBrands || []));
    dispatch(actionsFilters.setSelectedBadges(searchQueryData.selectedBadges || []));
    dispatch(actionsFilters.setSelectedAllergich(searchQueryData.selectedAllergich || []));
    dispatch(actionsFilters.setSelectedNutrion(searchQueryData.selectedNutrion || []));
    dispatch(actionsFilters.setHasPriceRanges(searchQueryData.hasPriceRanges || false));
    dispatch(actionsFilters.setHasBadges(searchQueryData.hasBadges || false));
    dispatch(actionsFilters.setHasSalePrice(searchQueryData.hasSalePrice || false));
    dispatch(actionsFilters.setSearchFilter(searchQueryData.searchFilter || ''));
    dispatch(actionsFilters.setSortDirection(searchQueryData.sortDirection));
    dispatch(actionsFilters.setCustomCategory(searchQueryData.customCategory));

    if (isSearchPage) {
      dispatch(actionsOther.setProductsSearchQuery(searchQueryData.productSearchFilter || ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShownBadges(badges);
    setShownBrands(brands);
    setShownAllergich(allergich);
    setShownNutrion(nutrion);
    setSearchValue(searchFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badges, brands, allergich, nutrion, searchFilter]);

  const generateUlrTail = () => {
    let queryTail: string =
      createHashFromFilter('brands', selectedBrands) +
      createHashFromFilter('badges', selectedBadges) +
      createHashFromFilter('allergens', selectedAllergich) +
      createHashFromFilter('nutrition', selectedNutrion);

    const tailParams: string[] = [];
    if (hasPriceRanges) {
      tailParams.push(`hasPriceRanges=${hasPriceRanges}`);
    }
    if (hasBadges) {
      tailParams.push(`hasBadges=${hasBadges}`);
    }
    if (hasSalePrice) {
      tailParams.push(`hasSalePrice=${hasSalePrice}`);
    }
    if (searchFilter) {
      tailParams.push(`searchFilter=${searchFilter}`);
    }
    if (isSearchPage && searchProductFilter) {
      tailParams.push(`search=${searchProductFilter}`);
    }
    if (sortDirection) {
      tailParams.push(`sort=${sortDirection}`);
    }
    if (customCategory) {
      tailParams.push(`customCategory=${customCategory}`);
    }

    if (tailParams?.length) {
      queryTail += tailParams.join('&');
    }
    const newSearchTail = queryTail.replace(/&$/, '');
    const navigationLink = `${location.pathname}${newSearchTail?.length ? '?' + newSearchTail : ''}`;

    navigate(navigationLink);
  };

  useEffect(() => {
    generateUlrTail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedBrands,
    selectedBadges,
    selectedAllergich,
    selectedNutrion,
    hasPriceRanges,
    hasBadges,
    hasSalePrice,
    searchFilter,
    sortDirection,
    customCategory,
  ]);

  useEffect(() => {
    if (isSearchPage) {
      generateUlrTail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProductFilter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(actionsFilters.setSearchFilter(searchValue));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const addBrand = (brand: IComboboxFilterItem) => {
    const currentFilter = selectedBrands.find((f) => f.id === brand.id);
    if (!currentFilter) {
      dispatch(actionsFilters.setSelectedBrands([...selectedBrands, brand]));
    }
  };

  const addBadge = (badge: IComboboxFilterItem) => {
    const currentFilter = selectedBadges.find((f) => f.id === badge.id);
    if (!currentFilter) {
      dispatch(actionsFilters.setSelectedBadges([...selectedBadges, badge]));
    }
  };

  const addAllergich = (a: IComboboxFilterItem) => {
    const currentFilter = selectedAllergich.find((f) => f.id === a.id);
    if (!currentFilter) {
      dispatch(actionsFilters.setSelectedAllergich([...selectedAllergich, a]));
    }
  };

  const addNutrion = (n: IComboboxFilterItem) => {
    const currentFilter = selectedNutrion.find((f) => f.id === n.id);
    if (!currentFilter) {
      dispatch(actionsFilters.setSelectedNutrion([...selectedNutrion, n]));
    }
  };

  const removeBrand = (brand: IComboboxFilterItem) => {
    const newFilters = selectedBrands.slice(0).filter((f) => f.id !== brand.id);
    dispatch(actionsFilters.setSelectedBrands(newFilters));
  };

  const removeBadge = (badge: IComboboxFilterItem) => {
    const newFilters = selectedBadges.slice(0).filter((f) => f.id !== badge.id);
    dispatch(actionsFilters.setSelectedBadges(newFilters));
  };

  const removeAllergich = (a: IComboboxFilterItem) => {
    const newFilters = selectedAllergich.slice(0).filter((f) => f.id !== a.id);
    dispatch(actionsFilters.setSelectedAllergich(newFilters));
  };

  const removeNutrion = (n: IComboboxFilterItem) => {
    const newFilters = selectedNutrion.slice(0).filter((f) => f.id !== n.id);
    dispatch(actionsFilters.setSelectedNutrion(newFilters));
  };

  const isCheckedBrand = (id: number) => {
    return !!selectedBrands.find((filter) => filter.id === id);
  };

  const isCheckedBadge = (id: number) => {
    return !!selectedBadges.find((filter) => filter.id === id);
  };
  const isCheckedAllergich = (id: number) => {
    return !!selectedAllergich.find((filter) => filter.id === id);
  };
  const isCheckedNutrion = (id: number) => {
    return !!selectedNutrion.find((filter) => filter.id === id);
  };

  const onSearch = (text: string) => {
    if (isOpenBrands) {
      if (!text) {
        setShownBrands(brands);
        return;
      }
      setShownBrands(brands?.slice(0).filter((b) => b.nameDe.toLowerCase().includes(text.toLowerCase())));
      return;
    }
    if (isOpenAllergich) {
      if (!text) {
        setShownAllergich(allergich);
        return;
      }
      setShownAllergich(allergich?.slice(0).filter((b) => b.nameDe.toLowerCase().includes(text.toLowerCase())));
      return;
    }
    if (isOpenNutrion) {
      if (!text) {
        setShownNutrion(nutrion);
        return;
      }
      setShownNutrion(nutrion?.slice(0).filter((b) => b.nameDe.toLowerCase().includes(text.toLowerCase())));
      return;
    }
    if (!text) {
      setShownBadges(badges);
      return;
    }
    setShownBadges(badges?.slice(0).filter((b) => b.nameDe.toLowerCase().includes(text.toLowerCase())));
    return;
  };

  return (
    <div className="category-filters_list">
      <CustomDropdownFilter
        addItem={addBrand}
        isChecked={isCheckedBrand}
        isLoading={isLoading}
        isOpenCurrent={isOpenBrands}
        items={sortedBrands}
        onSearch={onSearch}
        removeItem={removeBrand}
        setIsOpenCurrent={setIsOpenBrands}
        shownItems={shownBrands}
        title={buildLocale(currentTranslate, 'filtersBrandsTitle')}
        setShownItems={setShownBrands}
      />
      <CustomDropdownFilter
        addItem={addBadge}
        isChecked={isCheckedBadge}
        isLoading={isLoading}
        isOpenCurrent={isOpenBadges}
        items={sortedBadges}
        onSearch={onSearch}
        removeItem={removeBadge}
        setIsOpenCurrent={setIsOpenBadges}
        shownItems={shownBadges}
        title={buildLocale(currentTranslate, 'filtersBadgesTitle')}
        setShownItems={setShownBadges}
      />
      <CustomDropdownFilter
        addItem={addAllergich}
        isChecked={isCheckedAllergich}
        isLoading={isLoading}
        isOpenCurrent={isOpenAllergich}
        items={sortedAllergich}
        onSearch={onSearch}
        removeItem={removeAllergich}
        setIsOpenCurrent={setIsOpenAllergich}
        shownItems={shownAllergich}
        title={buildLocale(currentTranslate, 'filtersAllergicTitle')}
        setShownItems={setShownAllergich}
      />
      <CustomDropdownFilter
        addItem={addNutrion}
        isChecked={isCheckedNutrion}
        isLoading={isLoading}
        isOpenCurrent={isOpenNutrion}
        items={sortedNutrion}
        onSearch={onSearch}
        removeItem={removeNutrion}
        setIsOpenCurrent={setIsOpenNutrion}
        shownItems={shownNutrion}
        title={buildLocale(currentTranslate, 'filtersErnahrungTitle')}
        setShownItems={setShownNutrion}
      />
      <div className="category-filters_check">
        <div className="form-check">
          <input
            id="filter-check"
            type="checkbox"
            checked={hasPriceRanges}
            onChange={() => dispatch(actionsFilters.setHasPriceRanges(!hasPriceRanges))}
          />
          <label htmlFor="filter-check" className="form-check-label">
            {buildLocale(currentTranslate, 'filtersHasPriceRanges')}
          </label>
        </div>
      </div>
      <div className="category-filters_check">
        <div className="form-check">
          <input
            id="filter-badges"
            type="checkbox"
            checked={hasBadges}
            onChange={() => dispatch(actionsFilters.setHasBadges(!hasBadges))}
          />
          <label htmlFor="filter-badges" className="form-check-label">
            {buildLocale(currentTranslate, 'justBioFilter')}
          </label>
        </div>
      </div>
      <div className="category-filters_check">
        <div className="form-check">
          <input
            id="filter-sale-price"
            type="checkbox"
            checked={hasSalePrice}
            onChange={() => dispatch(actionsFilters.setHasSalePrice(!hasSalePrice))}
          />
          <label htmlFor="filter-sale-price" className="form-check-label">
            {buildLocale(currentTranslate, 'hasSalePriceFilter')}
          </label>
        </div>
      </div>
      {/* {showSearchFilter && ( */}
      <div className="category-filters_search">
        <input
          type="text"
          placeholder={buildLocale(currentTranslate, 'searchFilterPlaceholder')}
          className="form-control search-filter"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue || ''}
        />
        {searchValue && (
          <span className="clear" style={{ display: 'flex' }} onClick={() => setSearchValue('')}>
            ✕
          </span>
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default FiltersComponentDesktop;
