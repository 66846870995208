import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsFilters } from '../../../../../../redux/filters/filtersReducer';
import { selectorsLocale } from '../../../../../../redux/locale/localeReducer';
import {
  AttributesType,
  FILTERS_TYPE,
  IAttribute,
  IComboboxFilterItem,
  IMainFilters,
  ISelectedFilter,
  ISelectedFilters,
} from '../../../../../../typings/IFilters';
import { buildLocale } from '../../../../../../utils/buildLocale';
import sortFilters from '../../../../../../utils/sortFilters';

interface IProps {
  items: ISelectedFilters;
  selectedFilter: IMainFilters | null;
  setSelectedFiltersArray: (filters: IComboboxFilterItem[] | AttributesType) => void;
  selectedFiltersArray: IComboboxFilterItem[] | AttributesType;
}

const FiltersComponent = (props: IProps) => {
  const { items, selectedFilter, setSelectedFiltersArray, selectedFiltersArray } = props;
  const [shownItems, setShownItems] = useState<ISelectedFilters>([]);
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const sortedShownItems = useMemo(() => sortFilters(shownItems), [shownItems]);

  useEffect(() => {
    setShownItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterByType = () => {
    if (!selectedFilter) {
      return [];
    }
    switch (selectedFilter.type) {
      case FILTERS_TYPE.BRANDS: {
        return selectedBrands;
      }
      case FILTERS_TYPE.BADGES: {
        return selectedBadges;
      }
      case FILTERS_TYPE.ALLERGICH: {
        return selectedAllergich;
      }
      case FILTERS_TYPE.NUTRION: {
        return selectedNutrion;
      }
      default:
        return [];
    }
  };

  useEffect(() => {
    setSelectedFiltersArray(getFilterByType());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFilter = (filter: IComboboxFilterItem | IAttribute) => {
    setSelectedFiltersArray(selectedFiltersArray.slice(0).filter((f) => f.id !== filter.id));
  };

  const addFilter = (filter: IComboboxFilterItem | IAttribute) => {
    const currentFilter = selectedFiltersArray.find((f) => f.id === filter.id);
    if (!currentFilter) {
      setSelectedFiltersArray([...selectedFiltersArray, filter]);
    }
  };

  const isCheckedSelected = (id: number) => {
    return selectedFiltersArray.find((filter) => filter.id === id);
  };

  const onSearch = (text: string) => {
    if (!text) {
      return setShownItems(items.slice(0));
    }
    setShownItems(
      items
        .slice(0)
        .filter((item) => item.nameDe.toLowerCase().includes(text.toLowerCase()) || isCheckedSelected(item.id)),
    );
  };

  return (
    <div className="mobile-filter_choose">
      <div className="mobile-filter_search">
        <input
          placeholder={buildLocale(currentTranslate, 'filtersSearchPlaceholder')}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <div className="mobile-filter_checkbox-list">
        {selectedFilter &&
          sortedShownItems.length > 0 &&
          sortedShownItems.map((item: ISelectedFilter) => {
            return (
              <div className="mobile-filter_checkbox" key={item.id}>
                <input
                  type="checkbox"
                  id={`select-filter${item.id}`}
                  defaultChecked={isCheckedSelected(item.id)}
                  onChange={() => (isCheckedSelected(item.id) ? removeFilter(item) : addFilter(item))}
                />
                <label htmlFor={`select-filter${item.id}`}>{item.nameDe}</label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FiltersComponent;
