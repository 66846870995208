import React from 'react';
import ShowError from './ShowError';
import { generateClassName } from '../../../utils/generateClassName';

interface IInputItemProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  errors: any;
  label?: string;
  name: string;
  value?: string | any;
  labelClassName?: string;
}

const ItemInput = React.forwardRef(
  ({ label, labelClassName, name, type, errors, placeholder, ...rest }: IInputItemProps, ref: any) => {
    const isError = !!errors[name];

    return (
      <div className="profile-info_row">
        {label && <label className={`profile-info_label${labelClassName ? ' ' + labelClassName : ''}`}>{label}</label>}
        <div className={'profile-form-item'}>
          <input
            type={type || 'text'}
            className={generateClassName('form-control', {
              'error-form-border': isError,
            })}
            name={name}
            ref={ref}
            {...rest}
            placeholder={placeholder}
          />
          <ShowError errors={errors} name={name} />
        </div>
      </div>
    );
  },
);

const MiniItemInput = React.forwardRef(
  (
    {
      label,
      labelClassName,
      name,
      type = 'text',
      errors,
      placeholder,
      onChange,
      defaultValue,
      value,
      ...rest
    }: IInputItemProps,
    ref: any,
  ) => {
    const isError = !!errors[name];

    return (
      <div
        className={generateClassName('form-part', {
          filled: !!value,
        })}>
        {label && <label className={labelClassName ? labelClassName : ''}>{label}</label>}
        <div className={'profile-form-item'}>
          <input
            type={type}
            name={name}
            ref={ref}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            className={generateClassName('form-control', {
              'error-form-border': isError,
            })}
            {...rest}
          />
          <ShowError errors={errors} name={name} />
        </div>
      </div>
    );
  },
);

export { MiniItemInput };
export default ItemInput;
