import { SortingLabels } from '../typings/CustomTypes/SortingLabels';
import { SortingValues } from '../typings/CustomTypes/SortingValues';

const getSortingLabelByValue = (value: string | undefined) => {
  switch (value) {
    case SortingValues.ascPrice:
      return SortingLabels.ascPrice;
    case SortingValues.descPrice:
      return SortingLabels.descPrice;
    case SortingValues.descDiscount:
      return SortingLabels.descDiscount;
    default:
      return SortingLabels.popular;
  }
};

export default getSortingLabelByValue;
