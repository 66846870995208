import React, { useEffect, useMemo, useState } from 'react';
import { isBrowser } from '../utils/isBrowser';
import { Link, useLocation, useParams } from 'react-router-dom';
import CategoriesItemMobile from '../components/CategoriesMobile/CategoriesItemMobile';
import CategoriesMobileFooter from '../components/CategoriesMobile/CategoriesMobileFooter';
import Products from '../components/Products/Products';
import useParseQuery from '../useHooks/useParseQuery';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductLoader from '../components/Loaders/ProductLoader';
import CustomSlider from '../components/common/CustomSlider';
import { buildLocale } from '../utils/buildLocale';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { usePaths } from '../components/Routes/RouterList';
import { CategoryMobilePages } from '../typings/ICategory';
import { actionsFilters, selectorsFilters } from '../redux/filters/filtersReducer';
import FilterComponent from '../components/common/Filters/FilterComponent';
import { FILTER_TYPE } from '../typings/IFilters';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectorsCategory } from '../redux/category/categoryReducer';
import { useNavigate, useNavigationType } from 'react-router';
import GoBackBtn from '../components/CategoriesMobile/GoBackBtn';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';
import useFilterQueryParams from '../useHooks/useFilterQueryParams';
import useFilterDataFromSearchParams from '../useHooks/useFilterDataFromSearchParams';

const CategoriesMobilePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const paths = usePaths();
  const { id, type } = useParams();
  const categoryId = Number(id);
  const isCategoryIdNan = isNaN(categoryId);
  const dispatch: any = useDispatch();
  const query = useParseQuery('search');
  const ownCategoriesMain = useSelector(selectorsCategory.getRootCategories);
  const categoriesMain = useSelector(selectorsCategory.getMainCategories);
  const ownCategoriesChildren = useSelector(selectorsCategory.getDeepCategoriesById(categoryId));
  const category = useSelector(selectorsCategory.getCategoriesById(categoryId || 0));
  const categoryLevel = useSelector(selectorsCategory.getChainsCategories(categoryId))?.length;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const [limit, setLimit] = useState(4);
  const [isHasMore, setIsHasMore] = useState(true);
  const [sortingData, setSortingData] = useState<undefined | string>(undefined);
  const [productsTrigger, setProductsTrigger] = useState(false);
  const [isLoadingProducts] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [shownCategories, setShownCategories] = useState<Array<any>>([]);

  const navigationType = useNavigationType();

  const getActualFilters = () => {
    return {
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
    };
  };

  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({
    type: FILTER_TYPE.OWNCATEGORY,
    id: categoryId,
    skip: isCategoryIdNan,
    filters: getActualFilters(),
  });
  const { brands, badges, allergich, nutrion, searchQueryData } = useFilterDataFromSearchParams(
    brandsQuery,
    badgesQuery,
    attributesQuery,
  );

  const companies = [
    { title: buildLocale(currentTranslate, 'footerAboutUs'), link: paths.about_us },
    { title: buildLocale(currentTranslate, 'footerRegionalProducts'), link: paths.regional_products },
    { title: buildLocale(currentTranslate, 'footerRegionalPartners'), link: paths.regional_partners },
    { title: buildLocale(currentTranslate, 'brandsFooter'), link: paths.brands },
    { title: buildLocale(currentTranslate, 'footerBioBadges'), link: paths.bio_badges },
    { title: buildLocale(currentTranslate, 'footerDelivery'), link: paths.delivery },
    { title: buildLocale(currentTranslate, 'footerPresse'), link: paths.presse },
    { title: buildLocale(currentTranslate, 'footerFaq'), link: paths.faq },
    { title: buildLocale(currentTranslate, 'footerConfid'), link: paths.confidentiality },
    { title: buildLocale(currentTranslate, 'footerImpressum'), link: paths.impressum },
  ];

  useEffect(() => {
    if (!categoryId && !type) {
      setItems(ownCategoriesMain);
      dispatch(actionsFilters.clearAllSelected());
      return;
    }
    if (type === CategoryMobilePages.categories) {
      setItems(categoriesMain);
      dispatch(actionsFilters.clearAllSelected());
      return;
    }
    if (categoryId && type === CategoryMobilePages.own_categories) {
      const childrenList = ownCategoriesChildren.filter((item) => Number(item?.parent?.id) === categoryId);
      setItems(childrenList);
      setShownCategories(ownCategoriesChildren.slice(0, 4));
    }
    if (type === CategoryMobilePages.companies) {
      setItems(companies);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (isBrowser && window.screen.width > 991) {
      navigate(paths.main);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLimit = () => {
    const newLimit = limit + 1;
    setLimit(newLimit);
    const childrenList = ownCategoriesChildren.filter((item) => Number(item?.parent?.id) === categoryId);
    const newShownCategories = childrenList.slice(0, newLimit);
    setShownCategories(newShownCategories);
    if (newShownCategories.length > 0 && newShownCategories.length >= childrenList.length) {
      setIsHasMore(false);
    }
  };

  const setSort = (sortData: string | undefined) => {
    setSortingData(sortData);
    applyFilters();
  };

  const applyFilters = () => {
    setProductsTrigger(!productsTrigger);
  };

  const getActualTitle = () => {
    if (!id && !type) {
      return '';
    }
    if (type === CategoryMobilePages.categories) {
      return buildLocale(currentTranslate, 'categoriesCatalog');
    }
    if (id && type === CategoryMobilePages.own_categories) {
      return category.parent?.name || buildLocale(currentTranslate, 'categoriesCatalog');
    }
    return '';
  };

  const backBtn = useMemo(() => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return '';
    }
    const actualTitle = getActualTitle();

    if (type === CategoryMobilePages.companies) {
      return (
        <GoBackBtn
          location={location}
          navigate={navigate}
          translate={currentTranslate}
          buttonTitle={buildLocale(currentTranslate, 'categoriesCatalog')}
          categoryTitle={buildLocale(currentTranslate, 'companyTitle')}
        />
      );
    }
    if (!actualTitle?.length) {
      return <h1>{buildLocale(currentTranslate, 'categoriesCatalog')}</h1>;
    }

    return (
      <GoBackBtn
        location={location}
        navigate={navigate}
        translate={currentTranslate}
        buttonTitle={actualTitle}
        categoryTitle={category?.name || ''}
        containerClassName="mobile-categories_breadcrumbs"
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const navigateToCompanyCategoryPage = (e: React.MouseEvent<HTMLElement>, companyLink: string) => {
    e.preventDefault();
    const historyItem = `${location.pathname}${location.search}`;
    const historyData = location?.state?.from?.length ? [...location.state.from, historyItem] : [historyItem];
    const historyFiltersData = location?.state?.filters;

    navigate(`${companyLink}${location.search}`, { state: { from: historyData, filters: historyFiltersData } });
  };

  if (type === CategoryMobilePages.companies) {
    return (
      <section className="mobile-categories">
        <div className="mobile-categories_list">
          {backBtn}
          {companies.map((company) => (
            <div className="mobile-categories_one" key={company.title}>
              <div className="mobile-categories_one-link">
                <Link to={company.link} onClick={(e) => navigateToCompanyCategoryPage(e, company.link)}>
                  {company.title}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }

  return (
    <section className="mobile-categories">
      <div className="mobile-categories_list">
        <div className="mobile-categories_top">{backBtn}</div>
        {!id && !type && <CategoriesItemMobile isCategories={true} location={location} />}
        {items.length > 0 &&
          items.map((category) => <CategoriesItemMobile category={category} key={category.id} location={location} />)}
        {!id && !type && <CategoriesItemMobile isCompanies={true} location={location} />}
        {id && (category?.hasChildren || category?.children?.length > 0) && !(categoryLevel > 2) && (
          <FilterComponent
            id={+id}
            type={FILTER_TYPE.OWNCATEGORY}
            applyFilters={applyFilters}
            isLoading={isLoadingProducts}
            sortProducts={setSort}
            filters={getActualFilters()}
            brands={brands}
            badges={badges}
            allergich={allergich}
            nutrion={nutrion}
            searchQueryData={searchQueryData}
          />
        )}
        {id && (!category?.hasChildren || category?.children?.length === 0 || categoryLevel === 3) && (
          <Products query={query} hideBackBtn={true} id={categoryId} />
        )}
        {id && (category?.hasChildren || category?.children?.length > 0) && !(categoryLevel > 2) && (
          <div className="mobile-categories_products">
            <InfiniteScroll
              dataLength={shownCategories.length}
              style={{ overflowX: 'hidden' }}
              next={() => changeLimit()}
              hasMore={isHasMore}
              loader={<ProductLoader />}>
              {shownCategories.map((category: any) => (
                <CustomSlider
                  key={category.id}
                  id={category.id}
                  name={category.name}
                  route={category.hasChildren ? 'categories_page/own_categories' : 'products'}
                  setLimit={changeLimit}
                  sortingData={sortingData}
                  filters={getActualFilters()}
                />
              ))}
            </InfiniteScroll>
          </div>
        )}
      </div>
      <CategoriesMobileFooter />
    </section>
  );
};

export default CategoriesMobilePage;
