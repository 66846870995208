import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Product from '../components/Products/Product';
import service from '../services/service';
import { IProduct } from '../typings/IProduct';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import { usePaths } from '../components/Routes/RouterList';
import FilterComponent from '../components/common/Filters/FilterComponent';
import { FILTER_TYPE, IFiltersDTO } from '../typings/IFilters';
import { selectorsFilters } from '../redux/filters/filtersReducer';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductLoader from '../components/Loaders/ProductLoader';
import { useLessThen991 } from '../utils/mediaQuery';
import { scrollThreshold } from '../constants/scrollThreshold';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import { useNavigate } from 'react-router';
import getSortDataForFilter from '../utils/getSortDataForFilter';
import { selectorsOther } from '../redux/other/otherReducer';
import useFilterQueryParams from '../useHooks/useFilterQueryParams';
import useFilterDataFromSearchParams from '../useHooks/useFilterDataFromSearchParams';
import CommonHelmet from '../components/common/CommonHelmet';
import Breadcrumbs from '../components/common/Breadcrumbs';
import FilterWishlistCustomCategories from '../components/common/Filters/FilterWishlistCustomCategories';

const WishlistPage = () => {
  const path = usePaths();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const navigate = useNavigate();
  const perPage = 20;
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);

  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const selectedSort = useSelector(selectorsFilters.getSortDirection);
  const selectedCustomCategory = useSelector(selectorsFilters.getCustomCategory);

  const [items, setItems] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [productsSkip, setProductsSkip] = useState(0);
  const [isHaveMore, setIsHaveMore] = useState(true);

  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({
    type: FILTER_TYPE.WISHLIST,
    filters: {
      ...({} as IFiltersDTO),
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
      customCategoryFilter: selectedCustomCategory || '',
    },
  });
  const { brands, badges, allergich, nutrion, searchQueryData } = useFilterDataFromSearchParams(
    brandsQuery,
    badgesQuery,
    attributesQuery,
  );

  const getActualFilters = () => {
    return {
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      orderProducts: getSortDataForFilter(searchQueryData, selectedSort),
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
      customCategoryFilter: selectedCustomCategory || '',
    };
  };

  const getWishlistItems = (isWithFilter?: boolean) => {
    setIsLoading(true);

    const filters = getActualFilters();

    service
      .getWishlist(filters, isInCourierArea, 20, isWithFilter ? 0 : productsSkip, filters.orderProducts)
      .then((value: any) => {
        if (value.code === 401) {
          navigate(path.login);
        }
        if (value.data.length < 20) {
          setIsHaveMore(false);
        }
        setProductsSkip((prevSkip) => prevSkip + perPage);
        setItems((prevProducts) => [...prevProducts, ...value.data]);
      })
      .catch((e) => setItems([]));
  };

  const applyFilters = () => {
    setProductsSkip(0);
    setItems([]);
    setIsHaveMore(true);
    getWishlistItems(true);
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, selectedCustomCategory, selectedSort]);

  const removeFromWishlist = (id: number) => {
    setItems((items) => items.filter((item) => item.id !== id));
  };

  return (
    <div className="category-page">
      <CommonHelmet title={`${buildLocale(currentTranslate, 'wishListTitle')} | MyBio.de`}></CommonHelmet>
      <section className={'section-main no-banner-crumbs'}>
        <Breadcrumbs lastItemName={buildLocale(currentTranslate, 'wishListTitle')} />
      </section>
      <h2>{buildLocale(currentTranslate, 'wishListTitle')}</h2>
      <FilterWishlistCustomCategories filters={getActualFilters()} />
      <FilterComponent
        type={FILTER_TYPE.WISHLIST}
        applyFilters={applyFilters}
        isLoading={isLoading}
        filters={getActualFilters()}
        brands={brands}
        badges={badges}
        allergich={allergich}
        nutrion={nutrion}
        searchQueryData={searchQueryData}
      />
      <div className="search-result">
        <div className="container">
          {items && items.length > 0 && (
            <InfiniteScroll
              next={() => {
                getWishlistItems();
              }}
              hasMore={isHaveMore}
              loader={<ProductLoader />}
              dataLength={items.length}
              scrollThreshold={scrollThreshold}
              style={{ overflow: isLess991 ? 'visible visible' : 'hidden auto' }}>
              <>
                {items.map((product: any) => (
                  <div key={product.id} className="col-6 col-md-4 col-lg-3 col-xl-2 catalog-item">
                    <Product product={product} action={removeFromWishlist} isWishList={true} />
                  </div>
                ))}
              </>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default WishlistPage;
