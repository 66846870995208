import React from 'react';
import SortComponentDesktop from './components/desktop/SortComponentDesktop';
import FiltersComponentDesktop from './components/desktop/FiltersComponentDesktop';
import SelectedDesktopFilters from './components/SelectedDesktopFilters';
import { IUseApiQuery } from '../../../typings/IUseApiQuery';
import { IComboboxFilterItem } from '../../../typings/IFilters';
import { FilterSearchingParams } from '../../../redux/filters/filtersTypes';

interface IProps {
  isLoading: boolean;
  sortProducts?: (sortData: string | undefined) => void;
  hideSort?: boolean;
  brandsQuery: IUseApiQuery;
  brands: IComboboxFilterItem[];
  badges: IComboboxFilterItem[];
  allergich: IComboboxFilterItem[];
  nutrion: IComboboxFilterItem[];
  searchQueryData: FilterSearchingParams;
}

const FiltersDesktop = (props: IProps) => {
  const { isLoading, sortProducts, hideSort, brandsQuery, brands, badges, allergich, nutrion, searchQueryData } = props;

  return (
    <div className="catalog-filter-sort">
      <div className="container">
        <FiltersComponentDesktop
          isLoading={isLoading}
          brandsQuery={brandsQuery}
          brands={brands}
          badges={badges}
          allergich={allergich}
          nutrion={nutrion}
          searchQueryData={searchQueryData}
        />
        {!hideSort && <SortComponentDesktop sortProducts={sortProducts} />}
      </div>
      <SelectedDesktopFilters />
    </div>
  );
};

export default FiltersDesktop;
