import React, { useEffect, useState } from 'react';
import { IProduct } from '../../typings/IProduct';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';
import CartCountItem from '../Cart/CartCountItem';
import { useFormattingContext } from '../../context/FormattingContext';
import { getIndexProductOption } from '../../utils/getIndexProductOption';
import { ID_UNIT_WEIGHT } from '../../constants/constantsId';
import ProductImage from '../ImagesComponent/ProductImage';
import { generateClassName } from '../../utils/generateClassName';
import wishlistIcon from '../../assets/images/ic-heart_o.svg';
import wishlistIconNonActive from '../../assets/images/ic-heart.svg';
import service from '../../services/service';
import { selectorsUser } from '../../redux/user/userReducer';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { usePaths } from '../Routes/RouterList';
import ProductCard from '../Modals/ProductCard';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import useDataLayerViewItem from '../../utils/DataLayer/useDataLayerViewItem';
import { DL_helper } from '../../utils/DataLayer/DL_helper';
import BadgesList from '../common/BadgesList';
import UnitPrice from './UnitPrice';
import { getCapacity, getUnitType } from '../../utils/calculateUnits';
import { isMetroShop } from '../../constants/shops';
import config from '../../config';
import ProductPriceBlockMetro from './ProductPriceBlockMetro';
import { actionsOther } from '../../redux/other/otherReducer';
import { useLocation } from 'react-router-dom';
import SaleDiscountWidget from './SaleDiscountWidget';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';

interface IProductProps {
  product: IProduct;
  onOrder?: () => any;
  isShowButtonOrder?: boolean;
  currentSellPoint?: number;
  action?: (id: number) => any;
  onProductImageClick?: (product) => any;
  isWishList?: boolean;
  isDefaultImg?: boolean;
}

const Product = React.memo(
  ({ product, isShowButtonOrder = true, action, isWishList = false, isDefaultImg }: IProductProps) => {
    const currentTranslate = useSelector(selectorsLocale.getTranslate);
    const { formatPrice } = useFormattingContext();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const paths = usePaths();
    const dataLayerViewItem = useDataLayerViewItem();
    const aValue = useSelector(selectorsCart.getAlternativeCountProduct(product.id));
    const ID_SELL_POINT = useSelector(selectorsCart.getIdSellPoint);
    const productCart = useSelector(selectorsCart.getCartDataProduct(product.id));
    const { garnish, ingredients, shortDescription, title, quantityPerPack, unit } = product;
    const index = getIndexProductOption(product, ID_SELL_POINT);
    const currentProductOption = product.productOptions.length ? product.productOptions[index] : null;
    const price = currentProductOption ? +currentProductOption?.price : 0;
    const salePrice = currentProductOption ? currentProductOption?.salePrice : null;
    const cart = useSelector(selectorsCart.getCartProducts);
    const [available, setAvailable] = useState(false);
    const isWeightUnit = product.unit && product.unit.externalId === ID_UNIT_WEIGHT;
    const isAvgWeight = !!product.avgWeight && isWeightUnit;
    const isAuth = useSelector(selectorsUser.isAuth);
    const [isFavorite, setIsFavorite] = useState(product?.isFavorite || false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<any>({});
    const unitType = unit?.type || unit?.typeDe || unit?.typeEn;
    const capacity = getCapacity(quantityPerPack, unitType);
    const formattedUnitType = getUnitType(unit?.type);
    const isMyBioShop = !isMetroShop(config.shopId);
    const isLoadingCartRequest = useSelector(selectorsCart.getIsLoading);
    const isOpenCartModal = useSelector(selectorsCart.getIsOpenCart);
    const zipCode = useSelector(selectorsDelivery.getZipCode);
    const needZipCode = zipCode === '00000';

    useEffect(() => {
      if (product) {
        setAvailable(product.productOptions[0].availableAmount !== 0);
        setIsFavorite(product.isFavorite || false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    useEffect(() => {
      if (!isOpen) {
        blockBodyScroll(false);
      }
    }, [isOpen]);

    const addToWishlist = (id: number) => {
      service
        .addToWishlist(id)
        .then(() => setIsFavorite(true))
        .catch(() => console.error('ERROR>>>>> add to wishlist'));
    };

    const removeFromWishlist = (id: number) => {
      service
        .removeFromWishlist(id)
        .then(() => {
          setIsFavorite(false);
          action && action(id);
        })
        .catch(() => console.error('ERROR>>>>>>>> remove from wishlist'));
    };

    const handleOrder = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (needZipCode) {
        dispatch(actionsOther.setIsOpenZipCode(true));
        return;
      }
      if (available) {
        dispatch(
          actionsCart.addProduct({
            comment: '',
            count: isAvgWeight ? +product.avgWeight! : 1,
            product: product,
            alternativeCount: isAvgWeight ? 1 : null,
            services: [],
          }),
        );
        DL_helper(cart, 1, 'addToCart', product);
      }
    };

    const clickDL = (e) => {
      closeCartModal();
      closeProductCardPopup();
    };

    /**
     * Handle the popup open event
     *
     * @param {Object} product
     */
    const openProductCardPopup = (product) => {
      if (!isOpen) {
        setIsOpen(true);
        setSelectedProduct(product);
        dataLayerViewItem(product);
      }
    };

    /**
     * Handle the popup close event
     */
    const closeProductCardPopup = () => {
      if (isOpen) {
        setIsOpen(false);
        setSelectedProduct({});
      }
    };

    const changeIsFavorite = (id: number) => {
      if (!isAuth) {
        dispatch(
          actionsOther.setOpenLoginData({
            isOpenLogin: true,
            redirectLink: location.pathname,
          }),
        );
        return;
      }
      isFavorite ? removeFromWishlist(id) : addToWishlist(id);
    };

    const closeCartModal = () => {
      if (isOpenCartModal) {
        dispatch(actionsCart.toggleCart(false));
      }
    };

    return (
      <div
        className={generateClassName(`item-food`, {
          'item-food_in_menu': !isShowButtonOrder,
          'item-food_no': !available,
          'item-food_buy': productCart !== undefined && productCart.count !== 0,
          'item-food_wishlist': isWishList,
          'item-weight': !!product.isWeightable && !!salePrice,
        })}
        onClick={clickDL}>
        {isOpen && (
          <ProductCard
            isOpen={isOpen}
            id={selectedProduct.id}
            onProductCardClose={closeProductCardPopup}
            isFavorite={isFavorite}
          />
        )}
        {productCart && productCart.count !== 0 && productCart.price && (
          <div className="item-food_basket">
            {buildLocale(currentTranslate, 'inYourCartText')} <span>{productCart.count}</span>
            <span>{buildLocale(currentTranslate, 'inYourCartText2')}</span>{' '}
            <span>{formatPrice(+productCart.price)}</span>
          </div>
        )}
        <div className="item-food_main">
          <figure
            className={`item-food_pic ${salePrice !== null ? 'item-sale' : ''}`}
            onClick={(e) => {
              if (!available) {
                return;
              }
              e.stopPropagation();
              closeCartModal();
              openProductCardPopup(product);
            }}>
            {salePrice !== null && <SaleDiscountWidget productOption={currentProductOption} needCountdown={false} />}
            <a href={paths.product(product.id)} onClick={(e) => e.preventDefault()}>
              <BadgesList product={product} />
              <ProductImage product={product} isDefaultImg={isDefaultImg} />
            </a>

            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!available && !isWishList) {
                  return;
                }
                closeCartModal();
                changeIsFavorite(product.id);
              }}
              className="btn btn-wish"
              aria-label="Zu den Favoriten hinzufügen">
              <img src={isFavorite ? wishlistIcon : wishlistIconNonActive} alt="" width="20" height="19" />
            </button>
            {isShowButtonOrder && available && (
              <>
                {productCart ? (
                  <CartCountItem item={productCart} />
                ) : (
                  <button onClick={handleOrder} className="btn btn-buy-sm btn-plus" disabled={isLoadingCartRequest}>
                    +
                  </button>
                )}
              </>
            )}
          </figure>
          <div className="item-food_info">
            {capacity && (
              <p className="weight-unit">
                <span>{capacity}</span>
              </p>
            )}
            <h4>
              {isShowButtonOrder ? (
                <a
                  href={paths.product(product.id)}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!available) {
                      return;
                    }
                    closeCartModal();
                    openProductCardPopup(product);
                  }}
                  className="shortDesc product-title-item">{`${title || shortDescription}`}</a>
              ) : (
                `${title || shortDescription}`
              )}
            </h4>
            {garnish && (
              <div className="item-food_add">
                <p className="caption">{buildLocale(currentTranslate, 'commonGarnish')}:</p>
                {garnish}
              </div>
            )}
            {ingredients && (
              <div className="item-food_add">
                <p className="caption">{buildLocale(currentTranslate, 'commonStorage')}</p>
                {ingredients}
              </div>
            )}
            {available ? (
              <ul className={`item-food_price${salePrice ? ' sale' : ''}`}>
                <li key={`${product.id}-price`} className="price">
                  {isMyBioShop ? (
                    <>
                      {salePrice !== null ? (
                        <div className="price-block">
                          <p className="price-sale">
                            <span className="number">
                              {aValue === null ? formatPrice(+price, false) : formatPrice(+price, false)} €
                              {product.isWeightable ? `/${formattedUnitType}` : ''}
                            </span>
                            <span className="number-sale">
                              {aValue === null ? formatPrice(+salePrice, false) : formatPrice(+salePrice, false)} €
                              {product.isWeightable ? `/${formattedUnitType}` : ''}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div className="price-block">
                          <span className="number">
                            {aValue === null ? formatPrice(+price, false) : formatPrice(+price, false)} €
                            {product.isWeightable ? `/${formattedUnitType}` : ''}
                          </span>
                        </div>
                      )}
                      {unit && (
                        <UnitPrice
                          price={price}
                          salePrice={salePrice}
                          unit={unit}
                          quantityPerPack={quantityPerPack}
                          isWeightable={product.isWeightable}
                        />
                      )}
                    </>
                  ) : (
                    <ProductPriceBlockMetro product={product} />
                  )}
                </li>
              </ul>
            ) : (
              <ul className="item-food_price">
                <li key={`${product.id}-price`} className="price">
                  <span className="info-center">{buildLocale(currentTranslate, 'runOutProduct')}</span>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default Product;
