import React, { useRef, useState } from 'react';
import ContainerModal from '../../Modals/ContainerModal';
import { useForm } from 'react-hook-form';
import validation from '../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import service from '../../../services/service';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import ItemInput from '../../Forms/Components/ItemInput';
import { actionsOther } from '../../../redux/other/otherReducer';

interface ChangePassword {
  password: string;
  confirmedPassword: string;
}

const FieldChangePassword = React.memo(() => {
  const t = useSelector(selectorsLocale.getTranslate);
  const idForm = 'id_form_change_password';
  const dispatch = useDispatch<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => {
    setIsOpen((s) => !s);
  };

  const {
    handleSubmit,
    watch,
    formState: { errors },
    register,
    reset,
  } = useForm<ChangePassword>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
  });

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = async ({ password, confirmedPassword }: ChangePassword) => {
    setIsLoading(true);

    const result = await service.changePassword(password, confirmedPassword);

    if (!result.success) {
      dispatch(
        actionsOther.toggleSystemMessage({
          isOpen: true,
          type: 'error',
          message: result.data,
          duration: 7000,
        }),
      );
    }

    if (result.success) {
      dispatch(
        actionsOther.toggleSystemMessage({
          isOpen: true,
          type: 'success',
          message: result.data || buildLocale(t, 'successPassword'),
          duration: 7000,
        }),
      );
      toggle();
      reset();
    }
    setIsLoading(false);
  };

  return (
    <>
      <button type="button" onClick={toggle} className="link-dashed">
        {buildLocale(t, 'btnChangePassword')}
      </button>
      <ContainerModal
        title={buildLocale(t, 'btnChangePassword')}
        idForm={idForm}
        isAdd={false}
        isOpen={isOpen}
        isLoading={isLoading}
        toggle={toggle}>
        <form id={idForm} onSubmit={handleSubmit(onSubmit)} className="modal-form-profile modal-form-profile-pass">
          <div className="pass-row">
            <ItemInput
              // defaultValue=""
              errors={errors}
              label={buildLocale(t, 'tIYourPassword')}
              labelClassName="required"
              type={showPassword ? 'text' : 'password'}
              {...register('password', validation.pass)}
            />
            <div
              className={showPassword ? 'btn-hide-password' : 'btn-show-password'}
              onClick={() => setShowPassword((state) => !state)}></div>
          </div>
          <div className="pass-row">
            <ItemInput
              // defaultValue=""
              errors={errors}
              label={buildLocale(t, 'tIRepeatYourPassword')}
              labelClassName="required"
              type={showConfirmedPassword ? 'text' : 'password'}
              {...register('confirmedPassword', {
                validate: (value) => value === password.current || buildLocale(t, 'tIPasswordsDoNoMatch'),
              })}
            />
            <div
              className={showConfirmedPassword ? 'btn-hide-password' : 'btn-show-password'}
              onClick={() => setShowConfirmedPassword((state) => !state)}></div>
          </div>
        </form>
      </ContainerModal>
    </>
  );
});

export default FieldChangePassword;
