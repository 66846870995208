import { AxiosRequestConfig } from 'axios';
import { IGetProducts } from '../typings/CustomTypes/ServiceTypes';
import { getSortFilter } from '../typings/CustomTypes/TypeSortProduct';
import buildQuery from '../utils/buildQuery';
import { IFiltersDTO } from '../typings/IFilters';
import configs from '../config';
import { filterTitle, setAttributeRequest } from '../utils/setAttributeRequest';

const addFilterByBrands = (filter: any, brands: number[] | null | undefined) => {
  if (!brands) {
    return;
  }
  filter.and.push({
    or: brands.map((brand) => {
      return {
        'brand/id': { eq: brand },
      };
    }),
  });
};

const addFilterByBadges = (filter: any, badges?: number[] | null) => {
  if (!badges) {
    return;
  }
  filter.and.push({
    or: badges.map((badge) => {
      return {
        'badges/id': { eq: badge },
      };
    }),
  });
};

const addFilterByHasPriceRanges = (filter: any, hasPriceRanges?: boolean) => {
  if (!hasPriceRanges) {
    return;
  }
  filter.and.push({
    'productOptions/hasPriceRanges': { eq: hasPriceRanges },
  });
};

const addFilterBySalePriceIsNotNull = (filter: any, hasSalePrice?: boolean) => {
  if (!hasSalePrice) {
    return;
  }
  filter.and.push({
    'productOptions/salePrice': { ne: null },
  });
};

const addFilterBySalePriceGreaterThanZero = (filter: any, hasSalePrice?: boolean) => {
  if (!hasSalePrice) {
    return;
  }
  filter.and.push({
    salePrice: { gt: 0 },
  });
};

const addFilterByHasBadges = (filter: any, hasBadges?: boolean) => {
  if (!hasBadges) {
    return;
  }
  filter.and.push({
    'badges/id': { ne: null },
  });
};

const addFilterByIsBoxDeliveryAllowed = (filter: any, isInCourierArea: boolean | null) => {
  if (isInCourierArea) {
    return;
  }
  filter.and.push({
    isBoxDeliveryAllowed: { eq: true },
  });
};

const addFilterByProductsIsBoxDeliveryAllowed = (filter: any, isInCourierArea: boolean | null) => {
  if (isInCourierArea) {
    return;
  }
  if (isInCourierArea === false) {
    filter.and.push({
      'products/isBoxDeliveryAllowed': { eq: true },
    });
  }
};

const generateUrlFromParams = (
  filter: any,
  urlMainPart: string,
  customCategoryFilter: string | null | undefined,
  urlFilterPart: string,
  selectedAllergich: any,
  selectedNutrion: any,
) => {
  let urlParts = [] as string[];
  if (urlFilterPart?.length) {
    urlParts.push(urlFilterPart);
  }

  const attrs = setAttributeRequest(selectedAllergich, selectedNutrion);
  if (attrs?.length) {
    urlParts.push(`${filterTitle}${encodeURIComponent(attrs)}`);
  }
  if (customCategoryFilter) {
    urlParts.push(`${urlParts?.length ? '&' : ''}$customCategoryFilter=${customCategoryFilter}`);
  }
  const mainUrl = `${urlMainPart}${urlParts?.length && !urlFilterPart?.length ? '?' : ''}`;

  return `${mainUrl}${urlParts.join('').replace(/^&{1}/, '')}`;
};

const queries = {
  getRestaurants: () => {
    const filter = {};
    const query = buildQuery({ filter, orderBy: 'id' });

    const config: AxiosRequestConfig = {
      url: 'sell_points' + query,
      method: 'get',
    };

    return config;
  },
  getPictures: () => {
    const config: AxiosRequestConfig = {
      url: '/pictures',
      method: 'get',
    };

    return config;
  },
  getBrands: (search: any, top: number = 20, skip: number = 0) => {
    const filter = {};
    if (search) {
      filter['tolower(nameDe)'] = { contains: encodeURIComponent(search.toLowerCase()) };
    }

    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}clients/brands` + buildQuery({ filter, count: true }),
      method: 'get',
      params: {
        $top: top,
        $skip: skip,
      },
    };

    return config;
  },
  getBrandsByNames: (names: string[]) => {
    const filter = {
      or: [] as { [key: string]: any },
    };
    for (const name of names) {
      filter.or.push({
        'tolower(nameDe)': { eq: encodeURIComponent(name) },
      });
    }

    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}clients/brands` + buildQuery({ filter, count: false }),
      method: 'get',
    };

    return config;
  },
  getBrand: (id: number) => {
    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}clients/brands/${id}`,
      method: 'get',
    };
    return config;
  },
  getBioBadges: (search: any, top: number = 20, skip: number = 0) => {
    const filter = {};
    if (search) {
      filter['tolower(nameDe)'] = { contains: encodeURIComponent(search.toLowerCase()) };
    }

    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}clients/organic_badges` + buildQuery({ filter, count: true }),
      method: 'get',
      params: {
        $top: top,
        $skip: skip,
      },
    };

    return config;
  },
  getBioBadgesByNames: (names: string[]) => {
    const filter = {
      or: [] as { [key: string]: any },
    };
    for (const name of names) {
      filter.or.push({
        'tolower(nameDe)': { eq: encodeURIComponent(name) },
      });
    }

    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}clients/organic_badges` + buildQuery({ filter, count: true }),
      method: 'get',
    };

    return config;
  },
  getProducts: ({ top, skip, title = '', idCategories, idTag, sort, idSellPoint, idCity }: IGetProducts) => {
    const categoryFilter = {};
    if (!idCategories) {
      categoryFilter['custom_categories/id'] = {
        ne: null,
      };
    } else if (idCategories) {
      if (Array.isArray(idCategories)) {
        categoryFilter['custom_categories/id'] = { in: idCategories };
      } else {
        categoryFilter['custom_categories/id'] = idCategories;
      }
    }
    if (idTag) {
      categoryFilter['groups/id'] = idTag;
      categoryFilter['custom_categories/id'] = {
        ne: null,
      };
    }
    const orderBy = ['productOptions/available desc'];
    const sortStr = getSortFilter(sort);
    if (sortStr) {
      orderBy.push(sortStr);
    }

    const filter = {
      and: [categoryFilter],
      isActive: true,
    };

    if (title) {
      filter.and.unshift({
        'tolower(title)': { contains: encodeURIComponent(title.toLowerCase()) },
      });
    }

    let url = 'products';
    if (idCity) {
      url = `products/city/${idCity}`;
    } else if (idSellPoint) {
      url = `products/sell_point/${idSellPoint}`;
    }
    const config: AxiosRequestConfig = {
      url: url + buildQuery({ filter, count: true, top, skip, orderBy }),
      withCredentials: true,
      method: 'get',
    };

    return config;
  },
  getProductById: (id: number, isInCourierArea: boolean | null) => {
    const filter: any = {
      isActive: true,
      and: [],
    };
    addFilterByIsBoxDeliveryAllowed(filter, isInCourierArea);
    const config: AxiosRequestConfig = {
      url: `/products/${id}/city/1${buildQuery({ filter })}`,
      method: 'get',
      withCredentials: true,
    };
    return config;
  },
  getDeliveryTypes: () => {
    const config: AxiosRequestConfig = {
      url: '/delivery_types',
      method: 'get',
    };
    return config;
  },
  getSeoTextByMain: () => {
    const config: AxiosRequestConfig = {
      url: '/seo_templates/main',
      method: 'get',
    };
    return config;
  },
  getSeoTextByRoot: () => {
    const config: AxiosRequestConfig = {
      url: '/seo_templates/root_shop',
      method: 'get',
    };
    return config;
  },
  getCategories: () => {
    const config: AxiosRequestConfig = {
      url: '/categories?$filter=isActive&$orderby=ord asc',
      method: 'get',
    };
    return config;
  },
  getCategory: (id: number) => {
    const config: AxiosRequestConfig = {
      url: `/categories/${id}`,
      method: 'get',
    };
    return config;
  },
  getCustomNoParentsCategory: (id: number, isInCourierArea: boolean | null, locale?: any, isParent?: boolean) => {
    const filter: any = isParent ? { parent: { eq: null }, isActive: true, and: [] } : { and: [] };
    if (isInCourierArea === true) {
      filter.and.push({
        hasActiveProducts: { eq: true },
      });
    } else {
      filter.and.push({
        hasActiveProductsWithBoxDeliveryAllowed: { eq: true },
      });
    }

    const config: AxiosRequestConfig = {
      url: `${configs.baseURLV2}custom_categories/own_categories_page/${id}${
        isParent && buildQuery({ filter })
      }&$orderby=ord`,
      method: 'get',
      headers: {
        'Accept-Language': locale,
      },
    };
    return config;
  },
  getChildrenCategories: (id: number, isInCourierArea: boolean | null) => {
    let path = configs.baseURLV2;

    if (isInCourierArea === true) {
      path += 'custom_categories/children/active_products/';
    } else {
      path += 'custom_categories/children/box_delivery_allowed/';
    }

    const config: AxiosRequestConfig = {
      url: `${path}${id}?$orderby=ord`,
      method: 'get',
    };
    return config;
  },
  getCustomCategoryProductsVisible: (
    id: number,
    visibility: boolean,
    order: string,
    filters: IFiltersDTO,
    isInCourierArea: boolean | null,
    tid?: string,
    top?: number,
    random?: boolean,
  ) => {
    const {
      brands,
      badges,
      selectedAllergich,
      selectedNutrion,
      hasPriceRanges,
      hasSalePrice,
      hasBadges,
      searchFilter,
    } = filters;
    const filter: any = { and: [] };
    addFilterByBrands(filter, brands);
    addFilterByBadges(filter, badges);
    addFilterByHasPriceRanges(filter, hasPriceRanges);
    addFilterByHasBadges(filter, hasBadges);
    addFilterBySalePriceIsNotNull(filter, hasSalePrice);
    addFilterByIsBoxDeliveryAllowed(filter, isInCourierArea);
    const urlMainPart = `${configs.baseURLV2}products/custom-category/${id}/city/1`;
    const urlFilterPart = buildQuery({
      filter,
      top,
      orderBy: order,
      search: encodeURIComponent(searchFilter?.toLowerCase() || ''),
    });
    let urlParts = [urlMainPart + urlFilterPart];

    if (random && !searchFilter) {
      urlParts.push('&$getRandom=true');
    }
    const attrs = setAttributeRequest(selectedAllergich, selectedNutrion);
    if (attrs?.length) {
      urlParts.push(`${filterTitle}${encodeURIComponent(attrs)}`);
    }
    if (tid?.length) {
      urlParts.push(`&tid=${tid}`);
    }

    const config: AxiosRequestConfig = {
      url: urlParts.join(''),
      method: 'get',
      withCredentials: true,
    };
    return config;
  },
  getCategoryBrands: (
    signal: AbortSignal,
    isInCourierArea: boolean | null,
    filters?: IFiltersDTO,
    categoryId?: number,
  ) => {
    const filter: any = { 'products/isActive': true, 'products/visibility': true, and: [] };
    let queryParams = buildQuery({ filter });
    const urlTailParts: string[] = [];
    if (filters) {
      const { badges, hasPriceRanges, hasBadges, hasSalePrice, searchFilter, customCategoryFilter } = filters;
      addFilterByBadges(filter, badges);
      addFilterByHasPriceRanges(filter, hasPriceRanges);
      addFilterByHasBadges(filter, hasBadges);
      addFilterBySalePriceIsNotNull(filter, hasSalePrice);
      addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

      queryParams = buildQuery({ filter });

      if (queryParams?.length) {
        urlTailParts.push(queryParams);
      }

      if (customCategoryFilter) {
        urlTailParts.push(`$customCategoryFilter=${customCategoryFilter}`);
      }
      if (searchFilter) {
        urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
      }
    }

    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;

    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}products/category/${categoryId}/city/1/brands${urlTail}`,
      method: 'get',
      signal,
    };
    return config;
  },
  getOwnCategoryBrands: (
    signal: AbortSignal,
    isInCourierArea: boolean | null,
    filters?: IFiltersDTO,
    categoryId?: number,
  ) => {
    const filter: any = { 'products/isActive': true, 'products/visibility': true, and: [] };
    let queryParams = buildQuery({ filter });
    const urlTailParts: string[] = [];
    if (filters) {
      const { badges, hasPriceRanges, hasBadges, hasSalePrice, searchFilter } = filters;
      addFilterByBadges(filter, badges);
      addFilterByHasPriceRanges(filter, hasPriceRanges);
      addFilterByHasBadges(filter, hasBadges);
      addFilterBySalePriceIsNotNull(filter, hasSalePrice);
      addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

      queryParams = buildQuery({ filter });

      if (queryParams?.length) {
        urlTailParts.push(queryParams);
      }
      if (searchFilter) {
        urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
      }
    }

    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;

    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}products/custom-category/${categoryId}/city/1/brands${urlTail}`,
      method: 'get',
      signal,
    };
    return config;
  },
  getSearchBrands: (isInCourierArea: boolean | null, filters?: IFiltersDTO) => {
    const filter: any = { 'products/isActive': true, 'products/visibility': true, and: [] };
    let queryParams = buildQuery({ filter });
    const urlTailParts: string[] = [];
    let searchText = 'empty';
    if (filters) {
      const { badges, hasPriceRanges, hasBadges, hasSalePrice, searchFilter, customCategoryFilter, searchTopText } =
        filters;
      addFilterByBadges(filter, badges);
      addFilterByHasPriceRanges(filter, hasPriceRanges);
      addFilterByHasBadges(filter, hasBadges);
      addFilterBySalePriceIsNotNull(filter, hasSalePrice);
      addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

      queryParams = buildQuery({ filter });

      if (queryParams?.length) {
        urlTailParts.push(queryParams);
      }

      if (customCategoryFilter) {
        urlTailParts.push(`$customCategoryFilter=${customCategoryFilter}`);
      }
      if (searchFilter) {
        urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
      }
      if (searchTopText?.length) {
        searchText = searchTopText.toLowerCase();
      }
    }

    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;

    const config: AxiosRequestConfig = {
      url: configs.baseURL + `products/city/1/search/${encodeURIComponent(searchText)}/brands${urlTail}`,
      method: 'get',
    };
    return config;
  },
  getWishlistBrands: (signal: AbortSignal, isInCourierArea: boolean | null, filters?: IFiltersDTO) => {
    const filter: any = { 'products/isActive': true, and: [] };
    let queryParams = buildQuery({ filter });
    const urlTailParts: string[] = [];
    if (filters) {
      const { badges, hasPriceRanges, hasBadges, hasSalePrice, searchFilter, customCategoryFilter } = filters;
      addFilterByBadges(filter, badges);
      addFilterByHasPriceRanges(filter, hasPriceRanges);
      addFilterByHasBadges(filter, hasBadges);
      addFilterBySalePriceIsNotNull(filter, hasSalePrice);
      addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

      queryParams = buildQuery({ filter });

      if (queryParams?.length) {
        urlTailParts.push(queryParams);
      }

      if (customCategoryFilter) {
        urlTailParts.push(`$customCategoryFilter=${customCategoryFilter}`);
      }
      if (searchFilter) {
        urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
      }
    }

    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;

    const config: AxiosRequestConfig = {
      url: `${configs.baseURLV2}favorites/brands${urlTail}`,
      method: 'get',
      signal,
      withCredentials: true,
    };
    return config;
  },
  getWishlistBadges: (signal: AbortSignal, isInCourierArea: boolean | null, filters?: IFiltersDTO) => {
    const filter: any = { 'products/isActive': true, 'products/visibility': true, and: [] };
    let queryParams = buildQuery({ filter });
    const urlTailParts: string[] = [];
    if (filters) {
      const { brands, hasPriceRanges, hasBadges, hasSalePrice, searchFilter, customCategoryFilter } = filters;
      addFilterByBrands(filter, brands);
      addFilterByHasPriceRanges(filter, hasPriceRanges);
      addFilterByHasBadges(filter, hasBadges);
      addFilterBySalePriceIsNotNull(filter, hasSalePrice);
      addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

      queryParams = buildQuery({ filter });

      if (queryParams?.length) {
        urlTailParts.push(queryParams);
      }

      if (customCategoryFilter) {
        urlTailParts.push(`$customCategoryFilter=${customCategoryFilter}`);
      }
      if (searchFilter) {
        urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
      }
    }

    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;

    const config: AxiosRequestConfig = {
      url: `${configs.baseURLV2}favorites/organic_badges${urlTail}`,
      method: 'get',
      signal,
      withCredentials: true,
    };
    return config;
  },
  getCategoryBadges: (
    signal: AbortSignal,
    isInCourierArea: boolean | null,
    filters?: IFiltersDTO,
    categoryId?: number,
  ) => {
    const filter: any = { 'products/isActive': true, 'products/visibility': true, and: [] };
    let queryParams = buildQuery({ filter });
    const urlTailParts: string[] = [];
    if (filters) {
      const { brands, hasPriceRanges, hasBadges, hasSalePrice, searchFilter, customCategoryFilter } = filters;

      addFilterByBrands(filter, brands);
      addFilterByHasPriceRanges(filter, hasPriceRanges);
      addFilterByHasBadges(filter, hasBadges);
      addFilterBySalePriceIsNotNull(filter, hasSalePrice);
      addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

      queryParams = buildQuery({ filter });

      if (queryParams?.length) {
        urlTailParts.push(queryParams);
      }
      if (customCategoryFilter) {
        urlTailParts.push(`$customCategoryFilter=${customCategoryFilter}`);
      }
      if (searchFilter) {
        urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
      }
    }

    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;
    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}products/category/${categoryId}/city/1/organic_badges${urlTail}`,
      method: 'get',
      signal,
    };
    return config;
  },
  getOwnCategoryBadges: (
    signal: AbortSignal,
    isInCourierArea: boolean | null,
    filters?: IFiltersDTO,
    categoryId?: number,
  ) => {
    const filter: any = { 'products/isActive': true, 'products/visibility': true, and: [] };
    let queryParams = buildQuery({ filter });
    const urlTailParts: string[] = [];
    if (filters) {
      const { brands, hasPriceRanges, hasBadges, hasSalePrice, searchFilter } = filters;

      addFilterByBrands(filter, brands);
      addFilterByHasPriceRanges(filter, hasPriceRanges);
      addFilterByHasBadges(filter, hasBadges);
      addFilterBySalePriceIsNotNull(filter, hasSalePrice);
      addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

      queryParams = buildQuery({ filter });

      if (queryParams?.length) {
        urlTailParts.push(queryParams);
      }
      if (searchFilter) {
        urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
      }
    }

    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;

    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}products/custom-category/${categoryId}/city/1/organic_badges${urlTail}`,
      method: 'get',
      signal,
    };
    return config;
  },
  getSearchBadges: (isInCourierArea: boolean | null, filters?: IFiltersDTO) => {
    const filter: any = { 'products/isActive': true, 'products/visibility': true, and: [] };
    let queryParams = buildQuery({ filter });
    const urlTailParts: string[] = [];
    let searchText = 'empty';
    if (filters) {
      const { brands, hasPriceRanges, hasBadges, hasSalePrice, searchFilter, customCategoryFilter, searchTopText } =
        filters;

      addFilterByBrands(filter, brands);
      addFilterByHasPriceRanges(filter, hasPriceRanges);
      addFilterByHasBadges(filter, hasBadges);
      addFilterBySalePriceIsNotNull(filter, hasSalePrice);
      addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

      queryParams = buildQuery({ filter });
      if (queryParams?.length) {
        urlTailParts.push(queryParams);
      }
      if (customCategoryFilter) {
        urlTailParts.push(`$customCategoryFilter=${customCategoryFilter}`);
      }
      if (searchFilter) {
        urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
      }
      if (searchTopText?.length) {
        searchText = searchTopText.toLowerCase();
      }
    }

    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;

    const config: AxiosRequestConfig = {
      url: configs.baseURL + `products/city/1/search/${encodeURIComponent(searchText)}/organic_badges${urlTail}`,
      method: 'get',
    };
    return config;
  },
  getBrandBadges: (signal: AbortSignal, isInCourierArea: boolean | null, filters?: IFiltersDTO, brandId?: number) => {
    const filter: any = { 'products/isActive': true, 'products/visibility': true, and: [] };

    let queryParams = buildQuery({ filter });
    const urlTailParts: string[] = [];

    if (filters) {
      const { hasPriceRanges, hasBadges, hasSalePrice, searchFilter } = filters;
      addFilterByHasPriceRanges(filter, hasPriceRanges);
      addFilterByHasBadges(filter, hasBadges);
      addFilterBySalePriceIsNotNull(filter, hasSalePrice);
      addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

      queryParams = buildQuery({ filter });

      if (queryParams?.length) {
        urlTailParts.push(queryParams);
      }
      if (searchFilter) {
        urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
      }
    }

    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;

    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}products/brand/${brandId}/city/1/organic_badges${urlTail}`,
      method: 'get',
      signal,
    };
    return config;
  },
  getCategoryAttributes: (signal: AbortSignal, isInCourierArea: boolean | null) => {
    const filter: any = { and: [] };

    addFilterByProductsIsBoxDeliveryAllowed(filter, isInCourierArea);

    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}products/attributes` + buildQuery({ filter }),
      method: 'get',
      signal,
    };
    return config;
  },
  getChildrenCategoriesByVisibilityOption: (
    id: number,
    isInCourierArea: boolean | null,
    visibility: boolean = true,
  ) => {
    const filter: any = { isActive: true, and: [] };
    let path = configs.baseURLV2;

    filter.and.push({
      visibility: { eq: visibility },
    });

    if (isInCourierArea === true) {
      path += 'custom_categories/children/active_products/';
    } else {
      path += 'custom_categories/children/box_delivery_allowed/';
    }
    const config: AxiosRequestConfig = {
      url: `${path}${id}${buildQuery({ filter })}&$orderby=ord`,
      method: 'get',
    };
    return config;
  },
  getAllProducts: (
    filters: IFiltersDTO,
    isInCourierArea: boolean | null,
    top: number = 20,
    skip: number = 0,
    tid?: string,
  ) => {
    const {
      brands,
      badges,
      selectedAllergich,
      selectedNutrion,
      hasPriceRanges,
      hasBadges,
      hasSalePrice,
      searchFilter,
      customCategoryFilter,
      searchTopText,
    } = filters;
    //TODO: make 1 endpoint
    const filter: any = {
      and: [],
    };
    addFilterByBrands(filter, brands);
    addFilterByBadges(filter, badges);
    addFilterByHasPriceRanges(filter, hasPriceRanges);
    addFilterByHasBadges(filter, hasBadges);
    addFilterBySalePriceGreaterThanZero(filter, hasSalePrice);
    addFilterByIsBoxDeliveryAllowed(filter, isInCourierArea);

    const urlTailParts: string[] = [];
    const queryParams = buildQuery({ filter });
    if (queryParams?.length) {
      urlTailParts.push(queryParams);
    }

    const attrs = setAttributeRequest(selectedAllergich, selectedNutrion);
    if (attrs?.length) {
      urlTailParts.push(`$attributeFilter=${encodeURIComponent(attrs)}`);
    }
    if (customCategoryFilter) {
      urlTailParts.push(`${urlTailParts?.length ? '&' : ''}$customCategoryFilter=${customCategoryFilter}`);
    }
    if (searchFilter) {
      urlTailParts.push(`$search=${encodeURIComponent(searchFilter.toLowerCase())}`);
    }

    if (tid?.length) {
      urlTailParts.push(`tid=${tid}`);
    }

    const searchText = searchTopText ? searchTopText.toLowerCase() : 'empty';
    const urlTail = queryParams?.length ? urlTailParts.join('&') : `?${urlTailParts.join('&')}`;

    const config: AxiosRequestConfig = {
      url: `${configs.baseURLV2}products/city/1/search/${encodeURIComponent(searchText)}${urlTail}`,
      method: 'get',
      params: {
        $top: top,
        $skip: skip,
      },
      withCredentials: true,
    };
    return config;
  },
  getFilterProducts: (
    {
      title = '',
      idCategories,
      idTag,
      sort,
      idSellPoint,
      idCity,
      orderProducts,
      brands,
      badges,
      selectedAllergich,
      selectedNutrion,
      hasPriceRanges,
      hasBadges,
      hasSalePrice,
      searchFilter,
      isInCourierArea,
    }: IGetProducts,
    top: number = 12,
    skip: number = 0,
    tid?: string,
  ) => {
    const categoryFilter = {
      //'productOptions/available': true,
    };
    const filter: any = {
      and: [categoryFilter],
    };
    if (!idCategories) {
      filter.and.push({
        'custom_categories/id': { ne: null },
      });
    } else if (idCategories) {
      const numIdCategories = Array.isArray(idCategories) ? idCategories.map((item) => +item) : [idCategories];

      const customCategoriesFilter = {
        or: numIdCategories.map((category) => ({
          'custom_categories/id': { eq: category },
        })),
      };

      filter.and.push(customCategoriesFilter);
    }
    if (idTag) {
      categoryFilter['groups/id'] = idTag;
      categoryFilter['custom_categories/id'] = {
        ne: null,
      };
    }
    let orderBy = ['productOptions/available desc'];

    const sortStr = getSortFilter(sort);
    if (sortStr) {
      orderBy.push(sortStr);
    }
    if (orderProducts) {
      orderBy = [orderProducts];
    }

    addFilterByBrands(filter, brands);
    addFilterByBadges(filter, badges);
    addFilterByHasBadges(filter, hasBadges);
    addFilterByHasPriceRanges(filter, hasPriceRanges);
    addFilterBySalePriceIsNotNull(filter, hasSalePrice);

    if (title) {
      filter.and.unshift({
        'tolower(title)': { contains: encodeURIComponent(title.toLowerCase()) },
      });
    }
    addFilterByIsBoxDeliveryAllowed(filter, isInCourierArea);

    let url = 'products';
    if (idCity) {
      url = `products/city/${idCity}`;
    } else if (idSellPoint) {
      url = `products/sell_point/${idSellPoint}`;
    }

    const urlMainPart = `${configs.baseURLV2}${url}`;
    const urlFilterPart = buildQuery({
      filter,
      orderBy,
      search: encodeURIComponent(searchFilter?.toLowerCase() || ''),
    });
    const urlParts: string[] = [urlMainPart, urlFilterPart];
    const attrs = setAttributeRequest(selectedAllergich, selectedNutrion);

    if (attrs?.length) {
      urlParts.push(`${filterTitle}${encodeURIComponent(attrs)}`);
    }
    if (tid?.length) {
      urlParts.push(`&tid=${tid}`);
    }

    const config: AxiosRequestConfig = {
      url: urlParts.join(''),
      method: 'get',
      params: {
        $top: top,
        $skip: skip,
      },
      withCredentials: true,
    };
    return config;
  },
  getProductsByCategory: (
    id: number,
    order: string | undefined,
    filters: IFiltersDTO,
    isInCourierArea: boolean | null,
    top: number = 20,
    skip: number = 0,
    tid?: string,
  ) => {
    const {
      brands,
      badges,
      selectedAllergich,
      selectedNutrion,
      hasPriceRanges,
      hasBadges,
      hasSalePrice,
      searchFilter,
      customCategoryFilter,
    } = filters;
    const filter: any = { and: [] };

    addFilterByBrands(filter, brands);
    addFilterByBadges(filter, badges);
    addFilterByHasPriceRanges(filter, hasPriceRanges);
    addFilterBySalePriceIsNotNull(filter, hasSalePrice);
    addFilterByHasBadges(filter, hasBadges);
    addFilterByIsBoxDeliveryAllowed(filter, isInCourierArea);

    const urlMainPart = `${configs.baseURLV2}products/category/${id}/city/1`;
    const urlFilterPart = buildQuery({
      filter,
      orderBy: order,
      search: encodeURIComponent(searchFilter?.toLowerCase() || ''),
    });

    let urlParts = [] as string[];
    if (urlFilterPart?.length) {
      urlParts.push(urlFilterPart);
    }
    const attrs = setAttributeRequest(selectedAllergich, selectedNutrion);
    if (attrs?.length) {
      urlParts.push(`${filterTitle}${encodeURIComponent(attrs)}`);
    }
    if (customCategoryFilter) {
      urlParts.push(`&$customCategoryFilter=${customCategoryFilter}`);
    }
    if (tid?.length) {
      urlParts.push(`&tid=${tid}`);
    }
    urlParts.unshift(`${urlMainPart}${urlParts?.length && !urlFilterPart?.length ? '?' : ''}`);

    const config: AxiosRequestConfig = {
      url: urlParts.join(''),
      method: 'get',
      params: {
        $top: top,
        $skip: skip,
      },
      withCredentials: true,
    };
    return config;
  },
  getProductsByBrand: (
    id: number,
    order: string | undefined,
    filters: IFiltersDTO,
    isInCourierArea: boolean | null,
    top: number = 20,
    skip: number = 0,
  ) => {
    const { badges, selectedAllergich, selectedNutrion, hasPriceRanges, hasBadges, hasSalePrice, searchFilter } =
      filters;
    const filter: any = { and: [] };
    addFilterByBadges(filter, badges);
    addFilterByHasPriceRanges(filter, hasPriceRanges);
    addFilterBySalePriceIsNotNull(filter, hasSalePrice);
    addFilterByHasBadges(filter, hasBadges);
    addFilterByIsBoxDeliveryAllowed(filter, isInCourierArea);

    const config: AxiosRequestConfig = {
      url:
        configs.baseURLV2 +
        `products/brand/${id}/city/1` +
        buildQuery({
          filter,
          orderBy: order,
          search: encodeURIComponent(searchFilter?.toLowerCase() || ''),
        }) +
        `${
          setAttributeRequest(selectedAllergich, selectedNutrion)
            ? filterTitle + encodeURIComponent(setAttributeRequest(selectedAllergich, selectedNutrion))
            : ''
        }`,
      method: 'get',
      params: {
        $top: top,
        $skip: skip,
      },
      withCredentials: true,
    };
    return config;
  },
  getProductRecommendations: (id: number, isInCourierArea: boolean | null) => {
    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}products/${id}/recommendations?isInCourierArea=${isInCourierArea}`,
      method: 'get',
      withCredentials: true,
    };
    return config;
  },
  getProductVariants: (id: number, isInCourierArea: boolean | null) => {
    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}products/${id}/variants?isInCourierArea=${isInCourierArea}`,
      method: 'get',
      withCredentials: true,
    };
    return config;
  },
  getReviews: (id: number) => {
    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}products/${id}/reviews`,
      method: 'get',
      withCredentials: true,
    };
    return config;
  },
  getMessages: () => {
    const config: AxiosRequestConfig = {
      url: `${configs.baseURL}public_messages?$filter=isActive`,
      method: 'get',
      withCredentials: true,
    };
    return config;
  },
  getCustomCategoriesForCategory: (id: number, filters: IFiltersDTO, isInCourierArea: boolean | null) => {
    const {
      brands,
      badges,
      selectedAllergich,
      selectedNutrion,
      hasPriceRanges,
      hasBadges,
      hasSalePrice,
      searchFilter,
      customCategoryFilter,
    } = filters;
    const filter: any = { and: [] };

    addFilterByBrands(filter, brands);
    addFilterByBadges(filter, badges);
    addFilterByHasPriceRanges(filter, hasPriceRanges);
    addFilterBySalePriceIsNotNull(filter, hasSalePrice);
    addFilterByHasBadges(filter, hasBadges);

    const urlFilterPart = buildQuery({
      filter,
      search: encodeURIComponent(searchFilter?.toLowerCase() || ''),
    });

    let urlMainPart = `${configs.baseURLV2}custom_categories/`;
    urlMainPart += isInCourierArea === true ? 'active_products/' : 'box_delivery_allowed/';
    urlMainPart += `category/${id}`;

    const url = generateUrlFromParams(
      filter,
      urlMainPart,
      customCategoryFilter,
      urlFilterPart,
      selectedAllergich,
      selectedNutrion,
    );

    const config: AxiosRequestConfig = {
      url: url,
      method: 'get',
    };
    return config;
  },
  getWishlist: (filters: IFiltersDTO, isInCourierArea: boolean | null, top?: number, skip?: number, order?: string) => {
    const {
      brands,
      badges,
      selectedAllergich,
      selectedNutrion,
      hasPriceRanges,
      hasBadges,
      hasSalePrice,
      searchFilter,
      customCategoryFilter,
    } = filters;
    const filter: any = { and: [] };

    if (!searchFilter?.length) {
      filter.and.push({
        'products/isActive': { eq: true },
      });
    }
    addFilterByBrands(filter, brands);
    addFilterByBadges(filter, badges);
    addFilterByHasPriceRanges(filter, hasPriceRanges);
    addFilterBySalePriceIsNotNull(filter, hasSalePrice);
    addFilterByHasBadges(filter, hasBadges);
    addFilterByIsBoxDeliveryAllowed(filter, isInCourierArea);

    let urlMainPart = `${configs.baseURLV2}favorites/city/1`;
    const urlFilterPart = buildQuery({
      filter,
      top,
      skip,
      search: encodeURIComponent(searchFilter?.toLowerCase() || ''),
      orderBy: order,
    });

    const url = generateUrlFromParams(
      filter,
      urlMainPart,
      customCategoryFilter,
      urlFilterPart,
      selectedAllergich,
      selectedNutrion,
    );

    const config: AxiosRequestConfig = {
      url: url,
      method: 'get',
      withCredentials: true,
    };

    return config;
  },
  getCustomCategoriesForFavorites: (filters: IFiltersDTO, isInCourierArea: boolean | null, clientId: number | null) => {
    const {
      brands,
      badges,
      selectedAllergich,
      selectedNutrion,
      hasPriceRanges,
      hasBadges,
      hasSalePrice,
      searchFilter,
      customCategoryFilter,
    } = filters;

    let urlMainPart = `${configs.baseURLV2}custom_categories/favorites`;

    if (!clientId) {
      return {
        url: urlMainPart,
        method: 'get',
      };
    }

    const filter: any = { and: [] };

    addFilterByBrands(filter, brands);
    addFilterByBadges(filter, badges);
    addFilterByHasPriceRanges(filter, hasPriceRanges);
    addFilterBySalePriceIsNotNull(filter, hasSalePrice);
    addFilterByHasBadges(filter, hasBadges);
    addFilterByIsBoxDeliveryAllowed(filter, isInCourierArea);

    const urlFilterPart = buildQuery({
      filter,
      search: encodeURIComponent(searchFilter?.toLowerCase() || ''),
    });

    const url = generateUrlFromParams(
      filter,
      urlMainPart,
      customCategoryFilter,
      urlFilterPart,
      selectedAllergich,
      selectedNutrion,
    );
    const config: AxiosRequestConfig = {
      url: url,
      method: 'get',
      withCredentials: true,
    };

    return config;
  },
  getCustomCategoriesForSearchPage: (filters: IFiltersDTO, isInCourierArea: boolean | null) => {
    const {
      brands,
      badges,
      selectedAllergich,
      selectedNutrion,
      hasPriceRanges,
      hasBadges,
      hasSalePrice,
      searchFilter,
      customCategoryFilter,
      searchTopText,
    } = filters;

    const filter: any = { and: [] };

    addFilterByBrands(filter, brands);
    addFilterByBadges(filter, badges);
    addFilterByHasPriceRanges(filter, hasPriceRanges);
    addFilterBySalePriceIsNotNull(filter, hasSalePrice);
    addFilterByHasBadges(filter, hasBadges);
    addFilterByIsBoxDeliveryAllowed(filter, isInCourierArea);

    const searchText = searchTopText ? searchTopText.toLowerCase() : 'empty';
    let urlMainPart = `${configs.baseURLV2}custom_categories/all_products/search/${encodeURIComponent(searchText)}`;

    const urlFilterPart = buildQuery({
      filter,
      search: encodeURIComponent(searchFilter?.toLowerCase() || ''),
    });

    const url = generateUrlFromParams(
      filter,
      urlMainPart,
      customCategoryFilter,
      urlFilterPart,
      selectedAllergich,
      selectedNutrion,
    );
    const config: AxiosRequestConfig = {
      url: url,
      method: 'get',
      withCredentials: true,
    };

    return config;
  },
};

export default queries;
