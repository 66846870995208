import React from 'react';
import { Link } from 'react-router-dom';
import { usePaths } from '../Routes/RouterList';
import { useSelector } from 'react-redux';
import useProfileOptions from '../../useHooks/useProfileOptions';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import FormSettings from '../Forms/FormSettings';

const SettingsOption = React.memo(() => {
  const ProfileOptions = useProfileOptions();
  const paths = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  return (
    <div className="tab-pane settings">
      <Link to={paths.profile()} className="back-link">
        {buildLocale(currentTranslate, 'headerProfile')}
      </Link>
      <p className="profile-block_title">{ProfileOptions['settings'].name}</p>
      <div className="profile-block">
        <FormSettings />
      </div>
    </div>
  );
});

export default SettingsOption;
