import React from 'react';
import ItemInput from './Components/ItemInput';
import validation from '../../utils/validation';
import { IContact } from '../../typings/IContact';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import ShowError from './Components/ShowError';
import { isMetroShop } from '../../constants/shops';
import config from '../../config';

interface IFormContactProps {
  idForm: string;
  defaultContact?: Omit<IContact, 'id'>;
  onSubmit: (d: IContact) => any;
}

const FormContact = ({ defaultContact, idForm, onSubmit }: IFormContactProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isMetro = isMetroShop(config.shopId);
  const {
    handleSubmit,
    formState: { errors },
    register,
    trigger,
  } = useForm<IContact>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: defaultContact,
  });
  const phoneRegister = register('phone', validation.phone);
  const fullNameRegister = register('fullName', validation.required);
  const emailRegister = register('email', validation.email);

  return (
    <form id={idForm} onSubmit={handleSubmit(onSubmit)} className="modal-form-profile">
      <ItemInput
        errors={errors}
        label={buildLocale(currentTranslate, 'tINameLabel')}
        labelClassName="required"
        name={fullNameRegister.name}
        ref={fullNameRegister.ref}
        onChange={(e) => {
          trigger('fullName');
          fullNameRegister.onChange(e);
        }}
        onBlur={(e) => {
          trigger('fullName');
          fullNameRegister.onBlur(e);
        }}
      />
      <ItemInput
        errors={errors}
        label={buildLocale(currentTranslate, 'tIEmailLabel')}
        labelClassName="required"
        name={emailRegister.name}
        ref={emailRegister.ref}
        onChange={(e) => {
          trigger('email');
          emailRegister.onChange(e);
        }}
        onBlur={(e) => {
          trigger('email');
          emailRegister.onBlur(e);
        }}
      />
      {isMetro && (
        <ItemInput errors={errors} label={buildLocale(currentTranslate, 'tICompanyLabel')} {...register('company')} />
      )}
      <div className="form-item profile-info_row">
        <label htmlFor="phone" className="profile-info_label required">
          {buildLocale(currentTranslate, 'tIPhoneLabel')}
        </label>
        <PhoneInput
          inputProps={{
            errors,
            name: phoneRegister.name,
            ref: phoneRegister.ref,
          }}
          value={defaultContact ? defaultContact.phone : ''}
          enableSearch
          country="de"
          onChange={(value, _, e) => {
            phoneRegister.onChange(e);
            trigger('phone');
          }}
          onBlur={(e) => {
            phoneRegister.onBlur(e);
            trigger('phone');
          }}
          placeholder="+49"
        />
        {errors && <ShowError errors={errors} name={'phone'} />}
      </div>
    </form>
  );
};

export default FormContact;
