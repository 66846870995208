import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectorsCart } from '../../redux/cart/cartReducer';
import { selectorsCategory } from '../../redux/category/categoryReducer';
import { SelectorCity } from '../../redux/city/cityReducer';
import { selectorsOrder } from '../../redux/order/orderReducer';
import ProductLoader from '../Loaders/ProductLoader';
import ProductCard from '../Modals/ProductCard';
import Product from './Product';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLessThen991 } from '../../utils/mediaQuery';
import GoBackBtn from '../CategoriesMobile/GoBackBtn';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import FilterComponent from '../common/Filters/FilterComponent';
import { FILTER_TYPE } from '../../typings/IFilters';
import { selectorsFilters } from '../../redux/filters/filtersReducer';
import { scrollThreshold } from '../../constants/scrollThreshold';
import useApi from 'react-use-api';
import queries from '../../services/queries';
import { handleData } from '../../utils/handleData';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';
import useFilterDataFromSearchParams from '../../useHooks/useFilterDataFromSearchParams';
import useFilterQueryParams from '../../useHooks/useFilterQueryParams';
import getSortDataForFilter from '../../utils/getSortDataForFilter';
import { useNavigate, useNavigationType } from 'react-router';
import { v5 as uuidv5 } from 'uuid';
import { useLocation } from 'react-router-dom';
import isLocationForFirstLoadedPage from '../../utils/isLocationForFirstLoadedPage';
import { selectorsOther } from '../../redux/other/otherReducer';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { FilterSearchingParams } from '../../redux/filters/filtersTypes';

interface IProductsProps {
  query: string;
  className?: string;
  seoText?: string;
  id?: number | null;
  hideBackBtn?: boolean;
}

const Products = ({ query, seoText, id, hideBackBtn }: IProductsProps) => {
  const params: any = useParams();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const navigationType = useNavigationType();
  const categoryId = Number(id || params['category']);
  const perPage = 12;
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const idSelectedCity = useSelector(SelectorCity.getSelectedCityId)!;
  const cartSellPoint = useSelector(selectorsCart.getIdSellPoint)!;
  const isDeliverySelf = useSelector(selectorsOrder.isDeliverySelf);
  const idCategories = useSelector(selectorsCategory.getSelfIdOrChildrenIds(categoryId));

  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const selectedSort = useSelector(selectorsFilters.getSortDirection);

  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [productsSkip, setProductsSkip] = useState(0);

  const getActualFilters = () => {
    return {
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : [],
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : [],
      selectedAllergich: selectedAllergich,
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
    };
  };

  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({
    type: FILTER_TYPE.OWNCATEGORY,
    id: categoryId,
    filters: getActualFilters(),
  });
  const { brands, badges, allergich, nutrion, searchQueryData } = useFilterDataFromSearchParams(
    brandsQuery,
    badgesQuery,
    attributesQuery,
  );

  const location = useLocation();
  const navigate = useNavigate();
  const translate = useSelector(selectorsLocale.getTranslate);

  const backBtn = useMemo(() => {
    if (hideBackBtn) {
      return '';
    }
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return '';
    }
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={translate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const tidNameSpace = uuidv5(location.pathname, uuidv5.URL);
  const actualSearchData = searchFilter || '';
  const tid = actualSearchData?.length ? uuidv5(actualSearchData, tidNameSpace) : '';

  const getProducts = (offset?: number, limit?: number) => {
    const filters = {
      title: query,
      idCategories,
      idSellPoint: isDeliverySelf ? cartSellPoint : undefined,
      idCity: isDeliverySelf ? undefined : idSelectedCity,
      orderProducts: getSortDataForFilter({ isEmpty: true } as FilterSearchingParams, selectedSort),
      isInCourierArea,
    };

    return queries.getFilterProducts({ ...filters, ...getActualFilters() }, limit, offset, tid);
  };

  const applyFilters = () => {
    setProductsSkip(0);
  };

  useEffect(() => {
    if (!isOpen) {
      blockBodyScroll(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isLessThen991) {
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, totalCount]);

  const options = useMemo(
    () => ({
      handleData,
      dependencies: {
        perPage,
      },
    }),
    [perPage],
  );
  const [products, { loading, hasMore = true }, request] = useApi(getProducts(0, perPage), options);
  const loadMore = useCallback(() => {
    const nextOffset = productsSkip + perPage;
    request(getProducts(nextOffset, perPage), true).then(() => setProductsSkip(nextOffset));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productsSkip,
    selectedBrands,
    selectedBadges,
    selectedAllergich,
    selectedNutrion,
    hasPriceRanges,
    hasBadges,
    hasSalePrice,
    searchFilter,
    searchQueryData,
  ]);

  /**
   * Handle the popup open event
   *
   * @param {Object} product
   */
  const openProductCardPopup = (product) => {
    if (!isOpen) {
      setIsOpen(true);
      setSelectedProduct(product);
    }
  };

  /**
   * Handle the popup close event
   */
  const closeProductCardPopup = () => {
    if (isOpen) {
      setIsOpen(false);
      setSelectedProduct({});
    }
  };

  return (
    <>
      <div
        className="category-page"
        onClick={() => {
          // close modal when outside of modal is clicked
          closeProductCardPopup();
        }}>
        {backBtn}
        <FilterComponent
          id={categoryId}
          type={FILTER_TYPE.OWNCATEGORY}
          applyFilters={applyFilters}
          isLoading={loading}
          filters={getActualFilters()}
          brands={brands}
          badges={badges}
          allergich={allergich}
          nutrion={nutrion}
          searchQueryData={searchQueryData}
        />
        <section className="search-result">
          <div className="container">
            {!!products?.length && (
              <InfiniteScroll
                dataLength={products.length}
                style={{ overflow: isLessThen991 ? 'visible visible' : 'hidden auto' }}
                next={loadMore}
                scrollThreshold={scrollThreshold}
                hasMore={hasMore}
                loader={<ProductLoader />}>
                <>
                  {products.map((product: any) => {
                    return (
                      <div key={product.id} className="col-6 col-md-4 col-lg-3 col-xl-2 catalog-item">
                        <Product product={product} onProductImageClick={openProductCardPopup} />
                      </div>
                    );
                  })}
                </>
              </InfiniteScroll>
            )}
          </div>
        </section>
        {!!seoText && (
          <article
            className="section-seo"
            style={{ backgroundColor: 'white', paddingTop: !!products?.length ? '6.5rem' : 0 }}>
            <div className="container">
              <div className="row">
                <div
                  className="col-12"
                  dangerouslySetInnerHTML={{
                    __html: seoText || '',
                  }}></div>
              </div>
            </div>
          </article>
        )}
        {isOpen && <ProductCard isOpen={isOpen} id={selectedProduct.id} onProductCardClose={closeProductCardPopup} />}
      </div>
    </>
  );
};

export default Products;
