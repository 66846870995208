import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { blockBodyScroll } from '../utils/blockBodyScroll';
import { selectorsFilters } from '../redux/filters/filtersReducer';
import Product from '../components/Products/Product';
import ProductCard from '../components/Modals/ProductCard';
import ProductLoader from '../components/Loaders/ProductLoader';
import NoSearchResult from '../components/common/NoSearchResult';
import FilterComponent from '../components/common/Filters/FilterComponent';
import { FILTER_TYPE } from '../typings/IFilters';
import { useLessThen991 } from '../utils/mediaQuery';
import { scrollThreshold } from '../constants/scrollThreshold';
import queries from '../services/queries';
import useApi from 'react-use-api';
import { handleData } from '../utils/handleData';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import useFilterQueryParams from '../useHooks/useFilterQueryParams';
import useFilterDataFromSearchParams from '../useHooks/useFilterDataFromSearchParams';
import { selectorsOther } from '../redux/other/otherReducer';
import { v5 as uuidv5 } from 'uuid';
import FilterSearchPageCustomCategories from '../components/common/Filters/FilterSearchPageCustomCategories';

const AllProductsPage = () => {
  const limit = 20;
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const location = useLocation();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const [searchParams] = useSearchParams();
  const persistedSearchField = useSelector(selectorsOther.getProductsSearchQuery);
  const searchField = searchParams.get('search');
  const searchData = searchField || persistedSearchField || '';

  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const totalFiltersCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const selectedCustomCategory = useSelector(selectorsFilters.getCustomCategory);
  const [skip, setSkip] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const abortController = new AbortController();

  const getActualFilters = () => {
    return {
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
      customCategoryFilter: selectedCustomCategory || '',
      searchTopText: searchData ? searchData : 'empty',
    };
  };

  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({
    type: FILTER_TYPE.SEARCH,
    filters: getActualFilters(),
  });
  const { brands, badges, allergich, nutrion, searchQueryData } = useFilterDataFromSearchParams(
    brandsQuery,
    badgesQuery,
    attributesQuery,
  );

  const liveSearchData = searchFilter || '';
  const tidNameSpace = uuidv5(pathname, uuidv5.URL);
  const tid = liveSearchData?.length ? uuidv5(liveSearchData, tidNameSpace) : '';

  const getAllProducts = (limit?: number, offset?: number) => {
    const filters = getActualFilters();
    return queries.getAllProducts(filters, isInCourierArea, limit, offset, tid);
  };

  const options = useMemo(
    () => ({
      handleData,
      dependencies: {
        perPage: limit,
      },
    }),
    [limit],
  );
  const [products, { loading, hasMore = true }, requestGetProducts] = useApi(getAllProducts(), options);
  const [selectedProduct, setSelectedProduct] = useState<any>({});

  useEffect(() => {
    if (!isOpen) {
      blockBodyScroll(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isLessThen991) {
      applyFilters();
    }
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFiltersCount, location.search]);

  const applyFilters = () => {
    setSkip(0);
  };
  /**
   * Handle the popup open event
   *
   * @param {Object} product
   */
  const openProductCardPopup = (product) => {
    if (!isOpen) {
      setIsOpen(true);
      setSelectedProduct(product);
    }
  };

  /**
   * Handle the popup close event
   */
  const closeProductCardPopup = () => {
    if (isOpen) {
      setIsOpen(false);
      setSelectedProduct({});
    }
  };

  const loadMore = useCallback(() => {
    const newOffset = skip + limit;
    requestGetProducts(getAllProducts(limit, newOffset), true).then(() => setSkip(newOffset));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    skip,
    selectedBrands,
    selectedBadges,
    selectedAllergich,
    selectedNutrion,
    hasPriceRanges,
    hasBadges,
    hasSalePrice,
    searchFilter,
  ]);

  return (
    <section
      className="section-static section-results"
      onClick={() => {
        // close modal when outside of modal is clicked
        closeProductCardPopup();
      }}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Suchergebnisse</h1>
          </div>
          <div style={{ width: '100%' }}>
            <FilterSearchPageCustomCategories filters={getActualFilters()} />
            <FilterComponent
              type={FILTER_TYPE.SEARCH}
              applyFilters={applyFilters}
              isLoading={loading}
              hideSort={true}
              filters={getActualFilters()}
              brands={brands}
              badges={badges}
              allergich={allergich}
              nutrion={nutrion}
              searchQueryData={searchQueryData}
            />
            <br />
          </div>
          <section className="category-page">
            <div className="search-result">
              <div className="container">
                <InfiniteScroll
                  next={loadMore}
                  hasMore={hasMore}
                  loader={<ProductLoader />}
                  dataLength={products ? products.length : 0}
                  scrollThreshold={scrollThreshold}
                  style={{ overflowX: 'hidden' }}>
                  <>
                    {products?.map((product: any) => (
                      <div key={product.id} className="col-6 col-md-4 col-lg-3 col-xl-2 catalog-item">
                        <Product product={product} onProductImageClick={openProductCardPopup} />
                      </div>
                    ))}
                  </>
                </InfiniteScroll>
                {!loading && products?.length === 0 && (
                  <NoSearchResult className="no-search-result" searchQuery={searchData} />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>

      {isOpen && <ProductCard isOpen={isOpen} id={selectedProduct.id} onProductCardClose={closeProductCardPopup} />}
    </section>
  );
};

export default AllProductsPage;
