import React from 'react';
import { useSelector } from 'react-redux';
import useApi from 'react-use-api';
import queries from '../../../services/queries';
import { selectorsDelivery } from '../../../redux/delivery/deliveryReducer';
import { selectorsOther } from '../../../redux/other/otherReducer';
import FilterCustomCategoriesDesktop from './components/desktop/FilterCustomCategoriesDesktop';
import { IFiltersDTO } from '../../../typings/IFilters';
import { IFilterCustomCategories } from '../../../typings/IFilterCustomCategories';
import FilterCustomCategoriesMobile from './components/mobile/FilterCustomCategoriesMobile';
import { buildLocale } from '../../../utils/buildLocale';
import { IFetchCategory } from '../../../typings/ICategory';
import { selectorsLocale } from '../../../redux/locale/localeReducer';

interface ICustomCategoryProps {
  filters: IFiltersDTO;
}

const FilterSearchPageCustomCategories = (props: ICustomCategoryProps) => {
  const { filters } = props;
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const handleData = (data: IFilterCustomCategories, state: any) => {
    const { error } = state;
    if (error) {
      return [];
    }
    const { tree } = data;
    data.tree = tree.sort((a, b) => a.ord - b.ord);
    if (data.breadCrumbs?.length) {
      data.breadCrumbs.unshift({ id: 0, name: `${buildLocale(currentTranslate, 'commonSearch')}` } as IFetchCategory);
    }
    return { ...data };
  };

  const loadCustomCategoriesForSearchPage = (isInCourierArea: boolean | null) => {
    return queries.getCustomCategoriesForSearchPage(filters, isInCourierArea);
  };

  const [customCategoriesDataForSearchPage, { loading }] = useApi(loadCustomCategoriesForSearchPage(isInCourierArea), {
    handleData,
  });

  return (
    <>
      {isDesktop && !loading && (
        <div className="container">
          {!!customCategoriesDataForSearchPage?.tree?.length && (
            <FilterCustomCategoriesDesktop data={customCategoriesDataForSearchPage} />
          )}
        </div>
      )}
      {!isDesktop && !loading && (
        <div>
          {!!customCategoriesDataForSearchPage?.tree?.length && (
            <FilterCustomCategoriesMobile data={customCategoriesDataForSearchPage} isSearchPage={true} />
          )}
        </div>
      )}
    </>
  );
};

export default FilterSearchPageCustomCategories;
