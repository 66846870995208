import { FILTER_TYPE, IFiltersDTO } from '../typings/IFilters';
import { IUseApiQuery } from '../typings/IUseApiQuery';
import { useSelector } from 'react-redux';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import queries from '../services/queries';
import { useLocation } from 'react-router-dom';
import { useNavigationType } from 'react-router';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';

interface FilterQueryParamsProps {
  type: string;
  id?: number;
  skip?: boolean;
  filters?: IFiltersDTO;
}

const useFilterQueryParams = (props: FilterQueryParamsProps) => {
  const { type, id, filters, skip } = props;
  const abortController = new AbortController();
  const signal = abortController.signal;
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const location = useLocation();
  const navigationType = useNavigationType();

  const sortItemsByName = (data: { id: number; nameDe: string }[], state: any) => {
    const { error } = state;
    if (error) {
      return [];
    }
    return data.sort((a, b) => a.nameDe.localeCompare(b.nameDe));
  };

  const isFirstLoading = isLocationForFirstLoadedPage(location.key, navigationType);

  let brandsQuery: IUseApiQuery = id
    ? {
        query: queries.getOwnCategoryBrands(signal, isInCourierArea, !isFirstLoading ? filters : undefined, id),
        options: { skip: skip || false, handleData: sortItemsByName },
      }
    : ({ options: { skip: skip || false } } as IUseApiQuery);
  let badgesQuery: IUseApiQuery = id
    ? {
        query: queries.getOwnCategoryBadges(signal, isInCourierArea, !isFirstLoading ? filters : undefined, id),
        options: { skip: skip || false, handleData: sortItemsByName },
      }
    : ({ options: { skip: skip || false } } as IUseApiQuery);
  let attributesQuery = {
    query: queries.getCategoryAttributes(signal, isInCourierArea),
    options: { skip: skip || false },
  };

  if (type === FILTER_TYPE.SEARCH) {
    brandsQuery.query = queries.getSearchBrands(isInCourierArea, !isFirstLoading ? filters : undefined);
    brandsQuery.options!.handleData = sortItemsByName;
  } else if (type === FILTER_TYPE.CATEGORY) {
    brandsQuery.query = queries.getCategoryBrands(signal, isInCourierArea, !isFirstLoading ? filters : undefined, id);
    brandsQuery.options!.handleData = sortItemsByName;
  } else if (type === FILTER_TYPE.WISHLIST) {
    brandsQuery.query = queries.getWishlistBrands(signal, isInCourierArea, !isFirstLoading ? filters : undefined);
    brandsQuery.options!.handleData = sortItemsByName;
  } else if (type === FILTER_TYPE.BRAND) {
    brandsQuery.options!.skip = true;
  }

  if (type === FILTER_TYPE.SEARCH) {
    badgesQuery.query = queries.getSearchBadges(isInCourierArea, !isFirstLoading ? filters : undefined);
    brandsQuery.options!.handleData = sortItemsByName;
  } else if (type === FILTER_TYPE.CATEGORY) {
    badgesQuery.query = queries.getCategoryBadges(signal, isInCourierArea, !isFirstLoading ? filters : undefined, id);
    badgesQuery.options!.handleData = sortItemsByName;
  } else if (type === FILTER_TYPE.WISHLIST) {
    badgesQuery.query = queries.getWishlistBadges(signal, isInCourierArea, !isFirstLoading ? filters : undefined);
    badgesQuery.options!.handleData = sortItemsByName;
  } else if (type === FILTER_TYPE.BRAND) {
    badgesQuery.query = queries.getBrandBadges(signal, isInCourierArea, !isFirstLoading ? filters : undefined, id);
    badgesQuery.options!.handleData = sortItemsByName;
  }

  return { brandsQuery, badgesQuery, attributesQuery, abortController };
};

export default useFilterQueryParams;
