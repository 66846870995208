import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { actionsOther } from '../../../redux/other/otherReducer';
import { usePaths } from '../../Routes/RouterList';
import { buildLocale } from '../../../utils/buildLocale';
import { getLocaleStorageItem, setLocaleStorageItem } from '../../../utils/localStorageHelper';
import { buildNumber, city, company, fullName, street } from '../../../constants/payData';
import AddressesDropdown from './AddressesDropdown';
import ItemInput, { MiniItemInput } from '../../Forms/Components/ItemInput';
import { IRegister } from '../../../typings/IRegister';
import { actionsDelivery, selectorsDelivery } from '../../../redux/delivery/deliveryReducer';
import { useNavigate } from 'react-router';
import validation from '../../../utils/validation';
import { selectorsUser } from '../../../redux/user/userReducer';
import { FieldValue, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

interface IProps {
  errors: any;
  register: (name: string, validation: any) => IRegister;
  debCreateDraft: () => void;
  isSelectAddress: boolean;
  setIsSelectAddress: (isSelect: boolean) => void;
  setValue: UseFormSetValue<FieldValue<any>>;
  trigger: UseFormTrigger<any>;
}

const AddressDataComponent: React.FC<IProps> = ({
  errors,
  register,
  debCreateDraft,
  setIsSelectAddress,
  isSelectAddress,
  setValue,
  trigger,
}) => {
  const paths = usePaths();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectorsUser.getDataUser);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const zipCode = useSelector(selectorsDelivery.getZipCode);
  // const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const [addressData, setAddressData] = useState<any>(null);
  const cityRegister = register('city', validation.required);
  const streetRegister = register('street', validation.required);
  const buildNumberRegister = register('buildNumber', validation.required);
  const companyRegister = register('company', {});
  const fullNameRegister = register('fullName', validation.required);

  useEffect(() => {
    setInitialAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialAddress = () => {
    setAddressData((prevData: any) => ({ ...prevData, zipCode }));
    setInitialHelper(fullName);
    setInitialHelper(company);
    setInitialHelper(city);
    setInitialHelper(street);
    setInitialHelper(buildNumber);
    // setInitialHelper(flatNumber);
    // setInitialHelper(entrance);
    // setInitialHelper(floor);
  };

  const setInitialHelper = (key: string) => {
    const keyValue = getLocaleStorageItem(key);
    if (keyValue) {
      setAddressData((prevData: any) => ({ ...prevData, [key]: keyValue }));
      setValue(key, keyValue);
    } else if ([fullName, company].includes(key) && currentUser) {
      const currentUserValue = currentUser[key];
      setAddressData((prevData: any) => ({ ...prevData, [key]: currentUserValue }));
      setValue(key, currentUserValue);
      setLocaleStorageItem(key, currentUserValue || '');
    }
  };

  const changeData = (key: string, value: string) => {
    setLocaleStorageItem(key, value);
    setAddressData((prevData: any) => ({ ...prevData, [key]: value }));
  };

  const updateStorageAddress = (address: any) => {
    setLocaleStorageItem(city, address?.city || '');
    dispatch(actionsDelivery.setZipCode(address?.zipCode || ''));
    setLocaleStorageItem(street, address?.street || '');
    setLocaleStorageItem(buildNumber, address?.buildNumber || '');
    setInitialHelper(fullName);
    setInitialHelper(company);
    // setLocaleStorageItem(flatNumber, address?.flatNumber || '');
    // setLocaleStorageItem(entrance, address?.entrance || '');
    // setLocaleStorageItem(floor, address?.floor || '');
    setIsSelectAddress(!isSelectAddress);
  };

  const changeZipCode = () => {
    navigate(paths.home);
    dispatch(actionsOther.setIsOpenZipCode(true));
  };

  const updateAddress = (newAddress: any) => {
    setAddressData(newAddress);
    if (newAddress?.street) {
      setValue(street, newAddress.street);
      trigger(street);
    }
    if (newAddress?.city) {
      setValue(city, newAddress.city);
      trigger(city);
    }
    if (newAddress?.buildNumber) {
      setValue(buildNumber, newAddress.buildNumber);
      trigger(buildNumber);
    }
    setInitialHelper(fullName);
    setInitialHelper(company);
  };

  return (
    <div>
      <div className="pay-block_title">
        <div className="title">
          <span className="number">2</span> {buildLocale(currentTranslate, 'payPageAddress')}
        </div>
        <AddressesDropdown setAddressData={updateAddress} updateStorageAddress={updateStorageAddress} />
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-item">
            <label htmlFor="fullName" className="required">
              {buildLocale(currentTranslate, 'fullName')}
            </label>
            <ItemInput
              className="form-control"
              errors={errors}
              defaultValue={addressData?.fullName || ''}
              name={fullNameRegister.name}
              ref={fullNameRegister.ref}
              onChange={(e) => {
                changeData(fullName, e.target.value);
                fullNameRegister.onChange(e);
              }}
              onBlur={(e) => {
                debCreateDraft();
                fullNameRegister.onBlur(e);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <label htmlFor="company">{buildLocale(currentTranslate, 'tICompanyLabel')}</label>
            <ItemInput
              errors={errors}
              className="form-control"
              defaultValue={addressData?.company || ''}
              name={companyRegister.name}
              ref={companyRegister.ref}
              onChange={(e) => {
                changeData(company, e.target.value);
                companyRegister.onChange(e);
              }}
              onBlur={(e) => {
                debCreateDraft();
                companyRegister.onBlur(e);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <MiniItemInput
              errors={errors}
              label={buildLocale(currentTranslate, 'addressStreet')}
              labelClassName="required"
              value={addressData?.street || ''}
              defaultValue={addressData?.street || ''}
              name={'street'}
              ref={streetRegister.ref}
              onChange={(e) => {
                changeData(street, e.target.value);
                streetRegister.onChange(e);
              }}
              onBlur={(e) => {
                debCreateDraft();
                streetRegister.onBlur(e);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <MiniItemInput
              errors={errors}
              label={buildLocale(currentTranslate, 'addressBuild')}
              labelClassName="required"
              value={addressData?.buildNumber || ''}
              defaultValue={addressData?.buildNumber || ''}
              name={'buildNumber'}
              ref={buildNumberRegister.ref}
              onChange={(e) => {
                changeData(buildNumber, e.target.value);
                buildNumberRegister.onChange(e);
              }}
              onBlur={(e) => {
                debCreateDraft();
                buildNumberRegister.onBlur(e);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <MiniItemInput
              errors={errors}
              label={buildLocale(currentTranslate, 'addressCity')}
              labelClassName="required"
              value={addressData?.city || ''}
              defaultValue={addressData?.city || ''}
              name={'city'}
              ref={cityRegister.ref}
              onChange={(e) => {
                changeData(city, e.target.value);
                cityRegister.onChange(e);
              }}
              onBlur={(e) => {
                debCreateDraft();
                cityRegister.onBlur(e);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <MiniItemInput
              errors={[]}
              label={buildLocale(currentTranslate, 'zipPlaceholder')}
              labelClassName="required"
              value={addressData?.zipCode || ''}
              defaultValue={addressData?.zipCode || ''}
              name="zipCode"
              disabled
            />
            <span className="change-zip-link" onClick={changeZipCode}>
              {buildLocale(currentTranslate, 'payPageChangeZipCode')}
            </span>
          </div>
        </div>
        {/*{isInCourierArea && (*/}
        {/*  <>*/}
        {/*    <div className="col-12 col-md-4">*/}
        {/*      <div className="form-item">*/}
        {/*        <MiniItemInput*/}
        {/*          errors={[]}*/}
        {/*          label={buildLocale(currentTranslate, 'tiFlatPlaceholder')}*/}
        {/*          name="flatNumber"*/}
        {/*          value={addressData?.flatNumber || ''}*/}
        {/*          defaultValue={addressData?.flatNumber || ''}*/}
        {/*          placeholder={buildLocale(currentTranslate, 'tiFlatPlaceholder')}*/}
        {/*          onChange={(e) => changeData(flatNumber, e.target.value)}*/}
        {/*          onBlur={debCreateDraft}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="col-12 col-md-4">*/}
        {/*      <div className="form-item">*/}
        {/*        <MiniItemInput*/}
        {/*          errors={[]}*/}
        {/*          label={buildLocale(currentTranslate, 'tiEntrancePlaceholder')}*/}
        {/*          name="entrance"*/}
        {/*          defaultValue={addressData?.entrance || ''}*/}
        {/*          value={addressData?.entrance || ''}*/}
        {/*          placeholder={buildLocale(currentTranslate, 'tiEntrancePlaceholder')}*/}
        {/*          onChange={(e) => changeData(entrance, e.target.value)}*/}
        {/*          onBlur={debCreateDraft}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="col-12 col-md-4">*/}
        {/*      <div className="form-item">*/}
        {/*        <MiniItemInput*/}
        {/*          errors={[]}*/}
        {/*          label={buildLocale(currentTranslate, 'tiFloorPlaceholder')}*/}
        {/*          name="floor"*/}
        {/*          value={addressData?.floor || ''}*/}
        {/*          defaultValue={addressData?.floor || ''}*/}
        {/*          placeholder={buildLocale(currentTranslate, 'tiFloorPlaceholder')}*/}
        {/*          onChange={(e) => changeData(floor, e.target.value)}*/}
        {/*          onBlur={debCreateDraft}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default AddressDataComponent;
