import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { usePaths } from '../components/Routes/RouterList';
import { generateClassName } from '../utils/generateClassName';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { actionsCart, selectorsCart } from '../redux/cart/cartReducer';
import { buildLocale } from '../utils/buildLocale';
import wishlistIcon from '../assets/images/ic-heart_o.svg';
import wishlistIconNonActive from '../assets/images/ic-heart.svg';
import service from '../services/service';
import { selectorsUser } from '../redux/user/userReducer';
import ProductImage from '../components/ImagesComponent/ProductImage';
import { useLessThen991 } from '../utils/mediaQuery';
import ProductImagesSlider from '../components/Products/ProductImagesSlider';
import ProductMagnifyImage from '../components/common/ProductMagnifyImage';
import ClipLoader from 'react-spinners/ClipLoader';
import { DL_helper } from '../utils/DataLayer/DL_helper';
import DLViewProductDetails from '../utils/DataLayer/DLViewProductDetails';
import Breadcrumbs, { ParentType } from '../components/common/Breadcrumbs';
import BadgesList from '../components/common/BadgesList';
import PriceBlock from '../components/common/PriceBlock';
import { setLoaderColor } from '../utils/setLoaderColor';
import { getOwnCategory } from '../utils/getOwnCategory';
import useApi from 'react-use-api';
import queries from '../services/queries';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import CommonHelmet from '../components/common/CommonHelmet';
import config from '../config';
import { SeoTexts } from '../constants/seoTexts';
import { isContentNotEmpty } from '../utils/isContentNotEmpty';
import ProductJsonLd from '../components/JsonLd/ProductJsonLd';
import { ProductKeysTab } from '../components/Products/Enum/ProductKeysTab';
import { IProduct } from '../typings/IProduct';
import RelatedProductsWidget from '../components/Products/RelatedProductsWidget';
import { carouselCategories } from '../constants/configCarousel';
import ViewProductReviewWidget from '../components/Products/ProductReviews/ViewProductReviewWidget';
import ReviewStars from '../components/Products/ProductReviews/ReviewStars';
import { useRouterStaticContext } from '../context/RouterStaticContext';
import NotFoundComponent from './common/NotFoundPage';
import { IBreadcrumb } from '../typings/IBreadcrumb';
import { encodeUrlTailParamsForFilters } from '../utils/convertUrlTailParamsForFilters';
import { actionsOther, selectorsOther } from '../redux/other/otherReducer';
import { useNavigate } from 'react-router';
import SaleDiscountWidget from '../components/Products/SaleDiscountWidget';

const ProductPage = () => {
  const paths = usePaths();
  const location = useLocation();
  const navigate = useNavigate();
  const params: any = useParams();
  const productId = Number(params.product);
  const isProductIdNaN = isNaN(productId);
  const dispatch = useDispatch<any>();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const [tab, setTab] = useState(ProductKeysTab.fullDescription);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const zipCode = useSelector(selectorsDelivery.getZipCode);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const needZipCode = zipCode === '00000';
  const [product, { loading, error }] = useApi(queries.getProductById(productId, isInCourierArea), {
    skip: isProductIdNaN,
  });
  const staticContext = useRouterStaticContext();
  const [isFavorite, setIsFavorite] = useState(product?.isFavorite);
  const isProductInCart = useSelector(selectorsCart.checkProductInCart(productId));
  const isAuth = useSelector(selectorsUser.isAuth);
  const cart = useSelector(selectorsCart.getCartProducts);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isBoxDeliveryAllowed = !product?.isBoxDeliveryAllowed && isInCourierArea === false;
  const reviewContainerRef = useRef<HTMLDivElement | null>(null);
  const [openReviewManagingForm, setOpenReviewManagingForm] = useState(false);
  const REVIEW_URL_HASH = '#reviews';

  if (staticContext) {
    if (!staticContext.headers) {
      staticContext.headers = { link: [] };
    }
    staticContext.headers.link.push({
      rel: 'preload',
      href: product?.cdnImages?.length && product.cdnImages[0].variants.public,
      as: 'image',
    });

    if (error?.status === 404 || isProductIdNaN) {
      staticContext.statusCode = 404;
    }
  }

  const handleData = (data: any, state: any) => {
    const { error } = state;
    if (error) {
      return [];
    }
    const { items } = data;
    return [
      ...items.map((item: IProduct) => {
        item.title = item.titleDe;
        return item;
      }),
    ];
  };

  const [productRecommendations] = useApi(queries.getProductRecommendations(productId, isInCourierArea), {
    handleData,
    skip: isProductIdNaN,
  });
  const [productVariants] = useApi(queries.getProductVariants(productId, isInCourierArea), {
    handleData,
    skip: isProductIdNaN,
  });
  const [reviews, { isReviewsLoading }] = useApi(queries.getReviews(productId), { skip: isProductIdNaN });

  const metaData = useMemo(() => {
    const title = product?.seoTexts?.title || SeoTexts.title;
    const description = product?.seoTexts?.description || SeoTexts.description;

    return {
      title,
      description,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const getBreadcrumbLink = (product) => {
    const searchFilter = `${ParentType.brands}=${encodeUrlTailParamsForFilters(product.brand.nameDe)}`;
    if (product.customCategories.at(0)?.parent === null) {
      return `${paths.own_categories(product.customCategories.at(0).id)}?${searchFilter}`;
    }
    return `${paths.products(product.customCategories.at(0).id)}?${searchFilter}`;
  };

  const additionalBreadcrumbs: IBreadcrumb[] =
    product?.brand?.id && product?.customCategories?.length && !isBoxDeliveryAllowed
      ? [
          {
            name: `${product.customCategories.at(0).name} ${product.brand.nameDe}`,
            link: getBreadcrumbLink(product),
            filtersData: [
              {
                filterName: ParentType.brands,
                filterItems: [product.brand],
              },
            ],
          },
        ]
      : [];

  const setActiveTab = () => {
    const keyTabs = Object.values(ProductKeysTab);
    const mobileKeysTabs = isLess991
      ? keyTabs.filter((key: string) => key !== ProductKeysTab.fullDescription)
      : keyTabs;
    for (const tabName of mobileKeysTabs) {
      if (isContentNotEmpty(product[tabName])) {
        setTab(tabName);

        return;
      }
    }
  };

  useEffect(() => {
    if (product?.id) {
      setActiveTab();
      setIsFavorite(product.isFavorite);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, isLess991]);

  const addToWishlist = (id: number) => {
    service
      .addToWishlist(id)
      .then(() => {
        setIsFavorite(true);
      })
      .catch((e) => console.log('ERROR>>>>> add to wishlist'));
  };

  const removeFromWishlist = (id: number) => {
    service
      .removeFromWishlist(id)
      .then(() => {
        setIsFavorite(false);
      })
      .catch((e) => console.log('ERROR>>>>>>>> remove from wishlist'));
  };

  const changeWishlist = () => {
    if (!isAuth) {
      dispatch(
        actionsOther.setOpenLoginData({
          isOpenLogin: true,
          redirectLink: location.pathname,
        }),
      );
      return;
    }
    handleWishlistChanges();
  };

  const changeWishlistForMobile = () => {
    if (!isAuth) {
      navigate(paths.login, { state: { from: location.pathname } });
      return;
    }
    handleWishlistChanges();
  };

  const handleWishlistChanges = () => {
    if (!product) {
      return;
    }
    isFavorite ? removeFromWishlist(product.id) : addToWishlist(product.id);
  };

  // NOTE: Product options are an array of values. There is no description of what price value should be taken,
  // so it is taken from the first element
  const currentProductOption = product?.productOptions?.length ? product.productOptions.at(0) : null;
  const salePrice = currentProductOption ? currentProductOption.salePrice : null;

  const addProductToCart = (count: number, alternativeCount: number | null = null) => {
    if (needZipCode) {
      dispatch(actionsOther.setIsOpenZipCode(true));
      return;
    }
    if (!isProductInCart) {
      dispatch(
        actionsCart.addProduct({
          count: count,
          comment: '',
          product: product!,
          alternativeCount,
          services: [],
        }),
      );
      DL_helper(cart, 1, 'addToCart', product);
    }
  };

  const handleStarsClick = () => {
    if (reviewContainerRef?.current) {
      reviewContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    setCurrentImageIndex(0);
    if (!location.hash || location.hash !== REVIEW_URL_HASH) {
      return;
    }
    const timeout = setTimeout(() => {
      handleStarsClick();
      setOpenReviewManagingForm(true);
    }, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const orderBtnTitle = isProductInCart
    ? `${buildLocale(currentTranslate, 'allreaduInBasket')}`
    : isBoxDeliveryAllowed
      ? `${buildLocale(currentTranslate, 'wantToOrderDisableForBox')}`
      : `${buildLocale(currentTranslate, 'wantToOrder')}`;

  const customCategory = product ? getOwnCategory(product?.customCategories) : null;

  if (error?.status === 404 || isProductIdNaN) {
    return <NotFoundComponent />;
  }

  const makeTabContent = (content: any, tabTitle: string | null) => {
    const contentValue =
      tabTitle && !isLess991
        ? `<h2 class="tab-content-title">${tabTitle} <span>${product.title}</span></h2>${content}`
        : content;

    return <div dangerouslySetInnerHTML={{ __html: contentValue }}></div>;
  };

  return (
    <>
      {loading && (
        <div className="loading-cart">
          <ClipLoader size={50} color={setLoaderColor()} loading={loading} />
        </div>
      )}

      {product && (
        <div className="product-page">
          <CommonHelmet
            title={metaData.title}
            description={metaData.description}
            ogImg={product.cdnImages?.length && product.cdnImages[0].variants.public}>
            <link rel="canonical" href={config.canLink + paths.product(product?.id)} />
          </CommonHelmet>
          <DLViewProductDetails product={product} />
          <Breadcrumbs
            idCategory={customCategory?.id}
            additionalItems={additionalBreadcrumbs}
            lastItemName={product.title}
          />
          {product && <ProductJsonLd product={product} reviews={reviews} />}
          <section className="section-catalog section-product">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <figure className={`product-pic ${salePrice !== null ? 'item-sale' : ''}`}>
                    {salePrice !== null && (
                      <SaleDiscountWidget productOption={currentProductOption} needCountdown={true} />
                    )}
                    <BadgesList product={product} isBigBadge={true} />
                    {!isLess991 && (
                      <div className="product-pic_zoom">
                        <div id="zoom-picture">
                          <ProductMagnifyImage product={product} imageIndex={currentImageIndex} />
                        </div>
                        <div className={'product-pic-may-differ'}>
                          <span>{buildLocale(currentTranslate, 'productImageMayDifferFromOriginal')}</span>
                        </div>
                        {product.cdnImages && product.cdnImages.length > 1 && (
                          <div className="product-pic_pic-more_scroll">
                            <ul className="product-pic_pic-more">
                              {product.cdnImages.map((img: any, index: number) => (
                                <li onClick={() => setCurrentImageIndex(index)} key={img.id}>
                                  <ProductImage product={product} imageIndex={index} />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                    {isLess991 && (
                      <>
                        <ProductImagesSlider product={product} />
                        <button
                          onClick={() => changeWishlistForMobile()}
                          className="btn btn-wish"
                          aria-label="Zu den Favoriten hinzufügen">
                          <img src={isFavorite ? wishlistIcon : wishlistIconNonActive} alt="" width="20" height="19" />
                        </button>
                        <div className={'product-pic-may-differ'}>
                          <span>{buildLocale(currentTranslate, 'productImageMayDifferFromOriginal')}</span>
                        </div>
                      </>
                    )}
                  </figure>
                </div>
                <div className="col-md-6">
                  <div className="product-card">
                    <div>
                      <h1>{product.title}</h1>
                      {!isLess991 && (
                        <button
                          onClick={() => changeWishlist()}
                          className="btn btn-wish"
                          aria-label="Zu den Favoriten hinzufügen">
                          <img src={isFavorite ? wishlistIcon : wishlistIconNonActive} alt="" width="20" height="19" />
                        </button>
                      )}
                      {!!product?.reviewsPublishedCount && (
                        <ReviewStars
                          isEditable={false}
                          additionalData={
                            <span className="product-reviews_additional_data">
                              <span>(</span>
                              {product.reviewsPublishedCount}
                              <span>)</span>
                            </span>
                          }
                          ratingValue={product.reviewsAverageRating}
                          itemsCount={product.reviewsPublishedCount}
                          processStarsClick={handleStarsClick}
                        />
                      )}
                    </div>
                    <div
                      className={isLess991 ? 'product-short' : ''}
                      dangerouslySetInnerHTML={{
                        __html: product.shortDescription,
                      }}></div>
                    <PriceBlock product={product} addProductToCart={addProductToCart} orderBtnTitle={orderBtnTitle} />
                  </div>
                </div>
                <div className="col-12">
                  <section className="product-reviews">
                    {isLess991 && isContentNotEmpty(product.fullDescription) && (
                      <article className="product-reviews_article">
                        <p className="subtitle">{buildLocale(currentTranslate, 'productCardTabDesc')}</p>
                        <div>{makeTabContent(product.fullDescription, null)}</div>
                      </article>
                    )}
                    <ul className="nav nav-tabs">
                      {!isLess991 && isContentNotEmpty(product.fullDescription) && (
                        <li className="nav-item">
                          <button
                            className={generateClassName('nav-link', {
                              active: tab === ProductKeysTab.fullDescription,
                            })}
                            onClick={() => setTab(ProductKeysTab.fullDescription)}>
                            {buildLocale(currentTranslate, 'productCardTabDesc')}
                          </button>
                        </li>
                      )}
                      {isContentNotEmpty(product.garnish) && (
                        <li className="nav-item">
                          <button
                            className={generateClassName('nav-link', { active: tab === ProductKeysTab.garnish })}
                            onClick={() => setTab(ProductKeysTab.garnish)}>
                            {buildLocale(currentTranslate, 'productCardTabGarnish')}
                          </button>
                        </li>
                      )}
                      {isContentNotEmpty(product.energyValue) && (
                        <li className="nav-item">
                          <button
                            className={generateClassName('nav-link', { active: tab === ProductKeysTab.energyValue })}
                            onClick={() => setTab(ProductKeysTab.energyValue)}>
                            {buildLocale(currentTranslate, 'productCardTabEnergyValue')}
                          </button>
                        </li>
                      )}
                      {isContentNotEmpty(product.ingredients) && (
                        <li className="nav-item">
                          <button
                            className={generateClassName('nav-link', { active: tab === ProductKeysTab.ingredients })}
                            onClick={() => setTab(ProductKeysTab.ingredients)}>
                            {buildLocale(currentTranslate, 'productCardTabWarehouse')}
                          </button>
                        </li>
                      )}
                    </ul>
                    <div className="tab-content">
                      {!isLess991 && isContentNotEmpty(product.fullDescription) && (
                        <div
                          className={generateClassName('tab-pane', { active: tab === ProductKeysTab.fullDescription })}>
                          {makeTabContent(product.fullDescription, buildLocale(currentTranslate, 'productCardTabDesc'))}
                        </div>
                      )}
                      {isContentNotEmpty(product.garnish) && (
                        <div className={generateClassName('tab-pane', { active: tab === ProductKeysTab.garnish })}>
                          {makeTabContent(product.garnish, buildLocale(currentTranslate, 'productCardTabGarnish'))}
                        </div>
                      )}
                      {isContentNotEmpty(product.energyValue) && (
                        <div className={generateClassName('tab-pane', { active: tab === ProductKeysTab.energyValue })}>
                          {makeTabContent(
                            product.energyValue,
                            buildLocale(currentTranslate, 'productCardTabEnergyValue'),
                          )}
                        </div>
                      )}
                      {isContentNotEmpty(product.ingredients) && (
                        <div className={generateClassName('tab-pane', { active: tab === ProductKeysTab.ingredients })}>
                          {makeTabContent(
                            product.ingredients,
                            buildLocale(currentTranslate, 'productCardTabWarehouse'),
                          )}
                        </div>
                      )}
                    </div>
                  </section>
                </div>
                <RelatedProductsWidget
                  productId={productId}
                  productRecommendations={productRecommendations}
                  productVariants={productVariants}
                  responsiveConfig={carouselCategories}
                />
                <div className="col-12 product-reviews_bottom" ref={reviewContainerRef}>
                  <ViewProductReviewWidget
                    reviews={reviews}
                    isLoading={isReviewsLoading}
                    openManagingForm={openReviewManagingForm}
                    productId={productId}
                    productTitle={product.title}
                    ratingValue={product.reviewsAverageRating}
                    itemsCount={product.reviewsPublishedCount}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default ProductPage;
