import React from 'react';
import { useSelector } from 'react-redux';
import useApi from 'react-use-api';
import queries from '../../../services/queries';
import { useParams } from 'react-router-dom';
import { selectorsDelivery } from '../../../redux/delivery/deliveryReducer';
import { selectorsOther } from '../../../redux/other/otherReducer';
import FilterCustomCategoriesDesktop from './components/desktop/FilterCustomCategoriesDesktop';
import FilterCustomCategoriesMobile from './components/mobile/FilterCustomCategoriesMobile';
import { IFiltersDTO } from '../../../typings/IFilters';
import { IFilterCustomCategories } from '../../../typings/IFilterCustomCategories';

interface ICustomCategoryProps {
  filters: IFiltersDTO;
}

const FilterCustomCategories = (props: ICustomCategoryProps) => {
  const { filters } = props;
  const { id } = useParams();
  const categoryId = Number(id);
  const isCategoryIdNan = isNaN(categoryId);
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);

  const handleData = (data: IFilterCustomCategories, state: any) => {
    const { error } = state;
    if (error) {
      return [];
    }
    const { tree } = data;
    data.tree = tree.sort((a, b) => a.ord - b.ord);
    return { ...data };
  };

  const loadCustomCategoriesForCurrentCategory = (categoryId: number, isInCourierArea: boolean | null) => {
    return queries.getCustomCategoriesForCategory(categoryId, filters, isInCourierArea);
  };

  const [customCategoriesDataForCurrentCategory, { loading }] = useApi(
    loadCustomCategoriesForCurrentCategory(categoryId, isInCourierArea),
    {
      skip: isCategoryIdNan,
      handleData,
    },
  );

  return (
    <>
      {isDesktop && !loading && (
        <div className="container">
          {!!customCategoriesDataForCurrentCategory?.tree?.length && (
            <FilterCustomCategoriesDesktop data={customCategoriesDataForCurrentCategory} />
          )}
        </div>
      )}
      {!isDesktop && !loading && (
        <div>
          {!!customCategoriesDataForCurrentCategory?.tree?.length && (
            <FilterCustomCategoriesMobile data={customCategoriesDataForCurrentCategory} />
          )}
        </div>
      )}
    </>
  );
};

export default FilterCustomCategories;
