import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductLoader from '../components/Loaders/ProductLoader';
import Breadcrumbs from '../components/common/Breadcrumbs';
import ProductCard from '../components/Modals/ProductCard';
import { blockBodyScroll } from '../utils/blockBodyScroll';
import Product from '../components/Products/Product';
import {
  getBannerBullets,
  getBannerHeight,
  getBannerImage,
  getBannerTitle,
  isHasBanner,
} from '../utils/bannerPrictures';
import FilterComponent from '../components/common/Filters/FilterComponent';
import { FILTER_TYPE, IFiltersDTO } from '../typings/IFilters';
import { actionsFilters, selectorsFilters } from '../redux/filters/filtersReducer';
import { useLessThen991 } from '../utils/mediaQuery';
import queries from '../services/queries';
import useApi from 'react-use-api';
import { handleData } from '../utils/handleData';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import CommonHelmet from '../components/common/CommonHelmet';
import config from '../config';
import { usePaths } from '../components/Routes/RouterList';
import { SeoTexts } from '../constants/seoTexts';
import useFilterDataFromSearchParams from '../useHooks/useFilterDataFromSearchParams';
import useFilterQueryParams from '../useHooks/useFilterQueryParams';
import getSortDataForFilter from '../utils/getSortDataForFilter';
import { useNavigationType } from 'react-router';
import { v5 as uuidv5 } from 'uuid';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';
import { useRouterStaticContext } from '../context/RouterStaticContext';
import NotFoundComponent from './common/NotFoundPage';
import { selectorsOther } from '../redux/other/otherReducer';
import FilterCustomCategories from '../components/common/Filters/FilterCustomCategories';
import { FilterSearchingParams } from '../redux/filters/filtersTypes';

const CategoriesPage = () => {
  const { id } = useParams();
  const categoryId = Number(id);
  const isCategoryIdNan = isNaN(categoryId);
  const paths = usePaths();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const navigationType = useNavigationType();
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);

  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const selectedSort = useSelector(selectorsFilters.getSortDirection);
  const selectedCustomCategory = useSelector(selectorsFilters.getCustomCategory);
  const staticContext = useRouterStaticContext();

  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const [currentCategory, { error }] = useApi(queries.getCategory(categoryId), { skip: isCategoryIdNan });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const location = useLocation();
  const dispatch = useDispatch<any>();

  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({
    type: FILTER_TYPE.CATEGORY,
    id: categoryId,
    skip: isCategoryIdNan,
    filters: {
      ...({} as IFiltersDTO),
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
      customCategoryFilter: selectedCustomCategory || '',
    },
  });
  const { brands, badges, allergich, nutrion, searchQueryData } = useFilterDataFromSearchParams(
    brandsQuery,
    badgesQuery,
    attributesQuery,
  );

  if (staticContext && (error?.status === 404 || isCategoryIdNan)) {
    staticContext.statusCode = 404;
  }

  const applyFilters = () => {
    setOffset(0);
  };

  useEffect(() => {
    return () => {
      dispatch(actionsFilters.setCustomCategory(null));
      dispatch(actionsFilters.setSelectedCustomCategory(null));
      dispatch(actionsFilters.setCustomCategoryDraft(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLessThen991) {
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, categoryId]);

  const getActualSearchData = () => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return searchQueryData.isEmpty ? searchFilter || '' : searchQueryData.searchFilter || '';
    }
    return searchFilter || '';
  };

  const liveSearchData = getActualSearchData();
  const tidNameSpace = uuidv5(location.pathname, uuidv5.URL);
  const tid = liveSearchData?.length ? uuidv5(liveSearchData, tidNameSpace) : '';

  const getActualFilters = () => {
    return {
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      orderProducts: getSortDataForFilter({ isEmpty: true } as FilterSearchingParams, selectedSort),
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
      customCategoryFilter: selectedCustomCategory || '',
    };
  };

  const getProducts = (offset?: number, limit?: number) => {
    const filters = getActualFilters();
    return queries.getProductsByCategory(
      categoryId,
      filters.orderProducts,
      filters,
      isInCourierArea,
      limit,
      offset,
      tid,
    );
  };

  const options = useMemo(
    () => ({
      handleData,
      dependencies: {
        perPage: limit,
      },
      skip: isCategoryIdNan,
    }),
    [isCategoryIdNan, limit],
  );
  const [products, { loading, hasMore = true }, request] = useApi(getProducts(), options);
  const loadMore = useCallback(() => {
    const newOffset = offset + limit;
    request(getProducts(newOffset, limit), true).then(() => setOffset(newOffset));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    offset,
    selectedBrands,
    selectedBadges,
    selectedAllergich,
    selectedNutrion,
    hasPriceRanges,
    hasBadges,
    hasSalePrice,
    searchFilter,
    selectedSort,
  ]);

  useEffect(() => {
    if (!isOpen) {
      blockBodyScroll(false);
    }
  }, [isOpen]);

  const metaData = useMemo(() => {
    const title = currentCategory?.seoTexts?.title || SeoTexts.title;
    const description = currentCategory?.seoTexts?.description || SeoTexts.description;

    return {
      title,
      description,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  const bullets = getBannerBullets(currentCategory);

  const openProductCardPopup = (product) => {
    if (!isOpen) {
      setIsOpen(true);
      setSelectedProduct(product);
    }
  };

  const closeProductCardPopup = () => {
    if (isOpen) {
      setIsOpen(false);
      setSelectedProduct({});
    }
  };

  if (error?.status === 404 || isCategoryIdNan) {
    return <NotFoundComponent />;
  }

  return (
    <section className="category-page">
      <CommonHelmet title={metaData.title} description={metaData.description}>
        <link rel="canonical" href={config.canLink + paths.categories(categoryId)} />
      </CommonHelmet>
      <section className={`section-main ${isHasBanner(currentCategory) ? '' : 'no-banner-crumbs'}`}>
        <Breadcrumbs idCategory={categoryId} />
        {isHasBanner(currentCategory) && (
          <div className="search-main" style={{ height: getBannerHeight(currentCategory) }}>
            <figure className="search-main_pic">
              <img src={getBannerImage(currentCategory)} alt="" />
            </figure>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <h1 style={{ color: '#fff' }}>
                    {getBannerTitle(currentCategory, buildLocale(currentTranslate, 'ownCategoryBannerTitle'))}
                  </h1>
                  <div className="row">
                    {bullets &&
                      bullets.map((item, index) => (
                        <div key={index} className="col-md-4">
                          <article className="category-page_art" style={{ color: '#fff' }}>
                            {item}
                          </article>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isHasBanner(currentCategory) && <h1 className="category-title">{currentCategory?.name || ''}</h1>}
      </section>
      <FilterCustomCategories filters={getActualFilters()} />
      <FilterComponent
        id={categoryId}
        type={FILTER_TYPE.CATEGORY}
        applyFilters={applyFilters}
        isLoading={loading}
        filters={getActualFilters()}
        brands={brands}
        badges={badges}
        allergich={allergich}
        nutrion={nutrion}
        searchQueryData={searchQueryData}
      />
      <div className="search-result">
        <div className="container">
          <InfiniteScroll
            next={loadMore}
            hasMore={hasMore}
            loader={<ProductLoader />}
            dataLength={products?.length || 0}
            scrollThreshold={0.8}
            style={{ overflowX: 'hidden' }}>
            {!!products?.length &&
              products.map((product: any) => (
                <div key={product.id} className="col-6 col-md-4 col-lg-3 col-xl-2 catalog-item">
                  <Product product={product} onProductImageClick={openProductCardPopup} />
                </div>
              ))}
          </InfiniteScroll>
          {isOpen && <ProductCard isOpen={isOpen} id={selectedProduct.id} onProductCardClose={closeProductCardPopup} />}
        </div>
      </div>
    </section>
  );
};

export default CategoriesPage;
