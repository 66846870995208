import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductLoader from '../../components/Loaders/ProductLoader';
import ProductCard from '../../components/Modals/ProductCard';
import Product from '../../components/Products/Product';
import service from '../../services/service';
import searchIcon from '../../assets/images/ic-close_m.svg';
import { usePaths } from '../../components/Routes/RouterList';
import { isBrowser } from '../../utils/isBrowser';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import { buildLocale } from '../../utils/buildLocale';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { actionsOther, selectorsOther } from '../../redux/other/otherReducer';
import NoSearchResult from '../../components/common/NoSearchResult';
import { top } from '../../constants/searchParams';
import { IProduct } from '../../typings/IProduct';
import { selectorsFilters } from '../../redux/filters/filtersReducer';
import FilterComponent from '../../components/common/Filters/FilterComponent';
import { FILTER_TYPE } from '../../typings/IFilters';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';
import { useNavigate, useNavigationType } from 'react-router';
import useFilterQueryParams from '../../useHooks/useFilterQueryParams';
import useFilterDataFromSearchParams from '../../useHooks/useFilterDataFromSearchParams';
import AdvancedSearchMobileModal from './AdvancedSearchMobileModal';
import { searchingTags } from '../../constants/searchingTags';
import { v5 as uuidv5 } from 'uuid';
import { useLocation } from 'react-router-dom';
import FilterSearchPageCustomCategories from '../../components/common/Filters/FilterSearchPageCustomCategories';

const SearchingPage = () => {
  const navigate = useNavigate();
  const paths = usePaths();
  const dispatch = useDispatch<any>();
  const navigationType = useNavigationType();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [searchField, setSearchField] = useState('');
  const [trimmedSearchField, setTrimmedSearchField] = useState('');
  const [products, setProducts] = useState<IProduct[]>([]);
  const [skip, setSkip] = useState(0);
  const [isHasMore, setIsHasMore] = useState(true);
  const [isAdvancedSearchPopupOpen, setIsAdvancedSearchPopupOpen] = useState(false);
  const [isProductCardPopupOpen, setIsProductCardPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [showTags, setShowTags] = useState(true);
  const persistedSearchField = useSelector(selectorsOther.getProductsSearchQuery);
  const showNoResultsMessage = products.length === 0 && trimmedSearchField.length > 1 && !showTags;
  const searchData = trimmedSearchField || persistedSearchField.trim() || '';
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const selectedCustomCategory = useSelector(selectorsFilters.getCustomCategory);

  const { pathname } = useLocation();

  const getActualFilters = () => {
    return {
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
      customCategoryFilter: selectedCustomCategory || '',
      searchTopText: searchData ? searchData : 'empty',
    };
  };

  const { brandsQuery, badgesQuery, attributesQuery, abortController } = useFilterQueryParams({
    type: FILTER_TYPE.SEARCH,
    filters: getActualFilters(),
  });
  const { brands, badges, allergich, nutrion, searchQueryData } = useFilterDataFromSearchParams(
    brandsQuery,
    badgesQuery,
    attributesQuery,
  );
  const productSearchFilter = !searchQueryData.isEmpty ? searchQueryData.productSearchFilter : '';

  const SEARCH_MIN_LENGTH = 2;

  const searchingInputRef = useRef<HTMLInputElement>(null);

  const getActualSearchData = () => {
    if (navigationType === 'POP') {
      return searchQueryData.isEmpty ? searchFilter || '' : searchQueryData.searchFilter || '';
    }
    return searchFilter || '';
  };

  const liveSearchData = getActualSearchData();
  const tidNameSpace = uuidv5(pathname, uuidv5.URL);
  const tid =
    searchData?.length || liveSearchData?.length ? uuidv5(`${searchData}-${liveSearchData}`, tidNameSpace) : '';

  useEffect(() => {
    if (isBrowser && window.screen.width > 991) {
      navigate(paths.main);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productSearchFilter?.length) {
      setSearchField(productSearchFilter);
      setTrimmedSearchField(productSearchFilter.trim());
    }
  }, [productSearchFilter]);

  useEffect(() => {
    if (!isProductCardPopupOpen) {
      blockBodyScroll(false);
    }
  }, [isProductCardPopupOpen]);

  const searchProducts = (isWithFilter?: boolean) => {
    if (!searchData) {
      setShowTags(true);
    }
    if (isAdvancedSearchPopupOpen || searchData.length < SEARCH_MIN_LENGTH) {
      setProducts([]);
      return;
    }

    const filters = getActualFilters();
    service
      .getAllProducts(abortController.signal, filters, isInCourierArea, top, isWithFilter ? 0 : skip, tid)
      .then((value: any) => {
        if (!value.success) {
          return;
        }
        setIsHasMore(value.data.length > 0);
        setShowTags(false);
        setSkip((prevSkip) => prevSkip + top);
        setProducts((prevState) => [...prevState, ...value.data]);
      })
      .catch((e: any) => console.error(e));
  };

  useEffect(() => {
    if (searchingInputRef?.current) {
      searchingInputRef.current.focus();
    }
    searchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyFilters = () => {
    setSkip(0);
    setProducts([]);
    setIsHasMore(true);
    searchProducts(true);
  };

  useEffect(() => {
    if (isAdvancedSearchPopupOpen) {
      return;
    }
    dispatch(actionsOther.setProductsSearchQuery(trimmedSearchField));
    const delayDebounceFn = setTimeout(() => applyFilters(), 500);

    return () => {
      abortController.abort();
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedSearchField, selectedCustomCategory]);

  const openProductCardPopup = (product: IProduct) => {
    if (!isProductCardPopupOpen) {
      setIsProductCardPopupOpen(true);
      setSelectedProduct(product);
    }
  };

  const closeProductCardPopup = () => {
    if (isProductCardPopupOpen) {
      setIsProductCardPopupOpen(false);
      setSelectedProduct({});
    }
  };

  const openAdvancedSearchPopup = () => {
    if (!isAdvancedSearchPopupOpen) {
      setIsAdvancedSearchPopupOpen(true);
    }
  };

  const closeAdvancedSearchPopup = () => {
    if (isAdvancedSearchPopupOpen) {
      setIsAdvancedSearchPopupOpen(false);
      setSelectedProduct({});
    }
  };

  const resetProducts = () => {
    setProducts([]);
    setSearchField('');
    setTrimmedSearchField('');
    closeAdvancedSearchPopup();
    setShowTags(true);
  };

  const checkPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || !e.key) {
      e.preventDefault();
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      if (trimmedSearchField.length < SEARCH_MIN_LENGTH) {
        return;
      }
      searchProducts();
    }
  };

  const onSearchChange = (value: string) => {
    const cleanValue = value.trim();
    setSearchField(value);
    setTrimmedSearchField(cleanValue);
    if (cleanValue.length < SEARCH_MIN_LENGTH) {
      closeAdvancedSearchPopup();
      setProducts([]);
      setShowTags(true);
      return;
    }
    openAdvancedSearchPopup();
  };

  return (
    <div className="search-page">
      <div className="search-form">
        <input
          ref={searchingInputRef}
          placeholder={buildLocale(currentTranslate, 'searchPlaceholder')}
          className="my-input-search"
          onChange={(value) => onSearchChange(value.target.value)}
          value={searchField}
          onKeyDown={(e) => checkPressEnter(e)}
        />
        {searchField && (
          <span className="search-close">
            <img src={searchIcon} alt="" onClick={() => resetProducts()} width="18" height="18" />
          </span>
        )}
      </div>
      {isAdvancedSearchPopupOpen && <AdvancedSearchMobileModal trimmedSearchField={trimmedSearchField} />}
      <>
        {!isAdvancedSearchPopupOpen && (
          <div className="search-actual">
            <p className="title">{buildLocale(currentTranslate, 'searchTagsTitle')}</p>
            <div className="search-actual_list">
              {showTags &&
                searchingTags.map((tag, index) => (
                  <span key={index} className="search-tag" onClick={() => onSearchChange(tag)}>
                    {tag}
                  </span>
                ))}
            </div>
          </div>
        )}
        {searchData && (
          <div style={{ marginTop: '1rem' }}>
            <FilterSearchPageCustomCategories filters={getActualFilters()} />
            <FilterComponent
              type={FILTER_TYPE.SEARCH}
              applyFilters={applyFilters}
              isLoading={false}
              hideSort={true}
              filters={getActualFilters()}
              brands={brands}
              badges={badges}
              allergich={allergich}
              nutrion={nutrion}
              searchQueryData={searchQueryData}
            />
          </div>
        )}
        <div className="search-result">
          {!!products?.length && (
            <InfiniteScroll
              next={() => searchProducts()}
              hasMore={isHasMore}
              loader={<ProductLoader />}
              dataLength={products.length}
              scrollThreshold={0.9}
              style={{ overflowX: 'hidden' }}>
              {products.map((product: any) => (
                <div key={product.id} className="col-6 col-md-4 col-lg-3 col-xl-2 catalog-item">
                  <Product product={product} onProductImageClick={openProductCardPopup} />
                </div>
              ))}
            </InfiniteScroll>
          )}

          {showNoResultsMessage && <NoSearchResult searchQuery={searchField} />}

          {isProductCardPopupOpen && (
            <ProductCard
              isOpen={isProductCardPopupOpen}
              id={selectedProduct.id}
              onProductCardClose={closeProductCardPopup}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default SearchingPage;
